import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './WorldMap.scss';
// import CountryMapData from '../../data/countries.json';
// import ContinentMapData from '../../data/continentsLayer.json';
import '../../styles/_variables.scss';
import Markers from '../Markers/Markers';
import { offices } from '../../data/offices.json';
import { PinLocation } from '../IconLocation/pinLocation';

export default function WorldMap() {
  const zoomLevel=2;

  // useEffect(() => {
  //   const queryLevel = document.querySelector("#root > div > div.coverage > div > div > div.map.leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom > div.leaflet-pane.leaflet-map-pane > div.leaflet-proxy.leaflet-zoom-animated").style.cssText.split(" ")[4].match(/\d+/g)[0];
  //   console.log(queryLevel);
  //   setZoomLevel(queryLevel);
  //   console.log(zoomLevel);
  // }, [zoomLevel])

  // 40.413049130113414, -3.718185323313483      Calle manzanares

  const [state, setState] = useState({
    currentLocation: { lat: '40.416775', lng: '-3.703790' },
    zoom: zoomLevel,
  });

  // const countryStyle = {
  //   fillColor: '#f1f5fd',
  //   fillOpacity: 0,
  //   color: 'gray',
  //   weight: 1,
  // };
  // const paletteColor = ['#f1f5fd', '#63a8ea', '#1f55ba', '#054187', 'snow'];
  // const OnEachCountry = (country, layer) => {
  //   const countryName = country.properties.ADMIN;
  //   layer.bindPopup(`${countryName}`);
  //   // layer.options.fillColor =
  //   //   paletteColor[Math.floor(Math.random() * paletteColor.length)];

  //   const onCountryClick = (event) => {
  //     event.target.setStyle({
  //       color: 'black',
  //       fillColor: '#1f55ba',
  //       fillOpacity: 0.3,
  //     });
  //   };

  //   // const onCountryHover = (event) => {
  //   //   event.target.setStyle({ color: 'purple', fillColor: 'blue' });
  //   // };

  //   layer.on({
  //     click: onCountryClick,
  //     // mouseover: onCountryHover,
  //   });
  // };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        if (position.coords.latitude && position.coords.longitude) {
          setState({
            currentLocation: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            zoom: zoomLevel,
          });
        }
      },
      function (error) {
        console.log(error);
      },
      {
        enableHighAccuracy: true,
      }
    );
  }, [zoomLevel]);

  return (
    <div className='container'>
      <MapContainer
        className="map"
        zoom={state.zoom}
        center={state.currentLocation}
        scrollWheelZoom={false}
      >
        {/* <GeoJSON
          style={countryStyle}
          data={
            zoomLevel > 3 ? CountryMapData.features : ContinentMapData.features
          }
          onEachFeature={OnEachCountry}
        /> */}
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Markers places={offices} />
        <Marker position={state.currentLocation} icon={PinLocation}>
          <Popup>
            User Location
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}
