import React from 'react';

function MainCorporatePresentation() {
  return (
    <div className="container pt-5 mt-5">
      <iframe
        width="100%"
        height="600"
        src="https://www.youtube.com/watch?v=jheA8qs0rzA"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      {/* <video
        playsInline="playsinline"
        muted="muted"
        loop="loop"
        autoPlay="autoplay"
        className="rounded mx-auto d-block w-100"
        controlsList="nodownload"
        controls
      >
        <source
          src="https://res.cloudinary.com/dql1gaina/video/upload/v1649079926/website/COMPANY_PTT_MAR22_2_ijjlwq.mp4"
          type="video/mp4"
        />
      </video> */}
    </div>
  );
}

export default MainCorporatePresentation;
