import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import CarouselIndu from '../../../components/CarouselIndu/CarouselIndu';
import ConnectivitySolutions from '../../../components/ConnectivitySolutions/ConnectivitySolutions';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import TestimonialCarousel from '../../../components/TestimonialCarousel/TestimonialCarousel';
import './BusinessBroadband.scss';

export default function BusinessBoradband() {
  useEffect(() => {
    window.scroll(0,0);
  }, [])
  return (
    <div className="bb">
      <Helmet>
        <title>Business Broadband - inte-QT</title>
        <meta name="title" content="Business Broadband - inte-QT" />
        <meta
          name="description"
          content="Business Broadband is a contended connection, with different user sharing the same service. Data routes from Enterprises to nearest local cabinet through which it goes to backhaul and then to in Internet."
        />
        <link rel="canonical" href="https://www.inte-qt.com/services/business-broadband" />
      </Helmet>
      <img
        src="https://i.imgur.com/WSqtQnf.jpg"
        alt="12433124"
        className="banner-img banner-img__black"
      />
      <h1 className="banner-img__header text-center">
        Business <br /> Broadband
      </h1>
      <div className="container-fluid">
        <div className="container" align="justify">
          <div className="b_read">
            <p className="bb__subtitle text-center">
              Providing unlimited accessibility, Reliable and fast broadband
              connectivity through a flexible approach.
            </p>
            <h3 className="bb__q">
              What is Business Broadband (BB) and it’s Importance?
            </h3>
            <p>
              Business Broadband is a contended connection, with different user sharing the
              same service. Data routes from Enterprises to nearest local cabinet
              through which it goes to backhaul and then to in Internet.
            </p>
            <p>
              To address organization’s Internet needs and help gaining
              competitive advantage, business broadband gives an edge. If business
              utilizes internet-based services, relies on connection to
              communicate with stakeholders on delivering excellent customer
              services online, then a business broadband could be an
              important investment.
            </p>
            <h3 className="bb__q">
              Global Enabler for Business Broadband Services.
            </h3>
            The relationships with carriers and managed services partners in more than <strong>183 countries</strong> provides us an edge for business broadband services. The
            worldwide faster delivery capabilities and the SLA's which are best in
            the segment, ensure the customer satisfaction of using reliable
            internet access.
            <h3 className="bb__q">Step into the future of Business Broadband.</h3>
            <ol>
              <li>
                <p>Higher bandwidth offering</p>
              </li>
              <li>
                <p>
                  Dedicated Customer premise equipment (CPE) covering hardware
                  guarantee.
                </p>
              </li>
              <li>
                <p>
                  Network Security, Network Diversity and Multiple access options
                  like Fiber, copper and Radio.
                </p>
              </li>
              <li>
                <p>
                  Convergent, Redundant Gateway, Hyper fast and fully flexible
                  broadband services
                </p>
              </li>
            </ol>
            <h3 className="bb__q">What makes inte-QT unique?</h3>
            <ol>
              <li>
                <p>Unmatched Fast delivery</p>
              </li>
              <li>
                <p>Global presence with Local expertise.</p>
              </li>
              <li>
                <p>Proactive monitoring by NSOC team <strong>24*7*365</strong>.</p>
              </li>
              <li>
                <p>Standardized Process</p>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="bb__space"><ConnectivitySolutions /></div>
      <div className="my-5 g-5">
        <h2 className="mt-5 text-center center-header mx-auto">
          We help not one, but many companies.
        </h2>
        <CarouselIndu />
      </div>
      <TestimonialCarousel />
      <GetInTouch />
    </div>
  );
}
