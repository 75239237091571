import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet'
import CarouselIndu from '../../components/CarouselIndu/CarouselIndu'
import EventsGallery from '../../components/EventsGallery/EventsGallery'
import GetInTouch from '../../components/GetInTouch/GetInTouch'
import OurServices from '../../components/OurServices/OurServices'

export default function Events() {

  useEffect(() => {
    window.scroll(0,0);
  }, [])


  return (
    <div>
      <Helmet>
        <title>Events - inte-QT</title>
        <meta name="title" content="Events - inte-QT" />
        <meta
          name="description"
          content="Events in which we have participated, making great connections "
        />
        <link rel="canonical" href="https://www.inte-qt.com/events" />
      </Helmet>
      <EventsGallery />
      <h2 className="text-center center-header mx-auto center-header__title">Our Services</h2>
      <OurServices />
      <h2 className="text-center center-header mx-auto center-header__title">We help not One, but many Industries.</h2>
      <CarouselIndu />
      <GetInTouch />
    </div>
  )
}
