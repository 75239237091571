import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet'
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import './Nsoc.scss';
import TestimonialCarousel from '../../components/TestimonialCarousel/TestimonialCarousel';
import EventsComponent from '../../components/EventsComponent/EventsComponent';
import socialmedia from '../../data/social-media.svg';
import ContactSupport from '../Contact/ContactSupport/ContactSupport';

export default function Nsoc() {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  return (
    <div className="nsoc">
      <Helmet>
        <title>Global NSOC - inte-QT</title>
        <meta name="title" content="Global NSOC - inte-QT" />
        <meta name="description" content="We provide Internet Access across the globe with 24X7 Network and Security Operations Center is operational every clock hour, and 365 days of the year. " />
        <link rel="canonical" href="https://www.inte-qt.com/global-nsoc" />
      </Helmet>
      <img
        src="https://i.imgur.com/fcNdSGt.jpg"
        alt="big-background"
        className="banner-img banner-img__black"
      />
      <h1 className="banner-img__header text-center">
        Global NSOC 24x7
      </h1>

      <div className="container-fluid">
          <div className="nsoc__aux">
            <div className="row">
              <div className="col-lg-6 col-md-12 nsoc__img-row">
                <img src={socialmedia} alt="" className="text-center mt-4 nsoc__img-row__img"/>
              </div>
              <div className="col-lg-6 col-md-12">
                <p className="nsoc__p"><i className="far fa-check-circle blue"></i> We live in the era of businesses driven by internet, applications and tools which help in increasing the effectiveness of workforce. The internet-based services and applications are essential for enterprises to run their operations smoothly.</p>
                <p className="nsoc__p"><i className="far fa-check-circle blue"></i> The increasing importance of applications based on latest technologies to businesses need as close as 100% network uptime to prevent any adverse impact.</p>  
                <p className="nsoc__p"><i className="far fa-check-circle blue"></i> Operations across the Globe needs 24/7/365 NSOC (Network Security Operations Centre) to keep a vigilant eye on the healthof their network.  This monitoring helps to prevent any type of network issues and reduce the impact on businesses to a minimum possible.</p>
              </div>
            </div>
        <div align="justify">
            <h3 className="nsoc__p  nsoc__slimheader text-center">inte-QT’s NSOC operates around the clock, whole year to service its customers with an aim towards customer delight.  The key principles followed by our Global NSOC to have <span className="nsoc__pink">Robust</span>, <span className="nsoc__pink">Resilient</span> and <span className="nsoc__pink">Reliable</span> Networks:</h3>
            <div className="text-center"><img src="https://i.imgur.com/WSZQMpb.png" alt="Points of our global nsoc team" className="nsoc__img-secondary"/></div>
            <h3 className="text-center nsoc__p nsoc__slimheader">NSOC processes includes end to end management of networks to support our customers and their hassle-free network operations keeping stringent Service Level Agreements. </h3>
            <div className="text-center"><img src="https://i.imgur.com/FVpCGEE.png" alt="We serve" className="nsoc__img-secondary"/></div>
            <h3 className="nsoc__p nsoc__slimheader" align="justify">At our NSOC, SLA's are stringent and adhered to ensure best service availability to the customers. Designed to specify what customers will receive, as well as outlining the standards to be met while providing them services.</h3>
          </div>
        </div>
      </div>
      <img src="https://i.imgur.com/rNiboOR.jpg" alt="handshaking" className="nsoc__bigimg" />
      <TestimonialCarousel />
      <EventsComponent />
      <ContactSupport />
      <GetInTouch />
    </div>
  )
}
