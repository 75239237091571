import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function Mozambique() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Mozambique - inte-QT</title>
        <meta
          name="title"
          content="Internet in Mozambique - inte-QT"
        />
        <meta name="description" content="Our Coverage in Mozambique" />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/mozambique" />
      </Helmet>
      <img
        src="https://i.imgur.com/0nJcdAY.jpg"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - In the long-term, the Mozambique
              Population is projected to trend around 32.05 Million in 2021 and{' '}
              <strong>32.90 Million*</strong> in 2022, according to our
              econometric models.
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - Mozambique borders{' '}
              <strong>
                Tanzania, Malawi, Zambia, Zimbabwe, South Africa, and Eswatini
              </strong>
              . Its long Indian Ocean coastline of 2,500 kilometers faces east
              to Madagascar.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - Portuguese is the country's
              official language, but it is only spoken by around half of the
              population. The other most spoken primary languages in Mozambique,
              include: Makhuwa, Changana, Nyanja, Ndau, Sena, Chwabo, and Tswa.
            </li>
            <li>
              <strong>CURRENCY</strong> - Mozambican metical The metical is the
              currency of Mozambique, abbreviated with the symbol MZN or MT. It
              is nominally divided into 100 centavos. The name metical comes
              from Arabic مثقال, a unit of weight and an alternative name for
              the gold dinar coin that was used throughout much of Africa until
              the 19th century.
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Aluminum, Coal, Petroleum Products,
              Chemicals (fertilizer, soap, paints), Cement, Asbestos, Glass,
              Textiles, Tobacco, Food processing, Beverages.
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Matola, 1.616.267* population</li>
                <li>Maputo, 1.101.170* population</li>
                <li>Nampula, 743.125* population</li>
              </ul>
            </li>
            <li>
              <p>
                <strong>WEATHER</strong> - Mozambique has a
                <strong>tropical to sub-tropical</strong> climate and
                experiences two seasons: a cool and dry season from April to
                September and a hot and humid season between October and March.
                Temperatures are warmer near the coast and southern lowland
                regions compared to the higher, inland regions.
              </p>
            </li>
            <li>
              <p>
                <strong>EMPLOYMENT</strong> - Employers, total (% of total
                employment) (modeled ILO estimate) in Mozambique was reported at
                2.491 % in 2020, according to the World Bank collection of
                development indicators, compiled from officially recognized
                sources.
              </p>
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/33346827618f5264eb8092378f7cc886/raw/2b774ef5a71df83f27b9aaf42882634e25da6dc8/mozambique-flag.svg"
              alt="Mozambique flag"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            Mozambique, a scenic country in southeastern Africa. Mozambique is
            rich in natural resources, is biologically and culturally diverse,
            and has a tropical climate.
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7745571.310902386!2d31.312295239100607!3d-18.573261404275097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x18d4aceae6fd4ac5%3A0x12bbbfb9ae16a115!2sMozambique!5e0!3m2!1sen!2ses!4v1648814378083!5m2!1sen!2ses"
            width="95%"
            title="mozambique-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <h4 className="mt-4">Tourism</h4>
          <p>
            International tourism, number of arrivals in Mozambique was reported
            at 2033000 in 2019, according to the World Bank collection of
            development indicators, compiled from officially recognized sources.
            In 2020, travel and tourism contributed 3.4 percent to the Gross
            Domestic Product (GDP) in Mozambique. This meant a value added of
            nearly 450 million U.S. dollars.
          </p>

          <p>
            Mozambique has the lowest tourist numbers compared to its
            neighboring countries like South Africa, despite having huge tourism
            assets. Before independence, tourism was a vibrant industry in
            Mozambique, but after independence in 1975, the country was plunged
            into a civil war that almost destroyed the tourism industry as well
            as the wildlife conservation in the country. After the civil war
            confidence of tourist, operators have been on the rise, and the
            country has the opportunity to develop and revamp its tourism
            industry; however, inadequate budget, lack of marketing, and lack of
            tour operators have limited the growth of tourism in the country.
          </p>

          <h4 className="mt-4">Main Ports / Airports</h4>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">IATA</th>
                <th scope="col">Name</th>
                <th scope="col">City</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>MPM</td>
                <td>Maputo Airport</td>
                <td>Maputo</td>
              </tr>
              <tr>
                <td>APL</td>
                <td>Nampula Airport</td>
                <td>Nampula</td>
              </tr>
              <tr>
                <td>POL</td>
                <td>Pemba Airport</td>
                <td>Pemba / Porto Amelia</td>
              </tr>
              <tr>
                <td>BEW</td>
                <td>Beira Airport</td>
                <td>Beira</td>
              </tr>
            </tbody>
          </table>
          <p>
            The key ports in Mozambique are the{' '}
            <strong>
              port of Maputo (southwest of Mozambique), port of Beira (centre of
              Mozambique) and the port of Nacala (north of Mozambique)
            </strong>
            . There are other smaller ports in the Province of Gaza (Port of
            Xai-Xai), Inhambane, Quelimane and Pemba.
          </p>

          <h4 className="mt-5">National Sport</h4>
          <p>
            Football (soccer) is Mozambique's favourite sport; however, the
            country's most renowned player, Eusebio, made his name playing in
            Portugal (1961-75). Known as the “Black Panther,” he led the
            Portuguese national team to a third-place finish at the 1966 World
            Cup football championship.
          </p>
          <h4 className="mt-5">Typical Food</h4>
          <p>
            Xima (pronounced shima) is the backbone of Mozambican cooking. This
            staple, which is popular all over Africa, is a sort of porridge made
            with corn flour.
          </p>
        </article>
      </section>

      <div className="container">
        <h4 className="my-5">Submarine Cables</h4>
        <a
          href="https://www.submarinecablemap.com/country/mozambique"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/okZoBA5.png"
            alt="submarine cables"
            width="100%"
          />
        </a>
        <ol className="mt-4">
          <li>2Africa </li>

          <li>Eastern Africa Submarine System (EASSy) </li>

          <li>SEACOM/Tata TGN-Eurasia </li>
        </ol>

        <h4 className="my-4">
          ACCESS TO LANDLINE AND MOBILE PHONE ACCESS TO INTERNET*
        </h4>
        <p>
          Mozambique was one of the first countries in the region to embark upon
          telecom reform and to open the sector to competition. The mobile
          segment in particular has shown strong growth. The high cost of
          international bandwidth had long hampered internet use, though the
          landing of two international submarine cables (SEACOM and EASSy) has
          reduced the cost of bandwidth and so led to drastic reductions in
          broadband retail prices as well as a significant jump in available
          bandwidth.{' '}
        </p>

        <h4 className="my-4">Internet Providers*</h4>
        <p>
          There were 5.36 million internet users in Mozambique in January 2020.{' '}
        </p>

        <p>
          The number of internet users in Mozambique increased by 439 thousand
          (+8.9%) between 2019 and 2020.
        </p>

        <p>Internet penetration in Mozambique stood at 17% in January 2020. </p>

        <h4 className="my-4">Our Capabilities</h4>
        <p>
          For mobile internet speed the country counts with an average download
          speed of 16,03mbps and average upload speed of 10,56mbps. For fixed
          broadband it has an average download speed of 6,17 mbps and average
          upload of 4,27 mbps.
        </p>

        <p>
          The country has 10 Internet providers owning infrastructure who
          provide the access throughout the country. inte-QT as a
          global access enabler partner with the local providers and today, we
          have 6 infrastructure owning partners in Mozambique.
        </p>

        <p>
          Currently we are delivering in Maputo and Matola with guaranteed 24/7
          monitoring from our NSOC team.
        </p>

        <p>
          inte-QT can offer you different flavors of internet
          solutions in Mozambique, from Business Broadband to DIA, and
          3G/4G/LTE. We are offering services on the airport, commercial centers
          and 24/7 operational units. We are providing nearest reachability to
          the cloud gateways in Johannesburg. We bring traffic shaping
          capabilities to our customers by ensuring high performance for every
          circuit.
        </p>

        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connectivity</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>SLA</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins text-center"
          align="justify"
        >
          With our Global Business Solutions team, we can provide a{' '}
          <mark>Commercial Offer in 2-3 working days.</mark> Our Global Ops team
          is capable to deliver services in major cities (Maputo and Matola)
          country in 2 to 3 weeks and rest of the cities in 4- 5 weeks.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://www.worldbank.org/en/country/mozambique/overview#1"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.budde.com.au/Research/Mozambique-Telecoms-Mobile-and-Broadband-Statistics-and-Analyses"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://www.britannica.com/place/Mozambique"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Mozambique;
