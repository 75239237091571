import React from 'react';
import './EventsGallery.scss';
import {Link} from 'react-router-dom';

export default function EventsGallery() {
  return (
    <div className="eventsGallery px-3">
      <h1 className="center-header text-center mx-auto eventsGallery__title">Events</h1> <br /><br />
      <div className="container">
        <div className="row">
        <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/posts/bitsandbyteglobal_itwafrica-itw2024-keepingtheworldconnected-activity-7231657540122513408-k4uO?utm_source=share&utm_medium=member_ios">
            <figure>
              <img src="https://i.imgur.com/IJBhBsd.jpg" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">ITW Africa And Data Cloud Africa 2024</h3>
              <p className="eventsGallery__box-p">10 - 12 September 2024 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Kenya</span></p>
            </figure>
          </a>
          <hr/>
          <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/posts/bitsandbyteglobal_aslan2024-madrid-aslan-activity-7185596782733574144-w5yd?utm_source=share&utm_medium=member_ios">
            <figure>
              <img src="https://i.imgur.com/43lN9Po.jpg" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">Aslan 2024</h3>
              <p className="eventsGallery__box-p">17 - 18 April 2024 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Palacio de Congresos de IFEMA</span></p>
            </figure>
          </a>
          <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/posts/bitsandbyteglobal_inte-qt-at-itw-2024-activity-7191032993136701440-QJAv?utm_source=share&utm_medium=member_ios">
            <figure>
              <img src="https://i.imgur.com/N9WxHdS.jpg" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">ITW</h3>
              <p className="eventsGallery__box-p">14 - 17 May 2024 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Gaylord National resort & convention centre National Harbor, MD</span></p>
            </figure>
          </a>
          <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/posts/bitsandbyteglobal_capacitymiddleeast-capacitymedia-telecoms-activity-7153755223310675969-Uh3v?utm_source=share&utm_medium=member_ios">
            <figure>
              <img src="https://i.imgur.com/RHoqD2S.jpg" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">Capacity Middle East 2024</h3>
              <p className="eventsGallery__box-p">5 - 8 February 2024 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Grand Hyatt Dubai, UAE</span></p>
            </figure>
          </a>
          <hr/>
          <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/posts/dagagaurav_ptc2024-api-hawaii-activity-7150533292499292162-svt6?utm_source=share&utm_medium=member_ios">
            <figure>
              <img src="https://I.imgur.com/uC4mK8p.jpg" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">PTC 2024</h3>
              <p className="eventsGallery__box-p">21 - 24 January 2024 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Honolulu, Hawaii</span></p>
            </figure>
          </a>
          <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/posts/bitsandbyteglobal_conference-businessoutcomes-telecommunications-activity-7130910010393423872-B4zF?utm_source=share&utm_medium=member_ios">
            <figure>
              <img src="https://i.imgur.com/nAkVeWK.jpg" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">Total Telecom Congress</h3>
              <p className="eventsGallery__box-p">21 - 22 November 2023 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">The Rai, Amsterdam</span></p>
            </figure>
          </a>
          <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/posts/dagagaurav_london-capacityeurope2023-internetaccess-activity-7117125137639911424-XD8D?utm_source=share&utm_medium=member_ios">
            <figure>
              <img src="https://i.imgur.com/1Qkqrmn.jpg" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">Capacity Europe 2023</h3>
              <p className="eventsGallery__box-p">17 - 19 October 2023 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">London, UK</span></p>
            </figure>
          </a>
          <hr/>
          <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/posts/bitsandbyteglobal_itw2023-activity-7054431720669888512-dq_M?utm_medium=member_ios&utm_source=social_share_video">
            <figure>
              <img src="https://i.imgur.com/b0C0jRl.jpg" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">ITW 2023</h3>
              <p className="eventsGallery__box-p">14 - 17 May 2023 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Gaylord National resort & convention centre National Harbor, MD</span></p>
            </figure>
          </a>
          <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/posts/bitsandbyteglobal_ifemamadrid-access-sdwan-activity-6991348989002452993-OW4Y?utm_source=share&utm_medium=member_desktop">
            <figure>
              <img src="https://i.imgur.com/vIt6jue.jpg" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">Cloud Expo Europe 2022</h3>
              <p className="eventsGallery__box-p">28 - 29 October 2022  <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Madrid, ES</span></p>
            </figure>
          </a>
          <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/posts/bitsandbyteglobal_capacityeurope-telecoms-events-activity-6983397911896182785-qPYo?utm_source=share&utm_medium=member_android">
            <figure>
              <img src="https://i.imgur.com/89ne2Om.jpg" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">Capacity EUROPE 2022</h3>
              <p className="eventsGallery__box-p">17 - 20 October 2022 | InternationalContinental London | The 02 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">London, UK</span></p>
            </figure>
          </a>
          <hr/>
        <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/feed/update/urn:li:activity:6932313942992011264">
            <figure>
              <img src="https://i.imgur.com/xr6bTxU.png" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">Congreso & EXPO ASLAN 2022</h3>
              <p className="eventsGallery__box-p">18 - 19 May 2022 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Madrid, ES</span></p>
            </figure>
          </a>
        <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/feed/update/urn:li:activity:6924719247508054016">
            <figure>
              <img src="https://i.imgur.com/PtT5HIy.png" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">International Telecoms Week</h3>
              <p className="eventsGallery__box-p">09 - 12 May 2022 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Washington, USA</span></p>
            </figure>
          </a>
        <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/feed/update/urn:li:activity:6911638923874025473">
            <figure>
              <img src="https://i.imgur.com/lrhjjW8.png" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">Capacity Middle East</h3>
              <p className="eventsGallery__box-p">28 - 31 March 2022 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Dubai, UAE</span></p>
            </figure>
          </a>
          <hr />
        <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/feed/update/urn:li:activity:6909115405546450944">
            <figure>
              <img src="https://i.imgur.com/Yyylbtx.png" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">GCCM London</h3>
              <p className="eventsGallery__box-p">14 - 15 March 2022 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">London, UK</span></p>
            </figure>
          </a>
          <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/feed/update/urn:li:activity:6902921111361974272/">
            <figure>
              <img src="https://i.imgur.com/6BUFQXx.jpg" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">MWC Barcelona 2022</h3>
              <p className="eventsGallery__box-p">28 Feb - 03 March 2022 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Spain, ES</span></p>
            </figure>
          </a>
          <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/feed/update/urn:li:activity:6876121511753719808">
            <figure>
              <img src="https://i.imgur.com/3SbymtI.jpg" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">SDWAN and SASE Summit 2021</h3>
              <p className="eventsGallery__box-p">14 - 16 December 2021 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Paris, FR</span></p>
            </figure>
          </a>
          <hr />
          <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/posts/bitsandbyteglobal_capacityeurope2021-internetaccess-event-activity-6855817706504114176-o3eT">
            <figure>
              <img src="https://i.imgur.com/a9Bq1EA.jpg" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">Capacity Europe</h3>
              <p className="eventsGallery__box-p">18 - 21 October 2021 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Virtual</span></p>
            </figure>
          </a>
          <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/posts/bitsandbyteglobal_whats-cooking-bits-and-byte-global-activity-6836688841332596737-zkDZ">
            <figure>
              <img src="https://i.imgur.com/vCS2pUK.png" alt="img" className="eventsGallery__img" />
              <h5 className="text-center mt-3 eventsGallery__box-h">International Telecoms Week</h5>
              <p className="eventsGallery__box-p">29 August - 01 September 2021 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Virtual</span></p>
            </figure>
          </a>
          <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/posts/bitsandbyteglobal_capacityeurope-letsmeet-globalconnectivity-activity-6722242524867633154-hzDh">
            <figure>
              <img src="https://i.imgur.com/8vPCaIF.png" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">Capacity Europe</h3>
              <p className="eventsGallery__box-p">19 - 22 October 2020 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Virtual</span></p>
            </figure>
          </a>
          <hr />
          <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/posts/bitsandbyteglobal_newnormal2020-capacitymiddleeast-virtualcollaboration-activity-6711552979100676096-ajNf">
            <figure>
              <img src="https://i.imgur.com/vRHWnZi.png" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">Capacity Middle East</h3>
              <p className="eventsGallery__box-p">14 - 16 September 2020 <br /><i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Virtual</span></p>
            </figure>
          </a>
          <Link className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" to="/events">
            <figure>
              <img src="https://i.imgur.com/AafXQGY.png" alt="img" className="eventsGallery__img" />
              <h5 className="text-center mt-3 eventsGallery__box-h">International Telecoms Week</h5>
              <p className="eventsGallery__box-p">15 - 18 June 2020 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Virtual</span></p>
            </figure>
          </Link>
          <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/feed/update/urn:li:activity:6595776791338594304/">
            <figure>
              <img src="https://i.imgur.com/lGhLG1L.png" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">Capacity Europe</h3>
              <p className="eventsGallery__box-p">29 - 31 October 2019 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">London, UK</span></p>
            </figure>
          </a>
          <hr />
          <Link className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" to="/events">
            <figure>
              <img src="https://i.imgur.com/XDoetbv.png" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">Wan Summit</h3>
              <p className="eventsGallery__box-p"> 08 - 09 April 2019 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">New York, USA</span></p>
            </figure>
          </Link>
          <Link className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" to="/events">
            <figure>
              <img src="https://i.imgur.com/svQYnpy.png" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">Europe GCCM </h3>
              <p className="eventsGallery__box-p">13 - 14 February 2019 <br /><i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">London, UK</span></p>
            </figure>
          </Link>
          <a className="col-lg-3 col-md-12 my-3 mx-auto eventsGallery__box" href="https://www.linkedin.com/feed/update/urn:li:activity:6461240970494177281/">
            <figure>
              <img src="https://i.imgur.com/A98hEIT.png" alt="img" className="eventsGallery__img" />
              <h3 className="text-center mt-3 eventsGallery__box-h">Capacity Europe</h3>
              <p className="eventsGallery__box-p"> 23 - 25 October 2018 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">London, UK</span></p>
            </figure>
          </a>
          
        </div>
      </div>
    </div>
  )
}
