import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import DinamicLink from '../../../components/DinamicLink/DinamicLink';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../Blog.scss';

export default function Blog5() {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  return (
    <div className="blog">
      <Helmet>
        <title>Service Assurance - inte-QT</title>
        <meta name="title" content="Service Assurance - inte-QT" />
        <meta
          name="description"
          content="In the world of Digital Era, it is impossible to live without being connected. Lives and Businesses can’t remain healthy unless Information Technology can deliver and assure the mission critical services across the World..."
        />
        <link rel="canonical" href="https://www.inte-qt.com/blog/5" />
      </Helmet>
      <h1 className="center-header center-header__title mx-auto text-center">
      Service Assurance: A Responsibility That Cannot Have A No Show
      </h1>
      <div className="container">
        
        <div className="blog__text" align="justify">
        <div className="text-end mb-5">
          <p className="blogPosts__tags">
            <span className="blue mx-2">Service Assurance</span>/
            <span className="blue mx-2">SLA%</span>/
            <span className="blue mx-2">MTTR</span>
          </p>
          </div>
          <p>
            {' '}
            By{' '}
            <a
              href="https://www.linkedin.com/in/nikhil-jain-705a6943/"
              className="blue"
            >
              Nikhil Jain<i className="fab fa-linkedin-in ms-1"></i>
            </a>{' '}
            on September 28th, 2021 · 3 min read
          </p>
          <p>
          In the world of Digital Era, it is impossible to live without being connected. Lives and Businesses can’t remain healthy unless Information Technology can deliver and assure the mission critical services across the World. The Ultra high speed, reliable networks used by Enterprises and Consumers need to perform flawlessly to sustain growth and to keep a competitive edge. This is the world of Service Assurance function which doesn’t and cannot have a No Show.
          </p>
          <p>
          Customer Service Assurance for telecom networks can be defined as the set of guidelines, frameworks, and processes to ensure that the quality of services meet or exceeds the predefined Service-Level Agreements (SLA). Tools and Techniques are used to monitor the SLAs. The network health, devices and other parameters are constantly monitored in alignment with the agreements defined. There is a proactive monitoring and control mechanism to have the guaranteed performance. The Service Assurance is also called Network Assurance in the telecom networks services domain.
          </p>
            <img src="https://i.imgur.com/Gt6jUPa.jpg" alt="service delivery" className="blog__img" />
          <h3>Elements of Service Assurance Framework</h3>
          <p><strong>Service Level Agreements</strong> - Generally, this defines the guaranteed uptime of the service with relevant quality parameters. It is computed in % availability of the service from the total time available.</p>
          <ul>
            <li><p><strong>Latency</strong> - Latency is the amount of time taken by a data packet to travel from one location to another.  The lower the latency better the user experience.</p></li>
            <li><p><strong>Packet Loss</strong> - During the transmission of data, if one or more of data packets fails to reach the destination, it is called packet loss. This again is measured in %. For users, packet loss manifests in the form of network disruption, slow service, or downtime.</p></li>
            <li><p><strong>MTTR</strong> – Mean Time to Repair - Mean time to repair (MTTR) is a metric that measures the average time required to troubleshoot and repair the impacted service. It reflects how quickly the network support team can respond to unplanned breakdowns and repair them.	</p></li>
          </ul>
          <p>Service assurance process flow refers to the methodology in which IT operations teams monitor and respond to issues, to keep services up and running, without disrupting the customer’s work. Given are the key areas of service assurance process that is required to meet and exceed customer satisfaction.</p>
          <ol>
            <li><p><strong>Proactive End-to-End Monitoring</strong> - Network assurance teams need to pro-actively monitor the services across the entire chain. It enables them to gather the information needed to assure the services like an outage, performance, or any specific parameter and take the necessary steps before any service disruption.</p></li>
            <li><p><strong>Incident Management and Trouble ticketing</strong> – Generation and resolution of incidents and trouble tickets from the network monitoring observations, assessments, inputs from the customers and detected problems within the network.</p></li>
            <li><p><strong>Planned and Emergency Interventions</strong> – Mitigating and Eliminating the risk of disruption to customer services during planned and emergency maintenance of the network.</p></li>
          </ol>
          <p>We, <a
              href="https://www.linkedin.com/company/bitsandbyteglobal/"
              className="blue"
            >
              inte-QT
            </a> have an agile process whereby we ensure 24 x 7 x 365 response to any service assurance demands. More and more proactive, predictive tools are utilized so that required decision making can be smooth and human interventions are least. Framing and enforcing network services’ service-level agreements, We ensure that a good experience is being offered to the customers and the users.</p>
        
        </div>

        <div className="blogPosts__box">
          <div className="blogPosts__imgbox me-5">
            <img
              src="https://i.imgur.com/Vi0se0F.jpg"
              alt=""
              className="blogPosts__img"
            />
          </div>
          <p className="blogPosts__tags">
            <span className="blue mx-2">Connectivity</span>/
            <span className="blue mx-2">Bitcoin</span>/
            <span className="blue mx-2">Blockchain</span>
          </p>
          <h2 className="blogPosts__title">What is Blockchain about??</h2>
          <p className="blogPosts__author">
            {' '}
            By{' '}
            <a
              href="https://www.linkedin.com/in/juanrolo/"
              className="blue"
            >
              Juan Rolo<i className="fab fa-linkedin-in ms-1"></i>
            </a>{' '}
            on September 22th, 2021 · 4 min read
          </p>
          <p>
          In the digital era where everything runs through a computer or a cell phone, it seems so natural to see the news of what is happening around the world that sometimes we do not stop to think about...
          </p>
          <div className="text-end me-5">
            <DinamicLink url="/blog/4" name="Read more" />
          </div>
        </div>

        <GetInTouch />
      </div>
    </div>
  );
}
