import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function Qatar() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Qatar - inte-QT</title>
        <meta name="title" content="Internet in Qatar - inte-QT" />
        <meta name="description" content="Our Coverage in Qatar" />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/qatar" />
      </Helmet>
      <img
        src="https://i.imgur.com/jGLbisl.jpg"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - about 2.8 million* people live in
              Qatar (2020), with Qatari locals making up 15%* of this number.
              Qatar is home to over 100 nationalities
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - Qatar has one land
              border. The country borders <strong>Saudi Arabia</strong> to the
              south. The boundary with Saudi Arabia was settled in 1965 but
              never demarcated. Qatar's northwest coast is fewer than 30 km (19
              mi) from the main islands of Bahrain, while the small Hawar
              Islands of Bahrain are only 1.4 km (0.8 mi) off that coast.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - <strong>Arabic</strong> is the
              official language, and most Qataris speak a dialect of Gulf Arabic
              similar to that spoken in surrounding states. Modern Standard
              Arabic is taught in schools, and English is commonly used. Among
              the large expatriate population, Persian and Urdu are often
              spoken.
            </li>
            <li>
              <strong>CURRENCY</strong> - Qatari Riyal
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Liquefied natural gas, Crude oil,
              Ammonia fertilizer, Petrochemicals, Reinforcing steel, Cement,
              Ship repair
            </li>
            <li>
              <strong>EMPLOYMENT*</strong> - Qatar has one of the lowest
              unemployment rates in the world. Employment rate of Qatar
              increased from 76.53 % in 2001 to <strong>83.31 % in 2020</strong>{' '}
              growing at an average annual rate of 0.46%.
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Doha , Baladīyat ad Dawḩah</li>
                <li>Al Rayyan, Baladīyat ar Rayyān</li>
                <li>Umm Şalāl Muḩammad , Baladīyat Umm Şalāl</li>
              </ul>
            </li>
            <li>
              <strong>WEATHER</strong> - Qatar has a desert climate. The country
              experiences long summers from May to September characterized by
              intense dry heat, with temperatures rising above 45°C. Winter
              temperatures are mild but may fall below 5°C. ... Qatar is
              impacted by Shamal winds, which cause sand and dust storms
              throughout the year. Rainfall is only experienced during the
              winter with the northern parts of the country receiving 30% more
              rainfall than the south
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/77afc2039d867a89bad2e1d905fb9911/raw/972e57b8ace37c622d5979a658ef2c7dbfc69d07/qatar-flag.svg"
              alt="Qatar flag"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            One of the smallest but mightiest countries in the Persian Gulf,
            Qatar is a country full of many interesting facts and exciting
            things to see and do. It is one of the wealthiest countries in the
            world.
          </p>

          <p>
            One of the more interesting facts of Qatar is that it is one of the
            few places in the world where the sea meets the desert.
          </p>

          <p>
            It has acquired vast amounts of modern and contemporary art,
            purchasing art worth hundreds of millions of dollars.
          </p>

          <p>
            Qatar is home to a large number of museums, galleries and public
            artworks.
          </p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d923095.8102764545!2d50.65728392026866!3d25.343048538945993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c534ffdce87f%3A0x1cfa88cf812b4032!2sQatar!5e0!3m2!1sen!2ses!4v1644857882157!5m2!1sen!2ses"
            width="95%"
            title="dominican-republic-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>

          <h4 className="mt-4">Tourism</h4>
          <p>
            Despite the pandemic, Qatar's hospitality sector fared very well
            compared to its global peers. Doha had the 6th highest occupancy
            rate in the world in 2020{' '}
          </p>

          <p>
            Qatar welcomed 2.136 million* visitors in 2019, representing an
            increase of 17% compared to the same period in 2018.{' '}
          </p>

          <table className="table table-striped text-center">
            <thead>
              <tr>
                <th scope="col">Year</th>
                <th scope="col">Number of tourists</th>
                <th scope="col">Income per tourist</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>2019</td>
                <td>2.14m</td>
                <td>7324 $</td>
              </tr>
              <tr>
                <td>2018</td>
                <td>1.82 m</td>
                <td>8378 $</td>
              </tr>
              <tr>
                <td>2017</td>
                <td>2.26 m</td>
                <td>6984 $</td>
              </tr>
              <tr>
                <td>2016</td>
                <td>2.94 m</td>
                <td>4286 $</td>
              </tr>
            </tbody>
          </table>

          <h4 className="mt-4">Main Ports / Airports</h4>
          <table className="table table-striped text-center">
            <thead>
              <tr>
                <th scope="col">City Served</th>
                <th scope="col">Municipality</th>
                <th scope="col">Airport Name</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Doha</td>
                <td>Ad Dawhah</td>
                <td>Hamad International Airport</td>
              </tr>
              <tr>
                <td>Doha</td>
                <td>Ad Dawhah</td>
                <td>Al Udeid Air Base (Abu Nakhlah Airport)</td>
              </tr>
              <tr>
                <td>Dukham</td>
                <td>Al Shahaniya</td>
                <td>Dukhan / Tamim Airbase</td>
              </tr>
            </tbody>
          </table>
          <p>
            Qatar has 4 main ports:{' '}
            <strong>Doha Port, RasLaffan, Masaieed and Al Ruwais.</strong>
          </p>

          <h4 className="mt-5">National Sport</h4>
          <ul>
            <li>
              Falconry is a traditional sport in Qatar and is part of the
              country's intangible heritage.
            </li>
            <li>
              The country has a long history of camel racing, and now features
              robot jockeys - miniature humanoid robots connected to their
              trainers by remote-control walkie-talkies.
            </li>
            <li>
              Equestrian sports are also an important part of Qatar's sporting
              landscape, with Al Shaqab Equestrian Centre within Education City
              home to some of the finest Arabian steeds.
            </li>
          </ul>

          <h4 className="mt-5">Typical Food</h4>
          <p>Machboos, Harees, Thareed, Balaleet, Saloona.</p>
        </article>
      </section>
      <div className="container">
        <h4 className="my-5">Submarine Cables</h4>
        <a
          href="https://www.submarinecablemap.com/country/qatar"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/miZidZg.png"
            alt="submarine cables"
            width="100%"
            className=""
          />
        </a>
        <ol className="mt-4">
          <li>2Africa202 </li>

          <li>Asia Africa Europe-1 (AAE-1) </li>

          <li>FALCON </li>

          <li>Fiber Optic Gulf (FOG) </li>

          <li>Gulf Bridge International Cable System </li>

          <li>(GBICS)/Middle East North Africa (MENA) Cable System </li>

          <li>Qatar-U.A.E. Submarine Cable System </li>

          <li>Tata TGN-Gulf </li>
        </ol>

        <h4 className="my-4">Internet Providers*</h4>

        <p>
          The number of internet users in Qatar reached 2.88 million people last
          January, thus exceeding many countries of the world in this regard.
          The number of internet users in the State has increased with around
          29,000 users, and with 1% between 2020 and 2021, according to the
          report of the global Hootsuite Organization this year.
        </p>

        <p>
          The State of Qatar has ranked first globally in the rate of internet
          adoption among the total population with a percentage reaching 99%,
          according to "The Global State of Digital 2021" report released by
          Hootsuite Organization that is a global organization specialized in
          social media management, and the statistics of the internet and mobile
          phone.
        </p>

        <p>
          Mobile connections increased raising the connections to 4,67 million;
          160% of the popoulation. The mobile connection figures are shown to
          exceed the total population because many use several mobile
          connections.
        </p>

        <p>
          The country counts with an average download speed of 178mpbs and
          average upload speed of 29.74mpbs.{' '}
        </p>

        <p>
          The country has 6 Internet providers owning infrastructure who provide
          the access throughout the country.
        </p>
          
        <p>
          As a Global Access Enabler, inte-QT partners with local Internet providers and today, We have 4 partners in Qatar who own the infrastructure for offering the services. They all have obtained high consistency score. And we make sure that what we offer, is in compliance to local regulations.
        </p>

        <h4 className="my-4">Our Capabilities</h4>
        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>SLA</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins"
          align="justify"
        >
          With our Global Business Solutions team, we can provide a <mark>Commercial Offer in 2-3 working days</mark> and our Global Ops team is capable to deliver services in the country in 6 to 8 weeks. 
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://www.britannica.com/place/Qatar"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://qatarofw.com/low-unemployment-rate/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://www.geonames.org"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
          <li>
            <a
              href="https://www.qatartourism.com/en/news-and-media/sector-statistics/tourism-reports"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 4
            </a>
          </li>
          <li>
            <a
              href="https://climateknowledgeportal.worldbank.org/country/qatar/climate-data-historical"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 5
            </a>
          </li>
          <li>
            <a
              href="https://www.visitqatar.qa/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 6
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Qatar;
