import React, { useEffect, useRef, useState } from 'react';
import './ContactSupport.scss';
import { Helmet } from 'react-helmet';
import emailjs from 'emailjs-com';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function Support() {
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    company_name: '',
    nsoc_id: '',
    email: '',
    phone: '',
    region: 'region', 
    message: '',
    agreedToTerms: false,
  });

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_cxy3wvs',
        'template_fl2oq9j',
        formRef.current,
        'XTHb8nGPkWwJjz2yf'
      )
      .then(
        (result) => {
          console.log(result.text);
          showAlert(true);
          formRef.current.reset(); 
          setFormData({
            first_name: '',
            last_name: '',
            company_name: '',
            nsoc_id: '',
            email: '',
            phone: '',
            region: 'region', 
            message: '',
            agreedToTerms: false,
          });
        },
        (error) => {
          console.log(error.text);
          showAlert(false);
        }
      );
  };

  const showAlert = (success) => {
    const alertConfig = {
      title: success ? 'Success' : 'Wait',
      text: success
        ? 'We have received your form, we will review the information and get back to you soon.'
        : 'You need to fill in all the data',
      icon: success ? 'success' : 'info',
      timer: 3000,
      timerProgressBar: true,
    };

    Swal.fire(alertConfig);
  };

  return (
    <div>
      <Helmet>
        <title>Support Contact - inte-QT</title>
        <meta
          name="description"
          content="For current inte-QT customers with enquires about their connection"
        />
        <link rel="canonical" href="https://www.inte-qt.com/contact/support" />
      </Helmet>
      <h2 className="text-center center-header support mx-auto">
        We can Support your business
      </h2>

      <form ref={formRef} className="support__form mx-auto" onSubmit={sendEmail}>
        <div className="row">
          <div className="mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="First Name"
              id="first_name"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Last Name"
              id="last_name"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            id="company_name"
            placeholder="Company Name"
            name="company_name"
            value={formData.company_name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            id="nsoc_id"
            placeholder="NSOC ID / ORDER ID / CIRCUIT ID"
            name="nsoc_id"
            value={formData.nsoc_id}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <input
            type="email"
            className="form-control"
            id="email"
            aria-describedby="emailHelp"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <PhoneInput
            international
            defaultCountry="ES"
            className="form-control"
            placeholder="Enter phone number"
            value={formData.phone}
            onChange={(value) => setFormData((prevData) => ({ ...prevData, phone: value }))}
            name="phone"
            required
          />
        </div>
        <div className="mb-3">
          <select
            className="form-select"
            value={formData.region}
            name="region"
            onChange={handleChange}
            required
          >
            <option value="region" disabled>
              Region
            </option>
            <option value="singapore">Singapore</option>
            <option value="malaysia">Malaysia</option>
            <option value="rest-asia">Rest of Asia</option>
            <option value="australia-newzealand">Australia & New Zealand</option>
            <option value="europe">Europe</option>
            <option value="middle-east">Middle East</option>
            <option value="north America">North America</option>
            <option value="south America">South America</option>
            <option value="africa">Africa</option>
            <option value="others">Others</option>
          </select>
        </div>
        <div className="mb-3">
          <textarea
            className="form-control"
            id="message"
            rows="3"
            placeholder="Please share your concern"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="form-check mb-3 general__box">
          <input
            className="form-check-input"
            type="checkbox"
            value="1"
            id="partnerFormAgree"
            name="agreedToTerms"
            checked={formData.agreedToTerms}
            onChange={handleChange}
            required
          />
          <label className="form-check-label" htmlFor="partnerFormAgree">
            Agree to terms and conditions of the{' '}
            <Link to="/privacy-policy">privacy policy</Link>.
          </label>
        </div>
        <div className="text-center">
          <button type="submit" className="button">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
