import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ConnectivitySolutions from '../../components/ConnectivitySolutions/ConnectivitySolutions';
import DinamicLink from '../../components/DinamicLink/DinamicLink';
import GetInTouch from '../../components/GetInTouch/GetInTouch';


export default function Testimonials() {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  return (
    <div className="cases">
      <Helmet>
        <title>Testimonials - inte-QT</title>
        <meta name="title" content="Testimonials - inte-QT" />
        <meta name="description" content="Testimonials from our customers demonstrating the quality of the service we offer" />
        <link rel="canonical" href="https://www.inte-qt.com/testimonials" />
      </Helmet>
      <img src="https://i.imgur.com/17SWB8I.jpg" alt="1" className="banner-img banner-img__black"/>
      <h1 className="banner-img__header text-center">Testimonials</h1>
      <div className="container">
        
        <div className="cases__box">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <h2 className="cases__companyName mx-3">Delivering for the Highest Class of International Racing.</h2>
              <p className="cases__text m-3" align="justify"><strong className="blue">Brilliant</strong> work by inte-QT. <br/><br />
                  It was seamlessly managed by inte-QT, right from Quotation to timely delivery well within event schedule. Overall its delighted experience.</p>
              <div className="text-end mx-3"><DinamicLink url="/testimonials/mx" name="Read more"/></div>
            </div>
            <div className="col-lg-6 col-md-12">
              <img src="https://i.imgur.com/6Q7TYsB.jpg" alt="1" className="cases__img"/>
            </div>
          </div>
        </div>

        {/* <div className="cases__box">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <img src="https://picsum.photos/600/400" alt="1" className="cases__img"/>
            </div>
            <div className="col-lg-6 col-md-12">
              <h1 className="cases__companyName mx-3">Title</h1>
              <p className="cases__text m-3" align="justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi corrupti, eum pariatur beatae consequuntur quisquam dolor magnam ex a? Blanditiis labore pariatur quos corporis nihil perferendis ratione harum placeat autem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, deleniti numquam? Consequatur dolorum labore et, laudantium repellendus libero nihil? Quod.</p>
              <div className="text-start mx-3"><DinamicLink url="/testimonials/" name="Read more"/></div>
            </div>
          </div>
        </div>

        <div className="cases__box">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <h1 className="cases__companyName mx-3">Title</h1>
              <p className="cases__text m-3" align="justify">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quidem atque sit est odit perferendis laudantium quas, commodi vitae eveniet reiciendis ducimus porro, perspiciatis quam cumque. Accusamus maiores molestias accusantium perspiciatis, nisi suscipit beatae excepturi nihil cupiditate fugiat, quos id voluptatem architecto fugit tenetur eos tempore dolore numquam neque iure nobis!</p>
              <div className="text-end mx-3"><DinamicLink url="/testimonials/" name="Read more"/></div>
            </div>
            <div className="col-lg-6 col-md-12">
              <img src="https://picsum.photos/600/400" alt="1" className="cases__img"/>
            </div>
          </div>
        </div> */}
      </div>
      <ConnectivitySolutions />
      <GetInTouch />
    </div>
  )
}
