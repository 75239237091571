import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './PartnerTimeline.scss';

export default function PartnerTimeline() {
  return (
    <div>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work bounce-in"
          contentStyle={{ background: 'rgb(31, 85, 186)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(31, 85, 186)' }}
          // date="2011 - present"
          iconStyle={{ background: 'rgb(31, 85, 186)', color: '#fff' }}
          icon={<i className="fas fa-star" style= {{margin:'1.2rem'}}></i>}
        >
          <h3 className="vertical-timeline-element-title">Due Diligence</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          // date="2010 - 2011"
          contentStyle={{ background: 'rgb(31, 85, 186)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(31, 85, 186)' }}
          iconStyle={{ background: 'rgb(31, 85, 186)', color: '#fff' }}
          icon={<i className="fas fa-star" style= {{margin:'1.2rem'}}></i>}
        >
          <h3 className="vertical-timeline-element-title">Compliance</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          // date="2008 - 2010"
          contentStyle={{ background: 'rgb(31, 85, 186)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(31, 85, 186)' }}
          iconStyle={{ background: 'rgb(31, 85, 186)', color: '#fff' }}
          icon={<i className="fas fa-star" style= {{margin:'1.2rem'}}></i>}
        >
          <h3 className="vertical-timeline-element-title">Agreement</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          // date="2006 - 2008"
          contentStyle={{ background: 'rgb(31, 85, 186)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(31, 85, 186)' }}
          iconStyle={{ background: 'rgb(31, 85, 186)', color: '#fff' }}
          icon={<i className="fas fa-star" style= {{margin:'1.2rem'}}></i>}
        >
          <h3 className="vertical-timeline-element-title">Capability</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          // date="April 2013"
          contentStyle={{ background: 'rgb(31, 85, 186)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(31, 85, 186)' }}
          iconStyle={{ background: 'rgb(31, 85, 186)', color: '#fff' }}
          icon={<i className="fas fa-star" style= {{margin:'1.2rem'}}></i>}
        >
          <h3 className="vertical-timeline-element-title">Handshaking
          </h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
          icon={<i className="fas fa-star" style= {{margin:'1.2rem'}}></i>}
        />
      </VerticalTimeline>
    </div>
  );
}
