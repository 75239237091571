import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function Turkey() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Turkey - inte-QT</title>
        <meta
          name="title"
          content="Internet in Turkey - inte-QT"
        />
        <meta name="description" content="Our Coverage in Turkey" />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/turkey" />
      </Helmet>
      <img
        src="https://i.imgur.com/vQIhA63.jpg"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - In 2021, population for Turkey was
              85 million*.
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - Turkey, officially
              known as the Republic of Turkey, is both a European and an Asian
              country. It's neighbour to the northwest is Bulgaria; Greece to
              the west; Armenia, Azerbaijan and Iran to the east; Georgia to the
              northeast; Syria to the south; and Iraq to the southeast.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - Turkish is spoken in Turkey,
              Cyprus, and elsewhere in Europe and the Middle East. With Gagauz,
              Azerbaijani (sometimes called Azeri), Turkmen, and Khorāsān
              Turkic, it forms the southwestern, or Oğuz, branch of the Turkic
              languages. The common ethnic languages spoken in Turkey are
              Turkish, Kurmanji, Arabic and Zazaki.
            </li>
            <li>
              <strong>CURRENCY</strong> - Turkish Lira
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Machinery, tourism, textile,
              electronics, construction, shipbuilding, autos, mining, steel,
              iron, copper, boron, defence, petroleum, food processing and
              cotton.
            </li>
            <li>
              <strong>EMPLOYMENT*</strong> - Employment Rate in Turkey averaged
              43.43 percent from 2005 until 2021. The number of employed persons
              in Turkey increased to 29.855 Thousand in November of 2021 from
              29,627 Thousand in October of 2021.
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Ankara</li>
                <li>Istanbul</li>
                <li>Antalya</li>
              </ul>
            </li>
            <li>
              <strong>WEATHER</strong> - Turkey is situated between the
              subtropical zone and temperate zone. The climate zones observed in
              Turkey are the Mediterranean Climate where summers are hot and dry
              and the winters are mild and rainy.
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/ca2bc87d6135034900754af8a6295165/raw/bfc7c404e3e12f55f0796b17eb76e141739c0172/turkey-flag.svg"
              alt="Turkey flag"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            In 2022, Turkey's authorities decided to rebrand their country's
            international image. Turkey's President Recep Tayyip Erdoğan issued
            a communiqué, tweaking the country's internationally recognised name
            from "Turkey" to Türkiye. Turkey's unique geography is also a bridge
            between continents and one of the original sites of human
            settlement.
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6349270.021105543!2d30.68882645661244!3d39.01007790330397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b0155c964f2671%3A0x40d9dbd42a625f2a!2sTurkey!5e0!3m2!1sen!2ses!4v1646656939208!5m2!1sen!2ses"
            width="95%"
            title="turkey-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <h4 className="mt-4">Tourism</h4>
          <p>
            Turkey recorded a total of <strong>52 million* tourists</strong> in
            2019, ranking 11th in the world in absolute terms.
          </p>
          <h4 className="mt-4">Main Ports / Airports</h4>
          <table className="table table-striped text-center">
            <thead>
              <tr>
                <th scope="col">Rank</th>
                <th scope="col">Airport</th>
                <th scope="col">IATA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Istanbul Airport</td>
                <td>IST</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Sabiha Gokcen International Airport</td>
                <td>SAW</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Antalya Airport</td>
                <td>AYT</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Ankara Esenboğa Airport</td>
                <td>ESB</td>
              </tr>
            </tbody>
          </table>
          <p>
            Turkey has four major container ports:{' '}
            <strong>Haydarpasa, Ambarlı, Izmir and Mersin</strong>. Except
            Ambarli, the other three ports are operated by a large governmental
            agency namely TCDD
          </p>
          <h4 className="mt-5">National Sport</h4>
          <p>
            Oil wrestling, The traditional Turkish national sport has been the
            oil wrestling ("Yağlı güreş") since Ottoman times. Edirne hosts the
            annual Kırkpınar oiled wrestling tournament since 1361.
          </p>
          <h4 className="mt-5">Typical Food</h4>
          <p>
          Tukerys National dish is <strong>döner kebap</strong>, the most common preparations are roasting and grilling, which produce the famous Turkish kebaps, including döner kebap, the national dish, and köfte, the workingman's favorite.
          </p>
        </article>
      </section>

      <div className="container">
        <h4 className="my-5">Submarine Cables</h4>
        <a
          href="https://www.submarinecablemap.com/country/turkey"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/dWbnKt9.png"
            alt="submarine cables"
            width="100%"
          />
        </a>
        <ol className="mt-4">
          <li>Kafos</li>

          <li>MedNautilus Submarine Sytem</li>

          <li>SeaMeWE-3</li>

          <li>SeaMeWe-5</li>

          <li>Turcyos-1</li>

          <li>Turcyos-2</li>
        </ol>

        <h4 className="my-4">
          ACCESS TO LANDLINE AND MOBILE PHONE ACCESS TO INTERNET*
        </h4>
        <p>
          Total broadband subscribers reached 87.5 million at the end of the
          third quarter of 2021. In Turkey, fixed broadband usage has increased
          considerably thanks to the leadership of Türk Telekom's
          infrastructural investments since 2005.
        </p>

        <p>
          Accordingly, the number of fixed broadband subscribers increased to
          17.7 million. Fibre technology (FTTH/FTTB) increased by 22.8% year on
          year with number of subscribers reaching 4.6 million as of third
          quarter of 2021. Additionally, the number of xDSL subscribers was 11.3
          million and there were also 1.4 million cable internet subscribers at
          the end of Q3'21. Compared to OECD average of 33.2% fixed broadband
          population penetration rate, Turkey has important growth potential
          with its 21.2% penetration rate.
        </p>

        <p>
          Investments for fibre optic infrastructure have increased in the
          country in recent years. Türk Telekom with its 353 thousand kilometre
          fibre network has the widest fibre infrastructure in Turkey.
          Alternative operators fibre length was 101.9 thousand kilometre at the
          end of Q3'21.
        </p>

        <p>
          On the mobile broadband side, the total number of users was 69.7
          million corresponding to 83.4% population penetration rate at the end
          of Q3'21. Mobile broadband users via mobile phones reached 69.3
          million while mobile broadband users via non-phone mobile devices were
          456 thousand. Compared to OECD with average of 118.3% mobile broadband
          penetration rate, Turkey has important growth potential in terms of
          mobile broadband penetration.
        </p>

        <h4 className="my-4">Internet Providers*</h4>
        <p>
          In Turkey the average download is 30.31 mpbs and average upload speed
          8.31 mbps.
        </p>

        <p>
          The country continues to invest in the fibre infraestructure for them
          to have internet access in every corner of the country. Today it has 6
          Internet providers owning infrastructure who provide the access
          throughout the country. inte-QT as a global access
          enabler partner with the local providers and today, we have 4
          infrastructure owning partners in Turkey.{' '}
        </p>

        <h4 className="my-4">Our Capabilities</h4>
        <p>We provide services in <strong>Antalya, Istanbul, Ankara and Mersin</strong>. We provide best-in-class access and serve high fibre uptime solutions with guaranteed 24/7 monitoring thanks to our NSOC team.</p>

        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>SLA</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins"
          align="justify"
        >
          With our Global Business Solutions team, we can provide a{' '}
          <mark>Commercial Offer in 2-3 working days</mark> and our Global Ops
          team is capable to deliver services in the country in 6 to 8 weeks.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://knoema.com/atlas/Turkey/Population"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.flypgs.com/en/travel-guide/general-information-about-turkey#:~:text=Turkey%2C%20officially%20known%20as%20the,and%20Iraq%20to%20the%20southeast"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://www.worldatlas.com/articles/what-languages-are-spoken-in-turkey.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
          <li>
            <a
              href="https://tradingeconomics.com/turkey/employed-persons"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 4
            </a>
          </li>
          <li>
            <a
              href="http://www.ttyatirimciiliskileri.com.tr/en-us/turk-telekom-group/investing-in-turk-telekom/pages/turkish-telecom-sector.aspx#:~:text=Total%20broadband%20subscribers%20reached%2087.5,the%20third%20quarter%20of%202021.&text=Compared%20to%20OECD%20average%20of,with%20its%2021.2%25%20penetration%20rate"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 5
            </a>
          </li>
          <li>
            <a
              href="https://climateknowledgeportal.worldbank.org/country/turkey/climate-data-historical#:~:text=The%20climate%20zones%20observed%20in,Climate%20where%20temperature%20differences%20between "
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 6
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Turkey;
