import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TestimonialCarousel from '../../../components/TestimonialCarousel/TestimonialCarousel';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';

export default function ContactSales() {
  return (
    <>
      <Helmet>
        <title>Sales - inte-QT</title>
        <meta name="title" content="Sales - inte-QT | Get a Quote or Contact Us" />
        <meta name="description" content="Get a quote or contact our engineers for sales inquiries at inte-QT. Explore our sales options and get in touch today!" />
        <link rel="canonical" href="https://www.inte-qt.com/contact/sales" />
      </Helmet>

      <h1 className="text-center mx-auto center-header contact__title">Sales</h1>

      <div className="row contact__box">
        <div className="col-lg-3 col-md-12 contact__card">
          <Link to="/contact/quote" className="contact__link">
            <h3 className="contact__card-title">Get a Quote</h3>
            <p>To get a quotation, please fill out our sales form.</p>
          </Link>
        </div>
        <div className="col-lg-3 col-md-12 contact__card">
          <Link to="/contact/general" className="contact__link">
            <h3 className="contact__card-title">General</h3>
            <p>For all inquiries unrelated to Sales and Support.</p>
          </Link>
        </div>
      </div>

      <TestimonialCarousel />
      <GetInTouch />
    </>
  );
}
