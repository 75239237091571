import React from 'react';
import './Typewriter.scss';
import { Link } from 'react-router-dom';

export default function Typewriter() {
  return (
    <div className="typewriter mt-5">
      <div className="typewriter-container">
        <h2 className="header-animated">We cover 190+ countries across the globe</h2>
        <p className="typewriter__subtitle text-center"><Link to="/coverage" className="typewriter__link">Explore our coverage</Link></p>
      </div>
      <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop" className="rounded mx-auto d-block w-100">
        <source src="https://res.cloudinary.com/dql1gaina/video/upload/v1631815263/website/internet-video-small_dykbc9.mp4" type="video/mp4" />
      </video>
    </div>
  )
}
