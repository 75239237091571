import React from 'react'
import { Helmet } from 'react-helmet'


function CoverageNotFound() {
  return (
    <div className="container-fluid notFound mb-5">
      <Helmet>
        <title>404 Coverage Not Found - inte-QT</title>
        <meta name="title" content="404 Coverage Not Found - inte-QT" />
        <meta name="description" content="If you are seeing this message it means that we don't have specific page for this country yet, you can go back to the home page or see our sitemap in the footer of this page." />
      </Helmet>
      <h1 className="notFound__title center-header mx-auto text-center">
        Stay Tuned!
      </h1>
      <p className="notFound__sentence">
        We are in the process of updating our coverage. We cover 190+ countries. If you receive this message it means that we are building a specific page for this country. You can request a quote through the contact page or with the usual way.
      </p>
      <div className="notFound__container">
        <div>
          <div className="flex-item">
            <a href="/coverage">
              <img
                className="notFound__img"
                src="https://i.imgur.com/Q2BAOd2.png"
                alt="404"
              />
            </a>
          </div>
          <div className="flex-item mt-5">
            <a className="button" href="/">
              Go To Home
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CoverageNotFound