import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import ContactMap from '../../components/ContactMap/ContactMap';
import './Contact.scss';

export default function Contact() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="contact">
      <Helmet>
        <title>Contact Us - inte-QT</title>
        <meta name="title" content="Contact Us - inte-QT" />
        <meta name="description" content="For any kind of support you may need" />
        <link rel="canonical" href="https://www.inte-qt.com/contact" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <h1 className="text-center mx-auto center-header contact__title">
        What kind of support do you need?
      </h1>

      <div className="row contact__box">
        <div className="col-lg-4 col-md-6 col-sm-12 contact__card">
          <Link to="/contact/support" className="contact__link">
            <h3 className="contact__card-title">Support</h3>
            <p>For current inte-QT customers with inquiries about their connection</p>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 contact__card">
          <Link to="/contact/sales" className="contact__link">
            <h3 className="contact__card-title">Sales</h3>
            <p>For inquiries related to acquiring connections and internet plans</p>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 contact__card">
          <Link to="/contact/general" className="contact__link">
            <h3 className="contact__card-title">General</h3>
            <p>For all inquiries unrelated to Sales and Support</p>
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 col-md-12">
          <ContactMap />
        </div>
        <div className="col-lg-6 col-md-12 contact__font text-start">
          <h3>Contact Information</h3>
          <p className="text-start">If you wish to contact us in usual ways.</p>
          <hr />

          <div className="row">
            <div className="col-md-12 text-center mb-4">
              <h5 className="m-4">EUROPE</h5>
            </div>
            <div className="col-md-6 mb-4">
              <div>
                <h6 className="blue mt-3">COMPANY HEADQUARTERS</h6>
                <p>INTELLIGENT QUOTING TECHNOLOGY, S.L.</p>
                <p>Calle de Manzanares, 4</p>
                <p>28005 Madrid</p>
                <p>SPAIN</p>
                <p>
                  <i className="fas fa-phone-alt mx-2"></i>{' '}
                  <a href="tel:+34912604480" className="text-decoration-none blue">
                    (+44) 203 289 6070                  
                  </a>
                </p>
                <p>
                  <i className="fas fa-phone-alt mx-2"></i>{' '}
                  <a href="tel:+442032896070" className="text-decoration-none blue">
                    (+44) 203 289 6070
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div>
                <h6 className="blue mt-3">REGIONAL SALES OFFICE</h6>
                <p>INTELLIGENT QUOTING TECHNOLOGY S.L., GLOBAL SALES</p>
                <p>Calle Soria, 9</p>
                <p>28005 Madrid</p>
                <p>SPAIN</p>
                <p>
                  <i className="fas fa-phone-alt mx-2"></i>{' '}
                  <a href="tel:+34912604480" className="text-decoration-none blue">
                    (+44) 203 289 6070
                  </a>
                </p>
                <p>
                  <i className="fas fa-phone-alt mx-2"></i>{' '}
                  <a href="tel:+34620959970" className="text-decoration-none blue">
                    (+34) 620 95 99 70
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <h5 className="m-4 text-center">ASIA</h5>
            <h6 className="blue mt-3">INDIA REGIONAL OFFICE</h6>
            <p>inte-QT</p>
            <p>Jaipur 302019</p>
            <p>INDIA</p>
            <p>
              <i className="fas fa-phone-alt mx-2"></i>{' '}
              <a href="tel:+911414036244" className="text-decoration-none blue">
                (+44) 203 289 6070 
              </a>
            </p>
            <p>
              <i className="fas fa-phone-alt mx-2"></i>{' '}
              <a href="tel:+917208088770" className="text-decoration-none blue">
                (+91) 720 808 8770
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
