import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import emailjs from 'emailjs-com';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const ContactSalesQuote = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [phoneValue, setPhoneValue] = useState('');

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_eqzo3ks',
        'template_3y5tfzp',
        e.target,
        'sII_fVN7Ct9kkRQpN'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  const check = () => {
    const agreeCheckbox = document.querySelector('#partnerFormAgree');
    setIsChecked(agreeCheckbox.checked);
  };

  const showAlert = () => {
    if (isChecked) {
      showSuccessAlert();
    } else {
      showInfoAlert();
    }
  };

  const showSuccessAlert = () => {
    Swal.fire({
      title: 'Success',
      text:
        'We have received your form, we will review the information and get back to you soon.',
      icon: 'success',
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const showInfoAlert = () => {
    Swal.fire({
      title: 'Wait',
      text: 'You need to fill in all the data',
      icon: 'info',
      timer: 3000,
      timerProgressBar: true,
    });
  };

  return (
    <div>
      <Helmet>
        <title>Sales Quote - inte-QT</title>
        <meta name="title" content="Sales Quote - inte-QT" />
        <meta
          name="description"
          content="To get a quotation, please contact us by filling in the following form."
        />
        <link rel="canonical" href="https://www.inte-qt.com/contact/quote" />
      </Helmet>
      <h1 className="mx-auto center-header text-center general__title">
        Get a Quote
      </h1>

      <form onSubmit={sendEmail} className="general__form mx-auto">
        <div className="mb-3 general__box">
          <input
            type="text"
            id="company_name"
            className="form-control"
            name="company_name"
            placeholder="Company Name"
            required
          />
        </div>
        <div className="mb-3 general__box">
          <input
            type="plain/text"
            id="name"
            className="form-control"
            name="name"
            placeholder="Full Name"
          />
        </div>
        <div className="mb-3 general__box">
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="Email Address"
            required
          />
          <div id="emailHelp" className="form-text">
            We'll never share your email, address or phone number with anyone
            else.
          </div>
        </div>
        <div className="mb-3 general__box">
          <PhoneInput
            international
            defaultCountry="ES"
            className="form-control"
            placeholder="Enter phone number"
            value={phoneValue}
            onChange={setPhoneValue}
            name="phone"
          />
        </div>
        <div className="mb-3 general__box">
          <input
            type="text"
            className="form-control"
            id="address"
            name="address"
            placeholder="Full Address"
            required
          />
        </div>
        <div className="mb-3 general__box">
          <input
            type="text"
            className="form-control"
            id="county"
            name="county"
            placeholder="County"
            required
          />
        </div>
        <div className="mb-3 general__box">
          <input
            type="text"
            className="form-control"
            id="country"
            name="country"
            placeholder="Country"
            required
          />
        </div>
        <div className="mb-3 general__box">
          <input
            type="text"
            className="form-control"
            id="postal_code"
            name="postal_code"
            placeholder="Postal Code"
            required
          />
        </div>
        <div className="general__box mb-3">
          <label htmlFor="product" className="form-label">
            Select your product
          </label>
          <select
            id="product"
            className="form-select"
            aria-label="Product Select"
            name="product"
          >
            <option selected value="dedicated-lines">
              Dedicated Line
            </option>
            <option value="business-broadband">Business Broadband</option>
            <option value="wireless-connect">Wireless connect</option>
          </select>
        </div>
        <div className="general__box mb-3">
          <label htmlFor="contract" className="form-label">
            Term of contract
          </label>
          <select
            id="contract"
            className="form-select"
            aria-label="Term of contract"
            name="terms"
            required
          >
            <option selected value="1 year">
              1 Year
            </option>
            <option value="2 years">2 Years</option>
            <option value="3 years">3 Years</option>
          </select>
        </div>
        <div className="general__box mb-3">
          <label htmlFor="ip" className="form-label">
            IP
          </label>
          <select
            id="ip"
            className="form-select"
            aria-label="ip of the product"
            name="ip"
            required
          >
            <option selected value="/31">
              /31
            </option>
            <option value="/30">/30</option>
            <option value="/29">/29</option>
          </select>
        </div>
        <div className="mb-3 general__box">
          <textarea
            className="form-control"
            name="message"
            id="concern"
            cols="30"
            rows="5"
            placeholder="Message"
          ></textarea>
        </div>
        <div className="form-check mb-3 general__box">
          <input
            className="form-check-input"
            type="checkbox"
            value="1"
            id="partnerFormAgree"
            onChange={check}
            required
          />
          <label className="form-check-label" htmlFor="partnerFormAgree">
            Agree to terms and conditions of the{' '}
            <Link to="/privacy-policy">privacy policy</Link>.
          </label>
        </div>
        <div className="text-center">
          <button className="button" onClick={showAlert}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactSalesQuote;
