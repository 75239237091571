import React from 'react';
import './CarouselDesk.scss';

export default function CarouselDesk() {
  return (
    <div className="wave">
      <h1 className="text-center wave__header">
        A Wave of Disruptive Innovation in the Global VNO Market
      </h1>
      <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop" className="mx-auto d-block wave__video w-100">
        <source src="https://res.cloudinary.com/dql1gaina/video/upload/v1631815345/website/wave_sxtcsa.mp4" type="video/mp4" />
      </video>
    </div>
  );
}
