import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import DinamicLink from '../../../components/DinamicLink/DinamicLink';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../Blog.scss';

function Blog1() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="blog">
      <Helmet>
        <title>Customer Delight Approach - inte-QT</title>
        <meta name="title" content="Customer Delight Approach - inte-QT" />
        <meta
          name="description"
          content="Learn how inte-QT addresses the surge in demand for connectivity services amidst COVID-19 with robust service delivery and customer delight."
        />
        <link rel="canonical" href="https://www.inte-qt.com/blog/1" />
      </Helmet>
      <h1 className="center-header center-header__title mx-auto text-center">
        Customer Delight Approach
      </h1>
      <div className="container">
        <div className="blog__text" align="justify">
          <div className="text-end mb-5">
            <p className="blogPosts__tags">
              <span className="blue mx-2">Service Delivery</span>/
              <span className="blue mx-2">Customer Delight</span>/
              <span className="blue mx-2">COVID-19</span>
            </p>
          </div>
          <p>
            {`By `}
            <a
              href="https://www.linkedin.com/in/nikhil-jain-705a6943/"
              className="blue"
            >
              {`Nikhil Jain `}
              <i className="fab fa-linkedin-in ms-1"></i>
            </a>
            {` on August 17th, 2020 · 1 min read`}
          </p>
          <p>
            A significant part of world’s population is affected amidst COVID-19
            pandemic, the people are utilizing connectivity services more not
            only for normal browsing and streaming platforms but office
            applications to work from home and online education platforms. These
            high-quality content platforms and the new avenues have demanded
            faster and better quality towards access side of the network. The
            new normal will continuously require reliable and faster network
            connectivity.
          </p>
          <p>
            Requirements of dedicated internet services are increasing from
            enterprises as well as from households which demands faster and
            reliable deployment of services.
          </p>
          <p>
            To address this surge in demand{' '}
            <a
              href="https://www.linkedin.com/company/bitsandbyteglobal/"
              className="blue"
            >
              inte-QT
            </a>{' '}
            is providing quality services according to customer requirements and
            preferences. The process followed is robust in nature, it ensures
            not only meeting customer needs but also exceed customer
            expectations. The service delivery process captured in a snapshot is
            as:
          </p>
          <img
            src="https://i.imgur.com/52EZD6B.png"
            alt="service delivery"
            className="blog__img blog__img-big"
          />
          <p>
            We are a global aggregator offering Layer-3 Internet services in all
            flavors as access solutions across the globe. We cover 183 countries
            with local partners in each of them. We have an effective partner
            portfolio, and a competent team for service delivery ascertaining
            customer delight.
          </p>
        </div>

        <div className="blogPosts__box">
          <div className="blogPosts__imgbox me-5">
            <img
              src="https://lh3.googleusercontent.com/UXP5LN8rJ8eIBmsKZYZz_9Tb2Tzpb4k6tJ0WcJxFtohiBoHv7IXhzEP4orFLZ9wjiURL1323A8ufv9xEBZBCTlsDVqvHpzmX_r7UobhyGmZYp1fz_2KofBqHlzT4cr9_VBzWA9H1dtaaYdOnnXI9z56LpJTa0A3fTSvJ8CyG_OEOvFhyQVl0Hn3j8xgWHNcVacMC5hwJRVT9ZrauY0g0ClJtNb44rEEPmGAWGlu6CKG5XaR4WKxdrGrHPPxNgaYmBvM15AqLK_XrVyf-1IpNaWPPor9UKnkmTKSVXAMjX3o_9lNrfISrPpD0AWvrWk-jVYKCeOAtrfVOEej-XF_iGCS_5NQRGe-J0XuThL0_1LIRgxohQAH9mKBqxxfKlbIYdiHrbLs8HhJOPkYTZL7sNgXziazHgzCVwsNM7HhsdOeQJFYaZNaOGiSOfhWjHMZTwAYxnQttqJnZuwb25NP0Ga89UZAF2mkUFGFx3FhP13-_bvrv8mN0Z5dmPZdFLy9GhkF6Q62U-DFJ60_S_Sq2Z9qEgSQXGofZwUSVtw2Di_8wet6GfTqzp409h4naIgOSbAoEsE-DLO_S_P_pLYJUvgJU_5VCkjuLZ5OWv28lVCMSIsDar9HGCM2Orqe1RzNwmDgVGY-xgWdvbTzaeoqHsB89_N2nfpFZkbjKCllO2YiFidYTn8_q8B6DkA41iB_7xJRf0rjFIc7A4toguwV9sIDRD0g0xov5EGW5pCUfz5DdoMmHg-fZDkxGQeokab_Omsjyem4-V4uvtcFRD971e-eIBdikBA=w1280-h853-no?authuser=0"
              alt=""
              className="blogPosts__img"
            />
          </div>
          <p className="blogPosts__tags">
            <span className="blue mx-2">Service Assurance</span>/
            <span className="blue mx-2">SLA%</span>/
            <span className="blue mx-2">MTTR</span>
          </p>
          <h3 className="blogPosts__title">
            Service Assurance: A Responsibility That Cannot Have A No Show
          </h3>
          <p className="blogPosts__author">
            {`By `}
            <a
              href="https://www.linkedin.com/in/nikhil-jain-705a6943/"
              className="blue"
            >
              {`Nikhil Jain `}
              <i className="fab fa-linkedin-in ms-1"></i>
            </a>
            {` on September 28th, 2021 · 3 min read`}
          </p>

          <p>
            In the world of Digital Era, it is impossible to live without being
            connected. Lives and Businesses can’t remain healthy unless
            Information Technology can deliver...
          </p>
          <div className="text-end me-5">
            <DinamicLink url="/blog/5" name="Read more" />
          </div>
        </div>

        <GetInTouch />
      </div>
    </div>
  );
}

export default Blog1;
