import React, { useEffect } from 'react';
import DinamicLink from '../../../components/DinamicLink/DinamicLink';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../../Cases/Cases.scss';
import cases from '../../../data/casesInfo.json';
import './CaseFijiv2.scss';
import { Helmet } from 'react-helmet';

export default function CaseFiji() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="caseFiji">
      <Helmet>
        <title>Case Fiji - inte-QT</title>
        <meta name="title" content="Case Fiji - inte-QT" />
        <meta
          name="description"
          content="Connecting a BFSI company in the far East of the World, Fiji. Explore how inte-QT addressed connectivity challenges."
        />
        <meta name="keywords" content="Fiji, BFSI, connectivity, network solutions, inte-QT, case study" />
        <link rel="canonical" href="https://inte-qt.com/cases/fiji" />
      </Helmet>
      <img
        src="https://i.imgur.com/diCPBGP.jpg"
        alt="Connectivity challenges in Fiji's banking sector"
        className="banner-img banner-img__black"
      />
      <h1 className="caseFiji2__title text-center">
        Navigating Connectivity Challenges in Fiji’s Banking Sector
      </h1>
      <div className="caseFiji2__div"></div>

      <div className="container-fluid caseFiji">
        <div className="container">
          <div className="caseFiji2__width my-5">
            <div className="caseFiji2__space m-4">
              <img
                src="https://i.imgur.com/847uR6R.png"
                alt="Technology challenges in banking"
                className="caseFiji2__icon mx-3"
              />
              <h2 className="pt-4">Challenges:</h2>
            </div>
            <ul>
              <li>Faster Deployment and Migration</li>
              <li className="mt-2">Secure Access to Cloud Applications</li>
              <li className="mt-2">Integration of Remote Offices with the Mainland</li>
              <li className="mt-2">Adverse Climate Conditions</li>
              <li className="mt-2">Unavailability of Required Infrastructure</li>
            </ul>
          </div>
          <div className="caseFiji2__width my-5 caseFiji2__margin">
            <div className="caseFiji2__space m-4">
              <img
                src="https://i.imgur.com/4s6vXof.png"
                alt="Wireless connectivity solution"
                className="caseFiji2__icon mx-3"
              />
              <h2 className="pt-4">Solution:</h2>
            </div>
            <ul>
              <li>
                Robust and Reliable Network Deployment as part of inte-Qt
                Dedicated Line Service
              </li>
              <li className="mt-2">
                Flawless Installation of inte-Qt Dedicated Line Service ensured
                seamless go-live of multiple locations.
              </li>
              <li className="mt-2">
                24x7 Network Assurance with inte-Qt Dedicated Line Service enabled
                real-time resolution of customer operations.
              </li>
            </ul>
          </div>

          <div className="caseFiji2__width my-5">
            <div className="caseFiji2__space m-4">
              <img
                src="https://i.imgur.com/55CYPwa.png"
                alt="Successful outcomes"
                className="caseFiji2__icon mx-3"
              />
              <h2 className="pt-4">Outcomes:</h2>
            </div>
            <ul>
              <li>Sustainable and seamless operations for the user.</li>
              <li className="mt-2">
                Significant savings for the customer and overall service effectiveness.
              </li>
              <li className="mt-2">Exceeding customer expectations.</li>
            </ul>
          </div>

          <h2 className="my-5">Challenge:</h2>
          <p align="justify">
            At inte-QT, we believe that challenges make us stronger and better with each delivery.
          </p>
          <p align="justify">
            The customer required connectivity for a reputed bank located in Fiji, a country of islands where the sun rises as it sets in the West.
          </p>
          <img
            src="https://i.imgur.com/Dvbfhyz.jpg"
            alt="Beautiful beach in Fiji"
            className="caseFiji2__textimg caseFiji2__right m-3"
          />
          <p align="justify">
            The customer and end-user sought quick deployment and installation of links due to the critical nature of banking operations. Timely delivery was essential given the extensive requirements across the country's geography.
          </p>
          <p align="justify">
            User operations had restricted access, making secure service paramount.
          </p>
          <p align="justify">
            Being in a seismic zone prone to natural calamities like cyclones, accessing sites for connectivity deployment proved difficult.
          </p>
          <p align="justify">
            Challenges included infrastructure shortages, timely equipment availability, and the need to avoid disruptions to existing services.
          </p>
          <p align="justify">
            Additionally, commercial terms needed to be aggressive to ensure savings.
          </p>

          <h2 className="my-5">Solution:</h2>
          <p align="justify">
            Deeper customer engagement has always been part of the culture at Bits and Byte Global. Our customer appreciates the support we provide, allowing for open communication of requirements and concerns.
          </p>
          <img
            src="https://i.imgur.com/4O7Reay.jpg"
            alt="Technology implementation"
            className="caseFiji2__textimg caseFiji2__left m-3"
          />
          <p align="justify">
            We engaged all relevant interfaces to address the customer's needs. Local partners and service providers were informed of the critical nature of the service.
          </p>
          <p align="justify">
            Logistics were aligned to ensure installations were completed without delays. Relevant teams ensured services went live smoothly.
          </p>
          <p align="justify">
            The network design focused on safety and security of user data with cloud service providers, including necessary redundancies to mitigate geographical vulnerabilities.
          </p>
          <p align="justify">
            inte-Qt Dedicated Line service offers commitment across all fronts, from bandwidth to 24x7 network support. Rigorous tests were conducted before declaring services ready.
          </p>

          <h2 className="my-5">Outcome:</h2>
          <p align="justify">
            Collaborative efforts led to timely delivery of services, meeting all quality parameters. Sustainable operations were achieved, with various links live and connected to the cloud.
          </p>
          <p align="justify">
            The execution resulted in significant monetary savings, estimated at around 38%, without compromising service quality. The precise design improved operational effectiveness through prompt monitoring.
          </p>
          <p align="justify">
            The customer and end-user appreciated inte-Qt's Dedicated Line service and network support, yielding both tangible benefits and improved service parameters.
          </p>
          <img
            src="https://i.imgur.com/7fH1NGe.jpg"
            alt="Satisfied customers"
            className="caseFiji2__lastimg rounded"
          />

          <div className="cases__box">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h2 className="cases__companyName mx-3">{cases[2].title}</h2>
                <p className="cases__text m-3" align="justify">
                  {cases[2].p5}
                </p>
                <div className="text-end mx-3">
                  <DinamicLink url="/cases/argentina" name="Read more" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <img src={cases[2].img} alt="Argentina case" className="cases__img" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <GetInTouch />
    </div>
  );
}
