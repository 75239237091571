import React, { useEffect } from 'react';
import '../CoverageFormat.scss';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import { Helmet } from 'react-helmet';

function DominicanRepublic() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Dominican Republic - inte-QT</title>
        <meta
          name="title"
          content="Internet in Dominican Republic - inte-QT"
        />
        <meta name="description" content="Our Coverage in Dominican Republic" />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/dominicanrepublic" />
      </Helmet>
      <img
        src="https://i.imgur.com/C8V8U0c.jpg"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />
      <h1 className="CoverageFormat__title text-center">
        LOCATED ON THE ISLAND OF "LA HISPANIOLA"
      </h1>

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - 10.90 million*
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES</strong> - The island nation is
              bordered by <strong>Haiti</strong> in west and it shares maritime
              borders with Colombia, Venezuela, Aruba (Netherlands), Curacao
              (Netherlands), Puerto Rico (United States), and Turks and Caicos
              Islands (United Kingdom).
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - Spanish
            </li>
            <li>
              <strong>CURRENCY</strong> - Dominican Peso
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Its economy its highly dependent on
              tourism, agriculture, mining, cement, tobacco and sugar
              production.
            </li>
            <li>
              <strong>EMPLOYMENT</strong> - 56.8 percent*
            </li>
            <li>
              <strong>TOP 3 CITIES</strong> - Santo Domingo, Santiago de los
              Caballeros, San Pedro de Macoris
            </li>
            <li>
              {' '}
              <strong>TOURISM</strong> - More than 6.4 million*{' '}
            </li>
            <li>
              <strong>WEATHER</strong> - Moderate, relatively mild tropical
              climate. Dominicans say their island has the endless summer.
            </li>
            <li>
              <strong>NATIONAL SPORT </strong>- Baseball is a central part of
              the Dominican culture, being the most important one so that Major
              League Baseball recruits Dominican players.
            </li>
          </ul>{' '}
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/2f7ebb0fac50d497fb9ff946898787f7/raw/f998718d488d26056d7e04e748c5bf378fba4248/dominican-flag.svg"
              alt="dominican republic flag"
              className="my-5"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            Country located on the island of ''La Hispaniola" in the Caribbean.
            Discovered in 1492 by Christopher Colombus during the first voyage
            across the Atlantic looking for new routes for the Spanish to go to
            the India for the trade of spices. It is the largest economy in the
            Caribbean and Central America region and the 10th largest in Latin
            America. Because of its startegic location, in the last few years
            Dominican Republic has become more and more attractive for
            international investors.
          </p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1935300.8942961085!2d-71.25135073975223!3d18.669175595152634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eaf8838def1b6f5%3A0xa6020f24060df7e0!2sDominican%20Republic!5e0!3m2!1sen!2ses!4v1642674914005!5m2!1sen!2ses"
            width="95%"
            title="dominican-republic-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>

          <h4 className="mt-4">Main Ports / Airports</h4>
          <p>
            Dominican Republic counts with 15 Seaports, being the more
            importants:{' '}
          </p>
          <ul>
            <li>Port of Santo Domingo. DOSDQ, Santo Domingo.</li>
            <li>Port of Rio Haina. DOHAI, Haina.</li>
            <li>Port of Puerto Plata. DOPOP, San Felipe.</li>
            <li>Port of Barahona. DOBRX, Santa Cruz de Barahona.</li>
            <li>Port of Caucedo. DOBCC, Boca Chica.</li>
            <li>Port of Samana. DOAZS, Samana Peninsula.</li>
          </ul>
        </article>
      </section>
      <div className="container">
        <p>
          Dominican Republic has a count of 7 international aiports, each of
          them strategically located near the most popular cities. The most
          important ones due to the amount of people accessing are:{' '}
        </p>
        <ol>
          <li>Punta Cana International Airport, Punta Cana. </li>
          <li>Las Américas International Airport, Santo Domingo. </li>
          <li>Cibao International Airport, Santiago de los Caballeros.</li>
          <li>Gregorio Luperón International Airport, Puerto Plata. </li>
        </ol>

        <h4 className="mt-5">Typical Food</h4>
        <ul>
          <li>Sancocho, a stew with seven types of meat.</li>
          <li>
            The Dominican flag, the national dish prepared with chicken, beans
            and rice.
          </li>
          <li>Mangú, easy and delicious.</li>
          <li>Dominican rice, standard on every table.</li>
          <li>Mofongo, an African gift.</li>
          <li>Tostones, perfect anytime.</li>
          <li>Fried fish, the flavor of the sea. </li>
        </ul>

        <h4 className="mt-5">Connectivity details*</h4>
        <table className="table table-striped table-responsive">
          <thead>
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Millions</th>
              <th scope="col">Porcentage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Broadband users</td>
              <td>1.03 m</td>
              <td>9.51 %</td>
            </tr>
            <tr>
              <td>Landlines</td>
              <td>1.03 m</td>
              <td>10.65 %</td>
            </tr>
            <tr>
              <td>Mobile cellulars</td>
              <td>8.99 m</td>
              <td>82.87 %</td>
            </tr>
            <tr>
              <td>Access to internet</td>
              <td>8.08 m</td>
              <td>79 % per 100 inhabitants</td>
            </tr>
          </tbody>
        </table>

        <h4 className="mt-5">Submarine Cables</h4>
        <a
          href="https://www.submarinecablemap.com/country/dominican-republic"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/EvT9bqq.jpg"
            alt="submarine cables"
            width="100%"
            className=""
          />
        </a>
        <ol className="mt-4">
          <li>America Movil Submarine Cable System-1 (AMX-1) </li>
          <li>Antillas 1 </li>
          <li>ARCOS </li>
          <li>Boriken Submarine Cable System (BSCS)(2023) </li>
          <li>East-West </li>
          <li>Fibralink </li>
          <li>South America-1 (SAm-1) </li>
        </ol>

        <h4 className="mt-4">Internet Providers*</h4>

        <p>
          Its an amazing fact that Dominican Republic being an island is very
          well connected, with 7 submarine cables landing and every day more and
          more people having access to internet. A total of 8,08 million* people
          in the entire country accessing Internet.{' '}
        </p>

        <p>
          The Average Internet Download speed in the country is 13mbs and
          average upload speed is 7,5mbs. Dominican Republic has 32 provinces,
          but fiber cables are only available in 19 of them as the other 13
          provinces are rural places. Wireless i.e. Microwave Solutions is
          available throughout the island. The Dominican Republic government
          plans to continue investing in the infrastructure to get better in
          terms of internet connectivity throughout the country.{' '}
        </p>

        <p>
          The country has 13 Internet providers owning infrastructure who
          provide the access throughout the country. inte-QT as a
          global access enabler partners with the local providers and today, we
          have 7 infrastructure owning partners in the Dominican Republic.{' '}
        </p>

        <p>Our main capabilities are described in the table below:</p>
        <table className="table table-striped">
          <thead>
            <th scope="col">Capabilities</th>
            <th scope="col">
              <i class="fas fa-check"></i>
            </th>
          </thead>
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connect</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>SLA</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins"
          align="justify"
        >
          With our global business solutions team, we can provide a{' '}
          <mark>Commercial Offer in 1-2 working days.</mark>
          Our Global Ops team is capable to deliver services in the region, in
          major cities in 10 to 14 working days and in other regions in 4 to 6
          weeks.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://tradingeconomics.com/dominican-republic/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://datatopics.worldbank.org/world-development-indicators/ "
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://www.statista.com/statistics/816404/dominican-republic-number-of-tourist-arrivals/  "
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
          <li>
            <a
              href="https://www.worlddata.info/america/dominican-republic/telecommunication.php  "
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 4
            </a>
          </li>
          <li>
            <a
              href="https://www.statista.com/statistics/1055456/internet-penetration-dominican-republic/  "
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 5
            </a>
          </li>
          <li>
            <a
              href="https://www.itu.int/net4/itu-d/icteye#/  "
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 6
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default DominicanRepublic;
