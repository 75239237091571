import React from 'react';
import './PartnerBenefits.scss';

export default function PartnerBenefits() {
  return (
    <div className="partnerBenefits text-center my-3">
      <p>
        Global <strong className="blue">Interfacing</strong>
      </p>
      <i className="fas fa-chevron-down my-4 partnerBenefits__arrow"></i>
      <p>
        Transparency of <strong className="blue">Deal Cycle</strong>
      </p>
      <i className="fas fa-chevron-down my-4 partnerBenefits__arrow"></i>
      <p>
        A Forum to <strong className="blue">Gain</strong>
      </p>
      <i className="fas fa-chevron-down my-4 partnerBenefits__arrow"></i>
      <p>
        Opportunity to <strong className="blue">Grow</strong>
      </p>
      <i className="fas fa-chevron-down my-4 partnerBenefits__arrow"></i>
      <p>
        Efficient <strong className="blue">Quote to Cash</strong>
      </p>
    </div>
  );
}
