import React from 'react';
import '../ConnectivitySolutions/ConnectivitySolutions.scss';

export default function ManagedSolutions() {
  return (
    <div>
      <div className="row">
        <div className="col-lg-4 col-md-12">
          <div className="card__bodyup">
            <div className="card__container">
              <div className="card__content">
                <div className="card__front card__front-background4">
                  <h3 className="card__title">Hardware Deployment & Management</h3>
                </div>

                <div className="card__back">
                  <div className="card__body">
                    <p className="card__fz-ssm" align="justify">inte-QT provides telecom network connectivity services in more than 183 countries across the world. It has very strong relationships with carriers and channel partners in the regions and the countries who install Commission, configure telecom equipment's as per the requirements. inte-QT do you have the capabilities to provide hardware deployment services with their management in the far flung and remote areas off the countries. It can deploy hardware such as switches routers. hubs, bridges, firewalls etc. We do manage the hardware devices as per the agreed Service Level Agreements (SLA) and turnaround times (TAT).</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-12">
          <div className="card__bodyup">
            <div className="card__container">
              <div className="card__content">
                <div className="card__front card__front-background5">
                  <h3 className="card__title">Security Management</h3>
                </div>

                <div className="card__back">
                  <div className="card__body">
                    <p className="card__fz-ssm" align="justify">Telecommunication networks today are critical national infrastructure. Safeguarding these networks from attacks, that could lead to unavailability and breach in confidentiality of network services, is very much important now. inte-QT has effective Security management service offerings which ensure the sustainable network performance against those threats. The security solutions are smart, intelligent and autonomous for safeguarding against various kind of network security challenges.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-12">
          <div className="card__bodyup">
            <div className="card__container">
              <div className="card__content">
                <div className="card__front card__front-background6">
                  <h3 className="card__title">Network Design & Management</h3>
                </div>

                <div className="card__back">
                  <div className="card__body">
                    <p className="card__fz-ssm" align="justify">inte-QT provides network design and management services for enterprise customers globally. We have global presence with expertise in Analysis, Planning, Designing and Deployment of a Network including low level designs and high level designs. We design and execute the implementation as per the needs. Our designs are adaptable scalable and can be managed within agreed timelines. We do manage the customer operations efficiently and adheres to quality of service . We have expertise in network designing with cloud and migration towards cloud from existing infrastructures.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-12">
          <div className="card__bodyup">
            <div className="card__container">
              <div className="card__content">
                <div className="card__front card__front-background7">
                  <h3 className="card__title">Business Process Outsourcing</h3>
                </div>

                <div className="card__back">
                  <div className="card__body">
                    <p className="card__fz-ssm" align="justify">inte-QT team offers BPO services in key customer operations like sourcing, order management, network operations and managed services, partner management, contract management, sales analytics, traffic routing and planning, project and service delivery management. inte-QT team is well equipped to handle these outsourced services managing expectations, delivery and cost pressures.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-12">
          <div className="card__bodyup">
            <div className="card__container">
              <div className="card__content">
                <div className="card__front card__front-background8">
                  <h3 className="card__title">Cloud Management</h3>
                </div>

                <div className="card__back">
                  <div className="card__body">
                    <p className="card__fz-ssm" align="justify">inte-QT is the global cloud enabler, we are offering Private cloud, public cloud and Hybrid cloud with High data security, Network redundancy, Low IT infrastructure cost, Unlimited resources, High flexibility. Our Cloud management program improve cloud computing journey with a seamless, flexible, secure, and cost optimized approach. We are managing and delivering IaaS, PaaS, and SaaS cloud computing services with different key global vendors.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-12">
          <div className="card__bodyup">
            <div className="card__container">
              <div className="card__content">
                <div className="card__front card__front-background9">
                  <h3 className="card__title">Data Center Connects</h3>
                </div>

                <div className="card__back">
                  <div className="card__body">
                    <p className="card__fz-ssm" align="justify">inte-QT provides professional solutions of data center towards Planning, Designing, Execution and Optimizing the network. Our data centers solutions have network security with firewall, storage system with data backup, servers with high uptime and application delivery controllers with high application delivery assurance. We offering one stop solution for hosting ,deployment and manage the application, web and other services of customers in data centers.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
