import React from 'react';
import { Link } from 'react-router-dom';
import './DinamicLink.scss';

export default function DinamicLink(props) {
  const { url } = props;
  const { name } = props;

  return (
    <div className="readmore">
      <Link to={url} className="text-decoration-none readmore__button">
        {name} <i className="fas fa-arrow-right readmore__arrow"></i>
      </Link>
    </div>
  );
}
