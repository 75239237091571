import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './ContactGeneral.scss';
import emailjs from 'emailjs-com';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function ContactGeneral() {
  const [check, setCheck] = useState(false);
  const [value, setValue] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    if (!check) {
      showAlert('info', 'You need to agree to terms and conditions.');
      return;
    }

    emailjs
      .sendForm(
        'service_cxy3wvs',
        'template_qfce9va',
        e.target,
        'XTHb8nGPkWwJjz2yf'
      )
      .then(
        (result) => {
          console.log(result.text);
          showAlert('success', 'We have received your form. We will get back to you soon.');
          e.target.reset();
          document.getElementById('company-name').focus(); 
        },
        (error) => {
          console.log(error.text);
          showAlert('error', 'There was an error sending your message. Please try again later.');
        }
      );
  };

  const showAlert = (icon, text) => {
    Swal.fire({
      title: icon === 'success' ? 'Success' : 'Wait',
      text: text,
      icon: icon,
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: false
    });
  };

  const handleCheck = (e) => {
    setCheck(e.target.checked);
  };

  return (
    <div className="general">
      <Helmet>
        <title>General Contact - inte-QT</title>
        <meta name="title" content="General Contact - inte-QT" />
        <meta name="description" content="General support for any type of inquiry" />
        <link rel="canonical" href="https://www.inte-qt.com/contact/general" />
      </Helmet>
      <h1 className="mx-auto center-header text-center general__title">General</h1>

      <form onSubmit={sendEmail} className="general__form mx-auto">
        <div className="mb-3 general__box">
          <label htmlFor="company-name" className="form-label">Company Name</label>
          <input
            type="text"
            id="company-name"
            className="form-control"
            name="company_name"
            placeholder="Company Name"
            required
          />
        </div>
        <div className="mb-3 general__box">
          <label htmlFor="name" className="form-label">Full Name</label>
          <input
            type="text"
            id="name"
            className="form-control"
            name="name"
            placeholder="Full Name"
            required
          />
        </div>
        <div className="mb-3 general__box">
          <label htmlFor="email" className="form-label">Email Address</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="Email Address"
            required
          />
          <div id="emailHelp" className="form-text">
            We'll never share your email or phone number with anyone else.
          </div>
        </div>
        <div className="mb-3 general__box">
          <label htmlFor="react-phone" className="form-label"></label>
          <PhoneInput
            international
            defaultCountry="ES"
            className="form-control"
            value={value}
            onChange={setValue}
            name="phone"
          />
        </div>
        <div className="mb-3 general__box">
          <label htmlFor="concern" className="form-label">How can we support you?</label>
          <textarea
            className="form-control"
            name="message"
            id="concern"
            cols="30"
            rows="5"
            placeholder="How can we support you?"
            required
          ></textarea>
        </div>
        <div className="form-check mb-3 general__box">
          <input
            className="form-check-input"
            type="checkbox"
            value="1"
            id="partnerFormAgree"
            onChange={handleCheck}
            required
          />
          <label className="form-check-label" htmlFor="partnerFormAgree">
            Agree to terms and conditions of the{' '}
            <Link to="/privacy-policy">privacy policy</Link>.
          </label>
        </div>
        <div className="text-center">
          <button type="submit" className="button">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
