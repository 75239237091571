import React, {useEffect} from 'react';
import './AboutUs.scss';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import AboutUsGallery from '../../components/AboutUsGallery/AboutUsGallery';
import CarouselIndu from '../../components/CarouselIndu/CarouselIndu';
import { Helmet } from 'react-helmet';

export default function AboutUs() {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  return (
    <div className="aboutUs">
      <Helmet>
        <title>About Us - inte-QT</title>
        <meta name="title" content="About Us - inte-QT"/>
        <meta property="og:title" content="About Us - inte-QT" />
        <meta
            property="twitter:title"
            content="About Us - inte-QT"
            data-react-helmet="True"
        />
        <meta
            name="description"
            content="A little bit of information about who we are and what we do."
        />
        <meta
            property="og:description"
            content="A little bit of information about who we are and what we do."
        />
        <meta
            property="twitter:description"
            content="A little bit of information about who we are and what we do."
            data-react-helmet="True"
        />
        <link rel="canonical" href="https://www.inte-qt.com/about-us" />
        <meta property="og:url" content="https://www.inte-qt.com/about-us"/>
        <meta property="twitter:url" content="https://www.inte-qt.com/about-us"/>
      </Helmet>
      <img src="https://i.imgur.com/Rgam7cI.jpg" alt="" className="banner-img banner-img__black" />
      <h1 className="banner-img__header text-center">
        About inte-QT
      </h1>
      <div className="aboutUs__box" align="justify">
        <div className="b_read">
          <p>"inte-QT" is derived from "integrity" and "equity," two fundamental principles that underpin our business philosophy. We believe that this new name encapsulates our dedication to honesty, fairness, and quality, which have always been at the heart of our partnership. At inte-QT, our mission is to empower the Service Providers (Internet, Telco, Cloud and SDWAN) and System Integrators with the best-in-class access service, global internet connectivity and managed services all across the globe. This name represents our dedication to providing cutting-edge technology solutions to simplify quoting processes and enhance your experience. We are an international team for our customers, and we have a knack to deliver on commitments. Our Global NSOC (Network and Security Operation Center), and is 24X7X365 Operational.</p>
  
          <p className="my-5">Headquartered in the center of Madrid-SPAIN, We follow the best practices and certified processes with our members experienced in the field of Telecommunications. We have our Global 24X7 Network & Security Operations Center NSOC where the teams manage technical solutions, project management and global all layer support. Our Supplier management team has high quality experience in the Internet & Managed Service markets all over the globe. We are vendor and box agnostic. Our partner center program is effective in upgrading our partner network exclusively in every corner of this world.</p>
        </div>
      </div>
      <AboutUsGallery />
      <h2 className="center-header center-header__title mx-auto text-center">We help not One, but many Industries.</h2>
      <CarouselIndu />
      <GetInTouch />
    </div>
  )
}
