import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function Japan() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Japan - inte-QT</title>
        <meta name="title" content="Internet in Japan - inte-QT" />
        <meta name="description" content="Our Coverage in Japan" />
        <link
          rel="canonical"
          href="https://www.inte-qt.com/coverage/japan"
        />
      </Helmet>
      <img
        src="https://i.imgur.com/490TDWX.jpg"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION*</strong> - <strong>125.40 Million </strong>- In
              the long-term, the Japan Population is projected to trend around
              124.90 Million in 2022 and 124.50 Million in 2023, according to
              our econometric models.
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - The island nation
              stretches from the Sea of Okhotsk in the north to the East China
              Sea in the south. Japan shares maritime borders with PR China,
              North Korea, South Korea, the Philippines, Russia, Northern
              Mariana Islands (United States), and the Republic of China
              (Taiwan).
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - The official language is
              Japanese, and is the only language spoken by the vast majority of
              the population.
            </li>
            <li>
              <strong>CURRENCY</strong> - The yen (Japanese: 円, symbol: ¥;
              code: JPY; also abbreviated as JP¥) is the official currency of
              Japan.
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Japan's major export industries
              includes automobiles, consumer electronics, computers,
              semiconductors, and iron and steel. Additionally, key industries
              in Japan's economy are mining, nonferrous metals, petrochemicals,
              pharmaceuticals, bioindustry, shipbuilding, aerospace, textiles,
              and processed foods.
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Tokyo</li>
                <li>Yokohama</li>
                <li>Osaka</li>
              </ul>
            </li>
            <li>
              <p>
                <strong>WEATHER</strong> - Eastern Japan has hot and humid
                summers and cold winters with very heavy snow on the Sea of
                Japan side and in mountainous areas. Western Japan has very hot
                and humid summers (with temperatures sometimes reaching 35 oC or
                above) and moderate cold winters. Okinawa and Amami have a
                subtropical oceanic climate.
              </p>
            </li>
            <li>
              <p>
                <strong>NATIONAL SPORT</strong> - Sumo wrestling is the national
                sport in Japan. Sumo wrestling is believed to have originated in
                Japan, with its governing body being the Japan Sumo Association.
              </p>
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/9fb8b70032d0d7381cedd79374cbafde/raw/0ae1cb1058af9a2bd936e2e8445acd43485bbdf7/japan-162328.svg"
              alt="Japan flag"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            Japan is an island country which has nearly 7000 islands, of which
            only 421 islands are inhabited. Japan's "mainland" consists of four
            primary islands: Hokkaido, Honshu, Kyushu, and Shikoku.
          </p>

          <p>
            Japan is known worldwide for its traditional arts, including tea
            ceremonies, calligraphy and flower arranging. The country has a
            legacy of distinctive gardens, sculpture and poetry. Japan is home
            to more than a dozen UNESCO World Heritage sites and is the
            birthplace of sushi, one of its most famous culinary exports.
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27666078.85134878!2d119.0279051098724!3d32.17169526524557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34674e0fd77f192f%3A0xf54275d47c665244!2sJapan!5e0!3m2!1sen!2ses!4v1653989295349!5m2!1sen!2ses"
            width="95%"
            title="Japan-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <h4 className="mt-4">Tourism*</h4>
          <p>
            The number of foreign visitors to Japan in 2021 dropped to 245,900,
            the lowest since 1964 when comparable data became available, as the
            country enforced tighter border controls amid the coronavirus
            pandemic, government data showed Wednesday.
          </p>

          <p>
            In 2020, the number of foreign visitors traveling to Japan amounted
            to around 4.12 million, a sharp decrease from 31.88 million in the
            previous year.
          </p>

          <p>
            Japan is one of the oldest civilizations and has a beautiful and
            diverse history. The stunning, diverse scenery with mountains and
            breathtaking views, which are much appreciated by the Japanese,
            offers so many different experiences that attract tourists from all
            corners of the world.
          </p>

          <h4 className="mt-4">Main Ports / Airports</h4>

          <p>
            There are 5 international airports in Japan and 24 local airports
            that operate domestic flights, totaling 29 airports located across
            its four main islands and smaller islands.
          </p>

          <table className="table">
            <thead>
              <tr>
                <th>IATA</th>
                <th>Name</th>
                <th>City</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>NRT</td>
                <td>Narita International Airport</td>
                <td>Tokyo</td>
              </tr>
              <tr>
                <td>KIX</td>
                <td>Kansai International Airport</td>
                <td>Osaka</td>
              </tr>
              <tr>
                <td>FUK</td>
                <td>Fukuoka Airport</td>
                <td>Fukuoka</td>
              </tr>
              <tr>
                <td>HND</td>
                <td>Tokyo Haneda International Airport</td>
                <td>Tokyo</td>
              </tr>
            </tbody>
          </table>

          <p>5 Major Ports In Japan </p>

          <ul>
            <li>Port of Tokyo. </li>

            <li>Kobe Port. </li>

            <li>Port of Nagoya. </li>

            <li>Port of Osaka. </li>

            <li>Port of Yokohama.</li>
          </ul>

          <h4 className="mt-5">Typical Food</h4>
          <p>
            Japan's most internationally famous dish, sushi is also
            internationally misunderstood. Most people are mistaken in believing
            that sushi is simply raw fish. Rather, good sushi is a vigilant
            combination of vinegared rice, raw fish and vegetables and comes in
            many different forms.
          </p>

          <h4 className="mt-5">Employment</h4>
          <p>
            The Japanese employment system has been primarily guided by three
            principles: lifelong employment, seniority wage, and single
            enterprise union (a company union that is not industry-wide or a
            craft union). Employment in Japan has consistently been over 90%
            during the period, indicating the steady job situation in Japan.
            During the 2019-2020 academic year, the rate reached 97.8%, which
            means nearly all the students were able to get a job upon
            graduation. In 2021, the employment rate in Japan reached
            approximately 60.4 percent, slightly up from about 60.3 percent in
            the previous year. Japan's unemployment rate has been comparatively
            low compared to other major industrial and emerging countries.
          </p>
        </article>
      </section>

      <div className="container">
        <h4 className="my-5">Submarine Cables</h4>
        <a
          href="https://www.submarinecablemap.com/country/japan"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/zKL0Av0.png"
            alt="submarine cables"
            width="100%"
          />
        </a>
        <ol className="mt-4">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <li>APCN-2 </li>

              <li>Apricot</li>

              <li>Asia Direct Cable (ADC) </li>

              <li>Asia Pacific Gateway (APG) </li>

              <li>Asia Submarine-cable Express (ASE)/Cahaya </li>

              <li>Mlasya </li>

              <li>Australian-Japan Cable</li>

              <li>EAC-C2C </li>

              <li>FASTER </li>

              <li>Flag Europe-Asia (FEA) </li>

              <li>Flag North Asia Loop/Reach North Asia Loop </li>

              <li>Guan Okinama Kyushu Incheon (GOKI) </li>

              <li>Hokkaido-Sakhalin Cable System (HSCS) </li>

              <li>Japan-Guam-Australia North (JGA-N)</li>

              <li>Japan-U.S. Cable Network (JUS) </li>
            </div>
            <div className="col-md-12 col-lg-6">
              <li>Jupiter </li>

              <li>Korea-Japan Cable Network (KJCN) </li>

              <li>Miyazaki-Okinawa Cable (MOC) </li>

              <li>New Cross Pacifin (NCP) Cable System </li>

              <li>Okinawa Cellular Cable </li>

              <li>Pacific Crossing-1 (PC-1) </li>

              <li>Russia-Japan Cable Network (RJCN) </li>

              <li>SeaMeWe-3 </li>

              <li>Southeast Asia-Japan Cable 2 (SJC2) </li>

              <li>Southeast Asia-Japan Cable (SJC) </li>

              <li>Tata TGN-Pacific </li>

              <li>Topaz </li>

              <li>Trans-Pacific Express (TPE) Cable System </li>

              <li>Unity/EAC-PAcific </li>
            </div>
          </div>
        </ol>

        <h4 className="my-4">
          ACCESS TO LANDLINE AND MOBILE PHONE*
        </h4>
        <p>
          Japan invented the mobile internet, and Japan's telecommunications
          markets are among the world's largest, most profitable, most advanced
          and also most liberalized. Japan created the first successful mobile
          internet service, starting on February 22, 1999.
        </p>

        <p>
          Average annual mobile revenue growth was lower (1%) than mobile
          service subscriptions growth (4.7%) during the period 2014-2019,
          highlighting the structural challenges faced by mobile operators.
          Declining voice & SMS revenue only partially offset by wireless data
          monetisation is putting pressure on ARPU, compounded by bundling
          discounts to stem churn.
        </p>

        <p>
          The Japanese telecommunications market is the third-largest market in
          the world by revenue with three large fixed and mobile network
          operators that invested heavily in towers and fibre infrastructure
          over the past two decades despite an overall market underpinned by low
          economic and population growth
        </p>

        <h4 className="my-4">ACCESS TO INTERNET*</h4>
        <p>
          The number of internet users in Japan amounted to around 116
          million as of 2020. This figure was projected to increase to almost
          116.4 million internet users by 2026. Japan is among the countries
          with the highest number of internet users in the world.
        </p>

        <p>
          After over 20 years of investments in fibre infrastructure, Japan
          boasts one of the highest FTTH penetration in the world as well as one
          of the most advanced, consumer broadband products offering speeds up
          to 10Gbps. However, most people use 100Mbps packages which are mostly
          bundled with mobile services by the three dominant players, NTT, KDDI
          and Softbank.
        </p>

        <p>
          Fixed broadband penetration is forecasted to grow modestly as Japan's
          investments in full-fibre networks are now reaching maturity with
          affordable packages and increased broadband household penetration
          growing towards saturation.
        </p>

        <h4 className="my-4">Our Capabilities</h4>

        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connectivity</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins text-center"
          align="justify"
        >
          With our Global Business Solutions team, we can provide a
          <mark>Commercial Offer in 2 - 3 working days.</mark> Our Global Ops
          team is capable to deliver services in major cities (Benin and Lagos)
          country in 3 to 4 weeks and rest of the cities in 5 - 6 weeks.
        </p>

        <p className="text-center">
          For more information, please
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://www.japan.travel/en/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.data.jma.go.jp/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://finance.yahoo.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Japan;
