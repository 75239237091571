import React, { useEffect } from 'react';
import DinamicLink from '../DinamicLink/DinamicLink';
import './TestimonialCarousel.scss';

export default function TestimonialCarousel() {
  useEffect(() => {
    const btnc = document.getElementsByClassName('hero__indicator-btn');
    const slide = document.getElementById('slide');

    // console.log(btnc, slide);

    btnc[0].onclick = () => {
      slide.style.transform = 'translateX(0px)';
      for (let i = 0; i < 3; i++) {
        console.log(btnc);
        btnc[i].classList.remove('hero__indicator-active');
      }
      btnc[0].classList.add('hero__indicator-active');
    };
    btnc[1].onclick = () => {
      slide.style.transform = 'translateX(-850px)';
      for (let i = 0; i < 3; i++) {
        btnc[i].classList.remove('hero__indicator-active');
      }
      btnc[1].classList.add('hero__indicator-active');
    };
    btnc[2].onclick = () => {
      slide.style.transform = 'translateX(-1700px)';
      for (let i = 0; i < 3; i++) {
        btnc[i].classList.remove('hero__indicator-active');
      }
      btnc[2].classList.add('hero__indicator-active');
    };
  }, []);

  return (
    <div>
      <div className="hero">
        <h2 className="hero__title center-header mx-auto text-center">
          Testimonials
        </h2>

        <div className="hero__container">
          <div className="hero__indicator">
            <span className="hero__indicator-btn hero__indicator-active"></span>
            <span className="hero__indicator-btn"></span>
            <span className="hero__indicator-btn"></span>
          </div>
          <div className="hero__testimonial">
            <div className="hero__slide-row" id="slide">
              <div className="hero__slide-col">
                <div className="hero__user-text">
                  <p className="hero__user-text-p" align="justify">
                    <strong className="blue">BRILLIANT</strong> work by inte-QT. <br />
                    <br />
                    It was seamlessly managed by inte-QT, right
                    from Quotation to timely delivery well within event
                    schedule. Overall it's delighted experience.
                  </p>
                  <h5 className="hero__user-text-h">
                    Associate Director, Media Services <br />
                    World’s Leading Tier-1 Carrier <br />
                    London, UK 
                  </h5>
                  <p className="hero__user-text-p">
                    World Championship Motor Racing
                  </p>
                  <div className="text-end">
                    <DinamicLink url="/testimonials" name="Read more" />
                  </div>
                </div>
                <div className="hero__user">
                  <img
                    src="https://i.imgur.com/6Q7TYsB.jpg"
                    alt="1"
                    className="hero__user-img"
                  />
                </div>
              </div>

              {/* <div className="hero__slide-col">
                <div className="hero__user-text">
                  <p className="hero__user-text-p" align="justify">
                    Deeper customer engagement has always been part of culture
                    at inte-QT. The customer do understand and
                    appreciate the support they receive from us and this helps
                    them to share their requirements and concerns in an open
                    manner.
                  </p>
                  <h5 className="hero__user-text-h">Person Name</h5>
                  <p className="hero__user-text-p">
                    Connecting a BFSI company in far East of the World, Fiji
                  </p>
                  <div className="text-end">
                    <DinamicLink url="/testimonials" name="Read more" />
                  </div>
                </div>
                <div className="hero__user">
                  <img
                    src="https://i.imgur.com/KwMDwhu.jpg"
                    alt="1"
                    className="hero__user-img"
                  />
                </div>
              </div>

              <div className="hero__slide-col">
                <div className="hero__user-text">
                  <p className="hero__user-text-p" align="justify">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Soluta officia quam commodi in iure vero quis laborum
                    aliquam praesentium. In repellendus ipsam, vitae at hic
                    officiis id temporibus vel deleniti.
                  </p>
                  <h5 className="hero__user-text-h">Jose Cabeza</h5>
                  <p className="hero__user-text-p">Company name</p>
                  <div className="text-end">
                    <DinamicLink url="/testimonials" name="Read more" />
                  </div>
                </div>
                <div className="hero__user">
                  <img
                    src="https://picsum.photos/400/500"
                    alt="1"
                    className="hero__user-img"
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
