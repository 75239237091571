import React from 'react';
import { MapContainer, TileLayer} from 'react-leaflet';
import Markers from '../Markers/Markers';
import {offices} from '../../data/offices.json';

export default function ContactMap() {
  const position = [38.763382853415536, 35.48314308291784] //Turkey, it's in the middle of the markers 
  return (
    <div>
      <MapContainer
        className="map"
        center={position}
        zoom="2"
        scrollWheelZoom={false}
      >
        
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Markers places={offices} />
      </MapContainer>
    </div>
  )
}
