import React from 'react';
import { Link } from 'react-router-dom';
import './OurServices.scss';

export default function OurServices() {
  return (
    <div>
      <div className="ourServices">
        <div className="row text-center">
          <div className="col-lg-4 col-md-8 col-10 p-3 m-auto">
            <div className="text-center ourServices__container p-3 ourServices__1">
              <Link
                to="/services"
                className="text-decoration-none ourServices-anchor"
              >
                <i className="fas fa-server fa-3x my-4 ourServices__color"></i>
                <h4>Dedicated Lines (DL)</h4>
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-8 col-10 p-3 m-auto">
            <div className="text-center ourServices__container p-3 ourServices__2">
              <Link
                to="/services"
                className="text-decoration-none ourServices-anchor"
              >
                <i className="fas fa-network-wired fa-3x my-4 ourServices__color"></i>
                <h4>Business Broadband (BB)</h4>
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-8 col-10 p-3 m-auto">
            <div className="text-center p-3 ourServices__container ourServices__3">
              <Link
                to="/services"
                className="text-decoration-none ourServices-anchor"
              >
                <i className="fas fa-wifi fa-3x my-4 ourServices__color"></i>
                <h4>Wireless Connects (XC)</h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
