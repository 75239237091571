import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './SearchBox.scss';

export default function SearchBox({ placeholder, data }) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("")

  const handleFilterData = (e) => {
    e.preventDefault();
    const searchWord = e.target.value.toLowerCase();
    setWordEntered(searchWord);
    const newFilter = data.filter((item) => {
      return item.name.toLowerCase().includes(searchWord);
    });

    if (searchWord === '') {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  }

  return (
    <div className="text-center searchBox flex flex-jc-c text-center">
      <div className="searchBox__Inputs">
        <input
          className="form-control searchBox__Inputs-input"
          placeholder={placeholder}
          value={wordEntered}
          onChange={handleFilterData}
        />
        <div className="searchBox__Icon">
          {filteredData.length === 0 ? (
            <i className="fas fa-search"></i>
          ) : (
            <i
              className="fas fa-times searchbox__clearBtn"
              onClick={clearInput}
            ></i>
          )}
        </div>
      </div>
      {filteredData.length !== 0 && (
        <div className="searchBox__dataResult">
          {filteredData.slice(0, 15).map((item, key) => {
            return (
              <Link to={`/coverage/`+ item.name.toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/\s/g, '')} className="searchBox__dataResult-item" key={key}>
                {item.name} - {item.code}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
}
