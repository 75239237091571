import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function Nigeria() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Nigeria - inte-QT</title>
        <meta
          name="title"
          content="Internet in Nigeria - inte-QT"
        />
        <meta name="description" content="Our Coverage in Nigeria" />
        <link
          rel="canonical"
          href="https://www.inte-qt.com/coverage/nigeria"
        />
      </Helmet>
      <img
        src="https://i.imgur.com/sAoqVlf.jpg"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - The current population of Nigeria is{' '}
              <strong>215,203,368*</strong>
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - Nigeria is bordered to
              the north by Niger, to the east by Chad and Cameroon, to the south
              by the Gulf of Guinea of the Atlantic Ocean, and to the west by
              Benin. Nigeria is not only large in area—larger than the U.S.
              state of Texas—but also Africa's most populous country.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - Hausa was an official language
              of the northern states from 1951 to 1967. It is the most widely
              spoken language, although English is the official language of
              Nigeria. In addition to English, Hausa, Yoruba, Igbo, Fula, and
              English Creole are widely spoken. Many of the languages exist in
              written form.
            </li>
            <li>
              <strong>CURRENCY</strong> - The Nigerian naira (NGN) is the
              official currency of the Federal Republic of Nigeria.
            </li>
            <li>
              <strong>INDUSTRIES</strong> - The services sector - consisting of
              telecommunications, financial, and other services - is the largest
              sector in Nigeria, although it saw a slight decline, accounting
              for about 54.39% of GDP in Q4 2020.The largest industries which
              drive the country's economy and account for the bulk of its annual
              GDP are its petroleum, tourism, agriculture, and mining
              industries.
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Lagos</li>
                <li>Kano</li>
                <li>Ibadan</li>
              </ul>
            </li>
            <li>
              <p>
                <strong>WEATHER</strong> - Nigeria has a tropical climate with
                variable rainy and dry seasons, depending on location. It is hot
                and wet most of the year in the southeast but dry in the
                southwest and farther inland. A savanna climate, with marked wet
                and dry seasons, prevails in the north and west, while a steppe
                climate with little precipitation is found in the far north.
              </p>
            </li>
            <li>
              <p>
                <strong>NATIONAL SPORT</strong> - The Nigeria national football
                team competes regularly for international titles and many
                Nigerian footballers compete in Europe, particularly in England.
                Nigeria at the FIFA World Cup.
              </p>
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/4c843cf20657ffc830940f3e719c2b02/raw/19227509dd01ee8376f7d22f780db0e1f5ba2771/nigeria-flag.svg"
              alt="Nigeria flag"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            Nigeria, officially named the Federal Republic of Nigeria, is a
            diverse West African country. Nicknamed the "Giant of Africa"
            Nigeria is the seventh-most populous country in the world home to
            more than 200 million people.
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4034910.7009228664!2d6.4334882342906266!3d9.032489545414377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0baf7da48d0d%3A0x99a8fe4168c50bc8!2sNigeria!5e0!3m2!1sen!2ses!4v1652175620046!5m2!1sen!2ses"
            width="95%"
            title="Nigeria-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <h4 className="mt-4">Tourism*</h4>
          <p>
            Tourism in Nigeria centers largely on events, due to the country's
            ample amount of ethnic groups, but also includes rain forests,
            savannah, waterfalls, and other natural attractions.
          </p>

          <p>
            Nigeria tourism statistics for 2019 was 1,471,000,000.00, a 25.59%
            decline from 2018. Nigeria tourism statistics for 2018 was
            1,977,000,000.00, a 24.4% decline from 2017. Nigeria tourism
            statistics for 2017 was 2,615,000,000.00, a 140.35% increase from
            2016.
          </p>

          <h4 className="mt-4">Main Ports / Airports</h4>

          <p>
            Nigeria has 32 airports, 26 of which are operated by the Federal
            Airports Authority of Nigeria (FAAN), and five of which are
            functional international airports.
          </p>

          <table className="table">
            <thead>
              <tr>
                <th>IATA</th>
                <th>Name</th>
                <th>City</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>LOS</td>
                <td>Murtala Muhammed International Airport</td>
                <td>Lagos</td>
              </tr>
              <tr>
                <td>ABV</td>
                <td>Nnamdi Azikiwe International Airport</td>
                <td>Abuja</td>
              </tr>
              <tr>
                <td>PHC</td>
                <td>Port Harcourt International Airport</td>
                <td>Port Harcourt</td>
              </tr>
              <tr>
                <td>KAN</td>
                <td>Mallam Aminu International Airport</td>
                <td>Kano</td>
              </tr>
            </tbody>
          </table>

          <p>
            According to the Nigerian Ports Authority (NPA), the country has six
            seaports: Apapa and Tin Can in Lagos, the Onne and Port-Harcourt
            ports in Rivers State, the Warri Port, and the Calabar Port. But, by
            many accounts, only the Lagos ports are operating anywhere near full
            capacit.
          </p>
          <ul>
            <li>LAGOS PORT COMPLEX. </li>

            <li>TIN CAN ISLAND PORT. </li>

            <li>CALABAR PORT. </li>

            <li>RIVERS PORT COMPLEX. </li>

            <li>DELTA PORT. </li>

            <li>ONNE PORT. </li>
          </ul>

          <h4 className="mt-5">Typical Food</h4>
          <p>
            Jollof rice is Nigeria's national dish that is believed to be the
            origin of the popular Cajun dish known as jambalaya. This is one of
            the most popular foods in Nigeria and is eaten in every part of the
            country. You may wonder what it is that can be so highly rated and
            it will shock you to know that it is simply rice prepared with
            tomato, onion, pepper, and some other spices.
          </p>

          <h4 className="mt-5">Employment</h4>
          <p>
            Employment Rate in Nigeria averaged 83.68* percent from 2014 until
            2020, reaching an all time high of 93.60* percent in the fourth
            quarter of 2014 and a record low of 66.70 percent in the fourth
            quarter of 2020.
          </p>
        </article>
      </section>

      <div className="container">
        <h4 className="my-5">Submarine Cables</h4>
        <a
          href="https://www.submarinecablemap.com/country/nigeria"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/gFJBGJc.png"
            alt="submarine cables"
            width="100%"
          />
        </a>
        <ol className="mt-4">
          <li>2Africa</li>
          <li>Nigeria Cameroon Submarine Cable System </li>
          <li>Equiano</li>
          <li>West Africa Cable System </li>
          <li>MainOne </li>
          <li>Africa Coast to Europe </li>
          <li>Glo-1 </li>
          <li>SAT-3/WESC </li>
        </ol>

        <h4 className="my-4">
          ACCESS TO LANDLINE AND MOBILE PHONE ACCESS TO INTERNET*
        </h4>
        <p>
          In 2021, the number of mobile internet users in Nigeria amounted to
          over 101.7 million.
        </p>
        <p>
          Nigeria Telecom Market has witnessed strong growth in recent years and
          is expected to have continued growth over the forecast period to 2025.
          Telecom sector is further expected to have strong growth over the
          forecast period with rising adoption of Internet of Things (IoT) in
          the sector that connect with wired and wireless broadband.
        </p>

        <p>
          The major part of the market growth in Nigeria is attained by premium
          connectivity and content services in the country.
        </p>
        <p>
          Based on the service type, Nigeria telecom market has experienced
          major growth from Value-added services. The growth in Value-added
          services is mainly due to increasing subscribers in the emerging
          economies and growing demand from network operators to offer efficient
          mobile services. Based on the transmission type, still, wireline is
          accounted for the major market across the world and is expected to
          have continued growth over the forecast period, the growth in wireline
          is mainly due to increased demand for wired communication services.
        </p>

        <p>
          The global telecom market has seen strong growth in recent years and
          is expected to have strong growth over the forecast period to 2025.
          The industry has witnessed continued advancements in technology over
          the past few decades and evolved with a wider range of product
          offerings through the 19th century. Growing 5G technology will offer
          differentiation between wireless and wireline networks and is intended
          to converge the communication modes into a unified end-to-end system
          in public, residential, and enterprise spaces.
        </p>

        <p>
          Traditional global telecom services markets have changed drastically
          with the development in mobile solutions outperforms the fixed-line,
          as well as the internet, and is replacing fixed voice connection in
          the market. Increasing penetration of Internet services through
          wireless mode and the copper-laid network is expected to replace the
          fixed voice traffic in the coming years. Small & Medium-sized
          Businesses are expected to have strong growth over the forecast period
          with the increasing reach of technology to every corner of the global
          nations.
        </p>

        <h4 className="my-4">WIRELESS NETWORKS*</h4>
        <p>
          The growth would be further supported by Regulatory authorities, which
          play a major role in adopting a 5G network in the country.
        </p>

        <p>
          5G network plays a key role in enabling autonomous cars, and customer
          services including physical infrastructure to contractual agreements.
          However, 5G network adoption may face hurdles from public authorities
          and organizations as 5G network might lead to technical challenges and
          pose a threat to public safety and health. The key technical challenge
          for a challenge for adopting 5G's bands tends to have limited range
          and lower penetration rate, which hampered the pilot tests. Besides
          technical issues, adapting issues such as overheating of some 5G
          devices while using may lead to the slower adoption of 5G network
          services.
        </p>

        <h4 className="my-4">Our Capabilities</h4>

        <p>
          The country has 12 Internet providers with owning infrastructure who
          give connectivity access throughout Nigeria. As Global access enabler,
          inte-QT partners with local Internet providers and today,
          we have 8 infrastructure owning partners in Nigeria.{' '}
        </p>

        <p>
          inte-QT can offer you different internet solutions in
          Nigeria, from DIA to Business Broadband, and also 4G/5G,LTE. We bring
          traffic shaping to our customers to ensure high performance for every
          circuit.
        </p>

        <p>
          Currently we are delivering in Benin, Agbara and Lagos with guaranteed
          24/7 monitoring from our NSOC team.
        </p>

        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connectivity</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins text-center"
          align="justify"
        >
          With our Global Business Solutions team, we can provide a
          <mark>Commercial Offer in 2 - 3 working days.</mark> Our Global Ops
          team is capable to deliver services in major cities (Benin and Lagos)
          country in 3 to 4 weeks and rest of the cities in 5 - 6 weeks.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://www.worldometers.info/world-population/nigeria-population/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.britannica.com/place/Nigeria"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://www.globalmonitor.us/product/nigeria-telecommunication-market-report"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Nigeria;
