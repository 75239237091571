import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function Australia() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Australia - inte-QT</title>
        <meta
          name="title"
          content="Internet in Australia - inte-QT"
        />
        <meta name="description" content="Our Coverage in Australia" />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/australia" />
      </Helmet>
      <img
        src="https://i.imgur.com/dNhGDhm.jpg"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - The current population of Australia
              is 25,996,756*, 2022
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - Australia surrounding
              countries include Indonesia, East Timor and Papua New Guinea to
              the north; the Solomon Islands, Vanuatu and New Caledonia to the
              north-east; and New Zealand to the south-east
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - Australia legally has no
              official language. However, English is by far the most commonly
              spoken and has been entrenched as the de facto national language
              since European settlement. The second most spoken language in
              Australia is Mandarin, followed by Arabic, Cantonese, Vietnamese,
              Italian, and Tamil.
            </li>
            <li>
              <strong>CURRENCY</strong> - Australia's national currency is
              Australian dollars (AUD)
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Australia's key industries include:
              <br />
              The Financial Industry. <br />
              The Business Consulting Industry. <br />
              The Metals and Mining Industry. <br />
              The Energy and Utilities Industry. <br />
              The Healthcare Industry. <br />
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Sydney , New South Wales</li>
                <li>Melbourne , Victoria</li>
                <li>Brisbane , Queensland</li>
              </ul>
            </li>
            <li>
              <p>
                <strong>WEATHER</strong> - Due to the huge size of the country,
                Australia has serveral different climate zones. The northern
                section of Australia has a more tropical influenced climate, hot
                and humid in the summer, and quite warm and dry in the winter,
                while the southern parts are cooler with mild summers and cool,
                sometimes rainy winters.
              </p>
            </li>
            <li>
              <p>
                <strong>EMPLOYMENT</strong> - Employment Rate in Australia
                averaged 59.15* percent from 1978 until 2021, reaching an all
                time high of 63* percent in June of 2021.
              </p>
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/9d58173fe8a40d444c01fd4c29629bc5/raw/3126e4bf57d4119296a4d3265abe836ec9723321/australia-flag.svg"
              alt="Australia flag"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            Australia, officially the Commonwealth of Australia, is a sovereign
            transcontinental country comprising the mainland of the Australian
            continent, the island of Tasmania, and numerous smaller islands.
            Australia is world famous for{' '}
            <strong>
              its natural wonders and wide open spaces, its beaches, deserts,
              "the bush", and "the Outback"
            </strong>
            . Australia is one of the world's most highly urbanised countries;
            it is well known for the attractions of its large cities such as
            Sydney, Melbourne, Brisbane, and Perth.
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29623989.513552938!2d115.23038361409664!3d-24.992990915591953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2b2bfd076787c5df%3A0x538267a1955b1352!2sAustralia!5e0!3m2!1sen!2ses!4v1648035019830!5m2!1sen!2ses"
            width="95%"
            title="australia-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <h4 className="mt-4">Tourism</h4>
          <p>
            Total international and domestic tourism losses since the start of
            the pandemic in March 2020 reached $128.3* billion. International
            tourism saw losses of $62.5* billion for March 2020 to September
            2021. This was due to international border closures caused by the
            COVID-19 pandemic.
          </p>
          <h4 className="mt-4">Main Ports / Airports</h4>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">IATA</th>
                <th scope="col">Name</th>
                <th scope="col">City</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>SYD</td>
                <td>Sydney Kingsford Smith International Airport</td>
                <td>Sydney</td>
              </tr>
              <tr>
                <td>BNE</td>
                <td>Brisbane International Airport</td>
                <td>Brisbane</td>
              </tr>
              <tr>
                <td>MEL</td>
                <td>Melbourne International Airport</td>
                <td>Melbourne</td>
              </tr>
            </tbody>
          </table>
          <h6 className="m-3">Ports: </h6>
          <ol>
            <li>
              <strong>Port of Brisbane</strong> <br />
              The Port of Brisbane is the largest seaport in the state of
              Queensland.
            </li>
            <li>
              <strong>Port of Sydney</strong> <br />
              Situated on Botany Bay, the Port of Sydney is actually called the
              Port of Botany—and it's the second biggest container port in the
              country.
            </li>
            <li>
              <strong>Port of Fremantle</strong> <br />
              The Port of Fremantle is arguably the busiest port in Western
              Australia, as it handles not only more than 35 million tons of
              cargo each year, but also maritime cruises and a navy 24/7.
            </li>
          </ol>

          <h4 className="mt-5">National Sport</h4>
          <p>
            Cricket <br />
            Cricket is considered to be Australia's national sport.
          </p>
          <h4 className="mt-5">Typical Food</h4>
          <p>
            Chicken Parmigiana. This classic Aussie chicken dish - with roots in
            Italian-American cooking - is a staple offering on pretty much every
            pub menu in the country.
            <br />
            Roast lamb - Roast lamb has been declared Australia's national dish
            in a major poll.
          </p>
        </article>
      </section>

      <div className="container">
        <h4 className="my-5">Submarine Cables</h4>
        <a
          href="https://www.submarinecablemap.com/country/australia"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/5Sc9hBb.png"
            alt="submarine cables"
            width="100%"
          />
        </a>
        <ol className="mt-4">
          <div className="row">
            <div className="col-md-12 col-lg-6 mb-3">
              <li>Asia COnnect Cable 1 (ACC1)</li>

              <li>Australia-Japan Cable (AJC)</li>

              <li>Australia - Singapore Cable (ASC)</li>

              <li>Bass Strait-1</li>

              <li>Basslink</li>

              <li>Coral Sea Cable System (CS2)</li>

              <li>Darwin-Jakarta-Singapore Cable (DJSC)</li>

              <li>Gondwana-1</li>

              <li>Hawaiki</li>

              <li>Hawaiki Nui</li>

              <li>INDIGO-Central</li>
            </div>
            <div className="col-md-12 col-lg-6 mb-3">
              <li>Ingido-West</li>

              <li>Japan.Guam_australia South (JGA-S)</li>

              <li>North West Cable System</li>

              <li>Oman Australia Cable (OAC)</li>

              <li>Pipe Pacific Cable-1 (PPC-1)</li>

              <li>SeaMeWe-3</li>

              <li>Southern Cross Cable Network (SCCN)</li>

              <li>Southern Cross Next</li>

              <li>Tasman Global Acess (TGA) Cable</li>

              <li>Telstra Endeavour</li>
            </div>
          </div>
        </ol>

        <h4 className="my-4">
          ACCESS TO LANDLINE AND MOBILE PHONE ACCESS TO INTERNET*
        </h4>
        <p>
          Globally, the telecommunications sector is proving to be a core and
          essential infrastructure service to national economies, with data
          infrastructure becoming critical in a connected world and will likely
          increasingly attract a new class of investors such as large
          infrastructure funds. The Australian telecommunications industry is
          expected to remain steady thanks to the defensiveness nature of the
          industry, amid the political uncertainties and an uncertain economic
          outlook due to the COVID-19 pandemic.
        </p>

        <p>
          Australia has one of the most competitive and mature
          telecommunications markets in the world. Forecasts suggest that mobile
          subscriptions will continue to grow in the 2021-26 period while fixed
          broadband subscribers also expected to grow at a slightly lower
          average rate over the same period. The ratio of the telecommunications
          sector revenue to GDP is declining from a peak in 2000 to an all-time
          low in 2020.
        </p>

        <p>
          As a nation whose population is spread so widely, having a robust
          telecommunications network in Australia is crucial. Decades of
          continual improvement have resulted in extensive fixed-line and mobile
          infrastructure.
        </p>

        <p>
          Currently, Australia has deployed a reasonable amount of 5G and is in
          the process of auctioning off spectrum to provide for more.
        </p>

        <p>
          Nearly all Australian adults (99%) have access to the internet. 91% of
          Australian adults have a home internet connection, and three-quarters
          of these have an NBN connection. In the first half of 2021, almost all
          (98%) older Australians aged 55+ used the internet, up from 76% in
          2019, prior to COVID-19 lockdowns.
        </p>

        <h4 className="my-4">Internet Providers*</h4>
        <p>
          As the country has a diversity of submarine cables, it has very well
          internet connectivity.
        </p>
        <p>
          Speed test statistics showed that the average download speed is
          58.83Mbps with an average upload speed of 21.44Mbps.
        </p>

        <p>
          The country has 12 Internet providers owning infrastructure who
          provide the access throughout the country. inte-QT as a
          global access enabler partner with the local providers and today, we
          have 8 infrastructure owning partners in Australia.
        </p>

        <p>
          Currently we are delivering in main cities; Sydney, Melbourne and
          Adelaide with guaranteed 24/7 monitoring from our NSOC team.
        </p>

        <h4 className="my-4">Our Capabilities</h4>
        <p>
          inte-QT can offer you all different solutions in
          Australia, from DIA to BB, 4G/5G. We also can provide LTE solutions.
          We have given service to, medical equipment manufacturing companies,
          some of the biggest air companies at Perth Aiport, and top engineering
          consultancy leaders in the country
        </p>

        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connectivity</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>SLA</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins text-center"
          align="justify"
        >
          With our Global Business Solutions team, we can provide a{' '}
          <mark>Commercial Offer in 2-4 working days</mark> and our Global Ops
          team is capable to deliver services in major cities (Sydney and
          Melbourne) in 3 to 4 weeks and rest of the cities 5 to 6 weeks.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://www.worldometers.info/world-population/australia-population/#:~:text=The%20current%20population%20of%20Australia,of%20the%20total%20world%20population"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.australiance.com/key-industries-in-australia/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://www.ceicdata.com/en/indicator/australia/visitor-arrivals"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
          <li>
            <a
              href="https://www.weatheronline.co.uk/reports/climate/Australia.htm"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 4
            </a>
          </li>
          <li>
            <a
              href="https://glginsights.com/articles/understanding-australian-telecoms-and-towers/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 5
            </a>
          </li>
          <li>
            <a
              href="https://www.globenewswire.com/news-release/2021/09/20/2299530/28124/en/Australia-Telecoms-Industry-2021-2026-Mobile-Subscribers-Continue-to-Grow.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 6
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Australia;
