import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function SouthAfrica() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in SouthAfrica - inte-QT</title>
        <meta
          name="title"
          content="Internet in SouthAfrica - inte-QT"
        />
        <meta name="description" content="Our Coverage in South Africa" />
        <link
          rel="canonical"
          href="https://www.inte-qt.com/coverage/southafrica"
        />
      </Helmet>
      <img
        src="https://i.imgur.com/MmiBXYe.png"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION*</strong> - The current population of South
              Africa is <strong>60,689,034</strong>*
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - The southernmost
              country of the African continent, South Africa is bordered by
              Namibia, Botswana, Zimbabwe and Eswatini. South Africa entirely
              surrounds Lesotho in the east. A large plateau dominates the
              centre of the country, with rolling hills falling to plains and
              the coast.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - South Africa's Constitution
              recognises 11 official languages: Sepedi (also known as Sesotho sa
              Leboa), Sesotho, Setswana, siSwati, Tshivenda, Xitsonga,
              Afrikaans, English, isiNdebele, isiXhosa and isiZulu. For
              centuries South Africa's official languages were European - Dutch,
              English, Afrikaans.
            </li>
            <li>
              <strong>CURRENCY</strong> - The South African rand (ZAR) is the
              national currency of the country of South Africa. The rand was
              introduced in February 1961 and mostly held a steady peg against
              the US dollar until the end of apartheid.
            </li>
            <li>
              <strong>INDUSTRIES</strong> - South Africa's economy has
              traditionally been rooted in the primary sectors - the result of a
              wealth of mineral resources and favourable agricultural
              conditions.
              <p>
                However, the economy has been characterised by a structural
                shift in output over the past four decades.
              </p>
              <p>
                Among the key sectors that contribute to the gross domestic
                product and keep the economic engine running are manufacturing,
                retail, financial services, communications, mining, agriculture
                and tourism.
              </p>
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Cape Town, Western Cape</li>
                <li>Durban, KwaZuluNatal</li>
                <li>Johannesburg, Gauteng</li>
              </ul>
            </li>
            <li>
              <p>
                <strong>WEATHER</strong> - The climate in South Africa ranges
                from desert and semi-desert in the northwest to subtropical on
                the eastern coast. Rainfall generally occurs during summer (mid-
                to late September through April), although in the southwest
                around Cape Town, rainfall occurs in winter (April through early
                September).
              </p>
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/77b8770daf293354ec76a1828d7593c8/raw/36398790b645eb20bad1e7ffaeceb4c8ce77b5d9/south-africa-162425.svg"
              alt="SouthAfrica flag"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            South Africa, the southernmost country on the African continent,
            renowned for its varied topography, great natural beauty, and
            cultural diversity, all of which have made the country a favoured
            destination for travelers since the legal ending of apartheid
            (Afrikaans: “apartness,” or racial separation) in 1994.
          </p>

          <p>
            The three different capitals of South Africa are divided into
            administrative, legislative and judicial government bases.
          </p>

          <p>
            The administrative capital of South Africa is Pretoria. South
            Africa's legislative capital is Cape Town. The judicial capital of
            South Africa is Bloemfontein. Its name means “blooming flowers” or
            “fountain of flowers” in Afrikaans.
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14572569.139717594!2d16.7128331300851!3d-26.911233455215918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1c34a689d9ee1251%3A0xe85d630c1fa4e8a0!2sSouth%20Africa!5e0!3m2!1sen!2ses!4v1654167931743!5m2!1sen!2ses"
            width="95%"
            title="SouthAfrica-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <h4 className="mt-4">Tourism*</h4>
          <p>
            Tourism in South Africa. South Africa recorded a total of 4 million
            tourists in 2020, ranking 45th in the world in absolute terms.
          </p>

          <p>
            Tourism remains a key driver of South Africa's national economy and
            contributes to job creation. The tourism industry is a major
            contributor to the South African economy and employment of citizens.
            The sector contributes about 9% to the country's gross domestic
            product (GDP).
          </p>

          <h4 className="mt-4">Main Ports / Airports</h4>

          <table className="table">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Airport</th>
                <th>IATA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Oliver Tambo International Airport</td>
                <td>JNB</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Cape Town International Airport</td>
                <td>CPT</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Port Elizabeth International Airport</td>
                <td>PLZ</td>
              </tr>
            </tbody>
          </table>

          <h6>5 Major Ports In SouthAfrica</h6>

          <ul>
            <li>Port of Durban (Durban, South Africa) </li>

            <li>Port of Richards Bay (The Richards Bay town, South Africa)</li>

            <li>Port Elizabeth (Gqeberha, South Africa) </li>

            <li>Port of Cape Town (Cape Town, South Africa) </li>

            <li>Port of Saldanha (Saldanha Bay, South Africa) </li>
          </ul>

          <h4 className="mt-5">National Sport</h4>

          <p>
            Cricket, the team has been strong since its reinstatement, and has
            at several times held the number-one positions in international
            rankings. South Africa is also one of the most successful teams in
            ODI cricket, winning more than 60* per cent of their matches.
          </p>

          <h4 className="mt-5">Typical Food</h4>
          <p>
            Bobotie. Another dish thought to have been brought to South Africa
            by Asian settlers, bobotie is now the national dish of the country
            and cooked in many homes and restaurants. Minced meat is simmered
            with spices, usually curry powder, herbs and dried fruit, then
            topped with a mixture of egg and milk and baked until set.
          </p>

          <h4 className="mt-5">Employment*</h4>
          <p>
            Employment Rate in South Africa averaged 42.64 percent from 2000
            until 2021, reaching an all time high of 46.17 percent in the fourth
            quarter of 2008 and a record low of 35.93 percent in the third
            quarter of 2021.
          </p>
        </article>
      </section>

      <div className="container">
        <h4 className="my-5">Submarine Cables</h4>
        <a
          href="https://www.submarinecablemap.com/country/south-africa"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/HwGShAp.png"
            alt="submarine cables"
            width="100%"
          />
        </a>
        <h4 className="mt-5">Cable Names</h4>
        <ol className="mt-4">
          <div className="row">
            <div className="col-md-6">
              <li>2Africa </li>

              <li>Africa Coast to Eurolie (ACE) </li>

              <li>Eastern Africa SUbmarie System (EASSy) </li>

              <li>Equiano </li>

              <li>Melthingliot Indianoceanic Submarine System </li>

              <li>(METISS) </li>
            </div>
            <div className="col-md-6">
              <li>SAFE </li>

              <li>SAT-3/WASC </li>

              <li>SAFE </li>

              <li>SAT-3/WASC </li>

              <li>SEACOM/Tata TGN-Eurasia </li>

              <li>West Africa Cable System (WACS) </li>
            </div>
          </div>
        </ol>

        <h4 className="my-4">ACCESS TO LANDLINE AND MOBILE PHONE*</h4>
        <p>
          South Africa, as the second-largest country in Africa, has a
          population of 58.56 million and a GDP in 2020 of $301 billion, ranking
          39th in the world. South Africa and Nigeria often act as benchmarks
          for other African countries.
        </p>

        <p>
          The mobile sector has developed strongly in recent years, partly due
          to the poor availability and level of service of fixed-line networks,
          which meant that many people had no alternative to mobile networks for
          voice and data services.
        </p>

        <p>
          Telecommunication service requirements in SA have grown rapidly in the
          past five years. Recently, data requirements have grown especially
          quickly because of new technologies (like Artificial Intelligence and
          the Internet of Things) and the COVID-19 pandemic. The two key markets
          within the SA Telecom market are “to Business” (2B) and “to Consumers”
          (2C). Each of the two key markets has two main telecom
          infrastructures: wireless & fixed network. Together, they currently
          serve about 99M wireless subscribers in South Africa, meaning that
          every subscriber has on average 1.7 SIM cards excluding fixed network
          services subscribers (such as xDSL and fiber). due to data services
          becoming more common and popular in SA, communication service demands
          are still growing
        </p>

        <h4 className="my-4">ACCESS TO INTERNET*</h4>
        <p>
          South Africa's telecom sector boasts one of the most advanced
          infrastructures on the continent. There has been considerable
          investment from big companies and municipal providers as well as from
          mobile network operators. The focus in recent years has been on
          backhaul capacity and on fibre and LTE networks to extend and improve
          internet service connectivity.
        </p>

        <h4 className="my-4">WIRELESS NETWORKS </h4>

        <p>
          The demand for wireless data services is growing in both the 2B and 2C
          markets. In Europe and East Asia, Artificial Intelligence (AI),
          Internet of Things (IoT) and other new services are placing new
          demands on the big data pipeline .AI will be a revolutionary feat of
          computer science, and is likely to become a core component of all
          modern software over the coming years. The strengthening link between
          AI and Big Data will produce a huge demand on data services when it
          becomes more developed in the Ecosystem/Industry. This trend will be
          applicable in South Africa as SMEs and big companies continue to
          grow.
        </p>

        <p>
          There are 15 Fiber network providers in SouthAfrica. inte-QT 
          has onboarded 14 infrastructure partners. We deliver in the major cities
          as well as the remote areas of this region. It is one of our strongest regions
          in African continent. Our commercial strategy ensures to meet any target given
          by our customers.
        </p>

        <h4 className="my-4">Our Capabilities</h4>

        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connectivity</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins text-center"
          align="justify"
        >
          With our Global Business Solutions team, we can provide a
          <mark>Commercial Offer in 2 - 3 working days.</mark> Our Global Ops
          team is capable to deliver services in major cities (Capetown, Johannesburg and Durban)
          country in 3 to 4 weeks and rest of the cities in 5 - 6 weeks.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://www.britannica.com/place/South-Africa"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.nathab.com/know-before-you-go/african-safaris/southern-africa/weather-climate/south-africa/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://www.scirp.org/journal/paperinformation.aspx?paperid=113594"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default SouthAfrica;
