import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import Footer from './components/Footer/Footer';
import Cases from './pages/Cases/Cases';
import Services from './pages/Services/Services';
import Contact from './pages/Contact/Contact';
import Coverage from './pages/Coverage/Coverage';
import Nsoc from './pages/Nsoc/Nsoc';
import ContactSupport from './pages//Contact/ContactSupport/ContactSupport';
import ContactGeneral from './pages/Contact/ContactGeneral/ContactGeneral';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Legal from './pages/Legal/Legal';
import NotFound from './pages/NotFound/NotFound';
import PartnerCenter from './pages/PartnerCenter/PartnerCenter';
import DedicatedLines from './pages/Services/DedicatedLines/DedicatedLines';
import WirelessConnect from './pages/Services/WirelessConnect/WirelessConnect';
import BusinessBoradband from './pages/Services/BusinessBroadband/BusinessBoradband';
import Events from './pages/Events/Events';
import ContactSales from './pages/Contact/ContactSales/ContactSales';
import Blog from './pages/Blog/Blog';
import ContactPartners from './pages/Contact/ContactPartners/ContactPartners';
import CaseFijiv2 from './pages/Cases/CaseFijiv2/CaseFijiv2';
import CaseColgate from './pages/Cases/CaseColgate/CaseColgate';
import CaseLebanon from './pages/Cases/CaseLebanon/CaseLebanon';
import TestimonialMx from './pages/Testimonials/TestimonialMx/TestimonialMx';
import ContactSalesQuote from './pages/Contact/ContactSalesQuote/ContactSalesQuote';
import Sitemap from './pages/Sitemap/Sitemap';
import Testimonials from './pages/Testimonials/Testimonials';
import Blog1 from './pages/Blog/Blog1/Blog1';
import Blog2 from './pages/Blog/Blog2/Blog2';
import Blog3 from './pages/Blog/Blog3/Blog3';
import Blog4 from './pages/Blog/Blog4/Blog4';
import Blog5 from './pages/Blog/Blog5/Blog5';
import AboutUs from './pages/AboutUs/AboutUs';
import Blog6 from './pages/Blog/Blog6/Blog6';
import DominicanRepublic from './pages/Coverage/DominicanRepublic/DominicanRepublic';
import Austria from './pages/Coverage/Austria/Austria';
import Indonesia from './pages/Coverage/Indonesia/Indonesia';
import Ethiopia from './pages/Coverage/Ethiopia/Ethiopia';
import Qatar from './pages/Coverage/Qatar/Qatar';
import Bulgaria from './pages/Coverage/Bulgaria/Bulgaria';
import Mexico from './pages/Coverage/Mexico/Mexico';
import Blog7 from './pages/Blog/Blog7/Blog7';
import Blog8 from './pages/Blog/Blog8/Blog8';
import Turkey from './pages/Coverage/Turkey/Turkey';
import Vietnam from './pages/Coverage/Vietnam/Vietnam';
import Australia from './pages/Coverage/Australia/Australia';
import MainCorporatePresentation from './pages/MainCorporatePresentation/MainCorporatePresentation';
import VideoPresentationAirports from './pages/VideoPresentationAirports/VideoPresentationAirports';
import Mozambique from './pages/Coverage/Mozambique/Mozambique';
import CoverageNotFound from './pages/CoverageNotFound/CoverageNotFound';
import Bermuda from './pages/Coverage/Bermuda/Bermuda';
import Nigeria from './pages/Coverage/Nigeria/Nigeria';
import Japan from './pages/Coverage/Japan/Japan';
import Chile from './pages/Coverage/Chile/Chile';
import SouthAfrica from './pages/Coverage/SouthAfrica/SouthAfrica'
// import Test from './pages/Test/Test';

function App() {
  return (
    <div>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/coverage" component={Coverage} />

          <Route path="/coverage/nigeria" component={Nigeria} />
          <Route path="/coverage/austria" component={Austria} />
          <Route path="/coverage/australia" component={Australia} />
          <Route path="/coverage/bulgaria" component={Bulgaria} />
          <Route path="/coverage/dominicanrepublic" component={DominicanRepublic} />
          <Route path="/coverage/ethiopia" component={Ethiopia} />
          <Route path="/coverage/indonesia" component={Indonesia} />
          <Route path="/coverage/mexico" component={Mexico} />
          <Route path="/coverage/mozambique" component={Mozambique} />
          <Route path="/coverage/qatar" component={Qatar} />
          <Route path="/coverage/turkey" component={Turkey} />
          <Route path="/coverage/vietnam" component={Vietnam} />
          <Route path="/coverage/bermuda" component={Bermuda} />
          <Route path="/coverage/japan" component={Japan} />
          <Route path="/coverage/chile" component={Chile} />
          <Route path="/coverage/southafrica" component={SouthAfrica} />

          {/* Countries without specific page - START */}
          <Route path="/coverage/afghanistan" component={CoverageNotFound} />
          <Route path="/coverage/albania" component={CoverageNotFound} />
          <Route path="/coverage/algeria" component={CoverageNotFound} />
          <Route path="/coverage/americansamoa" component={CoverageNotFound} />
          <Route path="/coverage/andorra" component={CoverageNotFound} />
          <Route path="/coverage/angola" component={CoverageNotFound} />
          <Route path="/coverage/anguilla" component={CoverageNotFound} />
          <Route path="/coverage/antarctica" component={CoverageNotFound} />
          <Route path="/coverage/antiguaandbarbuda" component={CoverageNotFound} />
          <Route path="/coverage/argentina" component={CoverageNotFound} />
          <Route path="/coverage/armenia" component={CoverageNotFound} />
          <Route path="/coverage/aruba" component={CoverageNotFound} />
          <Route path="/coverage/azerbaijan" component={CoverageNotFound} />
          <Route path="/coverage/bahamas" component={CoverageNotFound} />
          <Route path="/coverage/bahrain" component={CoverageNotFound} />
          <Route path="/coverage/bangladesh" component={CoverageNotFound} />
          <Route path="/coverage/barbados" component={CoverageNotFound} />
          <Route path="/coverage/belarus" component={CoverageNotFound} />
          <Route path="/coverage/belgium" component={CoverageNotFound} />
          <Route path="/coverage/belize" component={CoverageNotFound} />
          <Route path="/coverage/benin" component={CoverageNotFound} />
          <Route path="/coverage/bermuda" component={CoverageNotFound} />
          <Route path="/coverage/bhutan" component={CoverageNotFound} />
          <Route path="/coverage/bolivia" component={CoverageNotFound} />
          <Route path="/coverage/bonaire" component={CoverageNotFound} />
          <Route path="/coverage/bosniaandherzegovina" component={CoverageNotFound} />
          <Route path="/coverage/botswana" component={CoverageNotFound} />
          <Route path="/coverage/bouvetisland" component={CoverageNotFound} />
          <Route path="/coverage/brazil" component={CoverageNotFound} />
          <Route path="/coverage/britishindianoceanterritory" component={CoverageNotFound} />
          <Route path="/coverage/bruneidarussalam" component={CoverageNotFound} />
          <Route path="/coverage/burkinafaso" component={CoverageNotFound} />
          <Route path="/coverage/burundi" component={CoverageNotFound} />
          <Route path="/coverage/cambodia" component={CoverageNotFound} />
          <Route path="/coverage/cameroon" component={CoverageNotFound} />
          <Route path="/coverage/canada" component={CoverageNotFound} />
          <Route path="/coverage/capeverde" component={CoverageNotFound} />
          <Route path="/coverage/caymanislands" component={CoverageNotFound} />
          <Route path="/coverage/centralafricanrepublic" component={CoverageNotFound} />
          <Route path="/coverage/chad" component={CoverageNotFound} />
          <Route path="/coverage/china" component={CoverageNotFound} />
          <Route path="/coverage/christmasisland" component={CoverageNotFound} />
          <Route path="/coverage/cocoskeelingislands" component={CoverageNotFound} />
          <Route path="/coverage/colombia" component={CoverageNotFound} />
          <Route path="/coverage/comoros" component={CoverageNotFound} />
          <Route path="/coverage/congorepublicofbrazzaville" component={CoverageNotFound} />
          <Route path="/coverage/congodemocraticrepublicofthekinshasa" component={CoverageNotFound} />
          <Route path="/coverage/cookislands" component={CoverageNotFound} />
          <Route path="/coverage/costarica" component={CoverageNotFound} />
          <Route path="/coverage/cotedivoire" component={CoverageNotFound} />
          <Route path="/coverage/croatia" component={CoverageNotFound} />
          <Route path="/coverage/cuba" component={CoverageNotFound} />
          <Route path="/coverage/curacao" component={CoverageNotFound} />
          <Route path="/coverage/cyprus" component={CoverageNotFound} />
          <Route path="/coverage/czechrepublic" component={CoverageNotFound} />
          <Route path="/coverage/denmark" component={CoverageNotFound} />
          <Route path="/coverage/djibouti" component={CoverageNotFound} />
          <Route path="/coverage/dominica" component={CoverageNotFound} />
          <Route path="/coverage/ecuador" component={CoverageNotFound} />
          <Route path="/coverage/egypt" component={CoverageNotFound} />
          <Route path="/coverage/elsalvador" component={CoverageNotFound} />
          <Route path="/coverage/equatorialguinea" component={CoverageNotFound} />
          <Route path="/coverage/eritrea" component={CoverageNotFound} />
          <Route path="/coverage/estonia" component={CoverageNotFound} />
          <Route path="/coverage/falklandislandsmalvinas" component={CoverageNotFound} />
          <Route path="/coverage/faroeislands" component={CoverageNotFound} />
          <Route path="/coverage/fiji" component={CoverageNotFound} />
          <Route path="/coverage/finland" component={CoverageNotFound} />
          <Route path="/coverage/france" component={CoverageNotFound} />
          <Route path="/coverage/frenchguiana" component={CoverageNotFound} />
          <Route path="/coverage/frenchpolynesia" component={CoverageNotFound} />
          <Route path="/coverage/frenchsouthernterritories" component={CoverageNotFound} />
          <Route path="/coverage/gabon" component={CoverageNotFound} />
          <Route path="/coverage/gambia" component={CoverageNotFound} />
          <Route path="/coverage/georgia" component={CoverageNotFound} />
          <Route path="/coverage/germany" component={CoverageNotFound} />
          <Route path="/coverage/ghana" component={CoverageNotFound} />
          <Route path="/coverage/gibraltar" component={CoverageNotFound} />
          <Route path="/coverage/greece" component={CoverageNotFound} />
          <Route path="/coverage/greenland" component={CoverageNotFound} />
          <Route path="/coverage/grenada" component={CoverageNotFound} />
          <Route path="/coverage/guadeloupe" component={CoverageNotFound} />
          <Route path="/coverage/guam" component={CoverageNotFound} />
          <Route path="/coverage/guatemala" component={CoverageNotFound} />
          <Route path="/coverage/guernsey" component={CoverageNotFound} />
          <Route path="/coverage/guinea" component={CoverageNotFound} />
          <Route path="/coverage/guineabissau" component={CoverageNotFound} />
          <Route path="/coverage/guyana" component={CoverageNotFound} />
          <Route path="/coverage/haiti" component={CoverageNotFound} />
          <Route path="/coverage/heardislandandmcdonaldislands" component={CoverageNotFound} />
          <Route path="/coverage/holyseevaticancitystate" component={CoverageNotFound} />
          <Route path="/coverage/honduras" component={CoverageNotFound} />
          <Route path="/coverage/hongkong" component={CoverageNotFound} />
          <Route path="/coverage/hurgary" component={CoverageNotFound} />
          <Route path="/coverage/iceland" component={CoverageNotFound} />
          <Route path="/coverage/india" component={CoverageNotFound} />
          <Route path="/coverage/iranislamicrepublicof" component={CoverageNotFound} />
          <Route path="/coverage/iraq" component={CoverageNotFound} />
          <Route path="/coverage/ireland" component={CoverageNotFound} />
          <Route path="/coverage/isleofman" component={CoverageNotFound} />
          <Route path="/coverage/israel" component={CoverageNotFound} />
          <Route path="/coverage/italy" component={CoverageNotFound} />
          <Route path="/coverage/ivorycoast" component={CoverageNotFound} />
          <Route path="/coverage/jamaica" component={CoverageNotFound} />
          <Route path="/coverage/jersey" component={CoverageNotFound} />
          <Route path="/coverage/jordan" component={CoverageNotFound} />
          <Route path="/coverage/kazakhstan" component={CoverageNotFound} />
          <Route path="/coverage/kenya" component={CoverageNotFound} />
          <Route path="/coverage/kiribati" component={CoverageNotFound} />
          <Route path="/coverage/kosovo" component={CoverageNotFound} />
          <Route path="/coverage/kuwait" component={CoverageNotFound} />
          <Route path="/coverage/kyrgyzstan" component={CoverageNotFound} />
          <Route path="/coverage/laospeoplesdemocraticrepublic" component={CoverageNotFound} />
          <Route path="/coverage/latvia" component={CoverageNotFound} />
          <Route path="/coverage/lebanon" component={CoverageNotFound} />
          <Route path="/coverage/lesotho" component={CoverageNotFound} />
          <Route path="/coverage/liberia" component={CoverageNotFound} />
          <Route path="/coverage/libya" component={CoverageNotFound} />
          <Route path="/coverage/liechtenstein" component={CoverageNotFound} />
          <Route path="/coverage/lithuania" component={CoverageNotFound} />
          <Route path="/coverage/luxembourg" component={CoverageNotFound} />
          <Route path="/coverage/macau" component={CoverageNotFound} />
          <Route path="/coverage/macedonia" component={CoverageNotFound} />
          <Route path="/coverage/madagascar" component={CoverageNotFound} />
          <Route path="/coverage/malawi" component={CoverageNotFound} />
          <Route path="/coverage/malaysia" component={CoverageNotFound} />
          <Route path="/coverage/maldives" component={CoverageNotFound} />
          <Route path="/coverage/mali" component={CoverageNotFound} />
          <Route path="/coverage/malta" component={CoverageNotFound} />
          <Route path="/coverage/marshallislands" component={CoverageNotFound} />
          <Route path="/coverage/martinique" component={CoverageNotFound} />
          <Route path="/coverage/mauritania" component={CoverageNotFound} />
          <Route path="/coverage/mauritius" component={CoverageNotFound} />
          <Route path="/coverage/mayotte" component={CoverageNotFound} />
          <Route path="/coverage/micronesiafederatedstatesof" component={CoverageNotFound} />
          <Route path="/coverage/moldovarepublicof" component={CoverageNotFound} />
          <Route path="/coverage/monaco" component={CoverageNotFound} />
          <Route path="/coverage/mongolia" component={CoverageNotFound} />
          <Route path="/coverage/montenegro" component={CoverageNotFound} />
          <Route path="/coverage/montserrat" component={CoverageNotFound} />
          <Route path="/coverage/morocco" component={CoverageNotFound} />
          <Route path="/coverage/myanmar" component={CoverageNotFound} />
          <Route path="/coverage/namibia" component={CoverageNotFound} />
          <Route path="/coverage/nauru" component={CoverageNotFound} />
          <Route path="/coverage/nepal" component={CoverageNotFound} />
          <Route path="/coverage/netherlands" component={CoverageNotFound} />
          <Route path="/coverage/netherlandsantilles" component={CoverageNotFound} />
          <Route path="/coverage/newcaledonia" component={CoverageNotFound} />
          <Route path="/coverage/newzealand" component={CoverageNotFound} />
          <Route path="/coverage/nicaragua" component={CoverageNotFound} />
          <Route path="/coverage/niger" component={CoverageNotFound} />
          <Route path="/coverage/niue" component={CoverageNotFound} />
          <Route path="/coverage/norfolkisland" component={CoverageNotFound} />
          <Route path="/coverage/northernmarianaislands" component={CoverageNotFound} />
          <Route path="/coverage/norway" component={CoverageNotFound} />
          <Route path="/coverage/oman" component={CoverageNotFound} />
          <Route path="/coverage/pakistan" component={CoverageNotFound} />
          <Route path="/coverage/palau" component={CoverageNotFound} />
          <Route path="/coverage/palestine" component={CoverageNotFound} />
          <Route path="/coverage/panama" component={CoverageNotFound} />
          <Route path="/coverage/papuanewguinea" component={CoverageNotFound} />
          <Route path="/coverage/paraguay" component={CoverageNotFound} />
          <Route path="/coverage/peru" component={CoverageNotFound} />
          <Route path="/coverage/philippines" component={CoverageNotFound} />
          <Route path="/coverage/pitcairn" component={CoverageNotFound} />
          <Route path="/coverage/poland" component={CoverageNotFound} />
          <Route path="/coverage/portugal" component={CoverageNotFound} />
          <Route path="/coverage/puertorico" component={CoverageNotFound} />
          <Route path="/coverage/reunionisland" component={CoverageNotFound} />
          <Route path="/coverage/romania" component={CoverageNotFound} />
          <Route path="/coverage/russia" component={CoverageNotFound} />
          <Route path="/coverage/rwanda" component={CoverageNotFound} />
          <Route path="/coverage/sainthelena" component={CoverageNotFound} />
          <Route path="/coverage/saintkitts" component={CoverageNotFound} />
          <Route path="/coverage/saintlucia" component={CoverageNotFound} />
          <Route path="/coverage/saintmartin" component={CoverageNotFound} />
          <Route path="/coverage/saintpierreandmiquelon" component={CoverageNotFound} />
          <Route path="/coverage/saintvincent" component={CoverageNotFound} />
          <Route path="/coverage/samoa" component={CoverageNotFound} />
          <Route path="/coverage/sanmarino" component={CoverageNotFound} />
          <Route path="/coverage/saotomeandprincipe" component={CoverageNotFound} />
          <Route path="/coverage/saudiarabia" component={CoverageNotFound} />
          <Route path="/coverage/senegal" component={CoverageNotFound} />
          <Route path="/coverage/serbia" component={CoverageNotFound} />
          <Route path="/coverage/seychelles" component={CoverageNotFound} />
          <Route path="/coverage/sierraleone" component={CoverageNotFound} />
          <Route path="/coverage/singapore" component={CoverageNotFound} />
          <Route path="/coverage/slovakia" component={CoverageNotFound} />
          <Route path="/coverage/slovenia" component={CoverageNotFound} />
          <Route path="/coverage/solomonislands" component={CoverageNotFound} />
          <Route path="/coverage/somalia" component={CoverageNotFound} />
          <Route path="/coverage/somaliland" component={CoverageNotFound} />
          <Route path="/coverage/southkorea" component={CoverageNotFound} />
          <Route path="/coverage/southsudan" component={CoverageNotFound} />
          <Route path="/coverage/spain" component={CoverageNotFound} />
          <Route path="/coverage/srilanka" component={CoverageNotFound} />
          <Route path="/coverage/sudan" component={CoverageNotFound} />
          <Route path="/coverage/suriname" component={CoverageNotFound} />
          <Route path="/coverage/svalbardandjanmayen" component={CoverageNotFound} />
          <Route path="/coverage/swaziland" component={CoverageNotFound} />
          <Route path="/coverage/sweden" component={CoverageNotFound} />
          <Route path="/coverage/switzerland" component={CoverageNotFound} />
          <Route path="/coverage/syria" component={CoverageNotFound} />
          <Route path="/coverage/taiwanrepublicofchina" component={CoverageNotFound} />
          <Route path="/coverage/tajikistan" component={CoverageNotFound} />
          <Route path="/coverage/tanzaniaunitedrepublicof" component={CoverageNotFound} />
          <Route path="/coverage/thailand" component={CoverageNotFound} />
          <Route path="/coverage/timorleste" component={CoverageNotFound} />
          <Route path="/coverage/togo" component={CoverageNotFound} />
          <Route path="/coverage/tokelau" component={CoverageNotFound} />
          <Route path="/coverage/tonga" component={CoverageNotFound} />
          <Route path="/coverage/trinidadandtobago" component={CoverageNotFound} />
          <Route path="/coverage/tunisia" component={CoverageNotFound} />
          <Route path="/coverage/turkmenistan" component={CoverageNotFound} />
          <Route path="/coverage/turksandcaicosislands" component={CoverageNotFound} />
          <Route path="/coverage/tuvalu" component={CoverageNotFound} />
          <Route path="/coverage/uganda" component={CoverageNotFound} />
          <Route path="/coverage/ukraine" component={CoverageNotFound} />
          <Route path="/coverage/unitedarabemirates" component={CoverageNotFound} />
          <Route path="/coverage/unitedkingdom" component={CoverageNotFound} />
          <Route path="/coverage/unitedstatesofamerica" component={CoverageNotFound} />
          <Route path="/coverage/unitedstatesminoroutlyingislands" component={CoverageNotFound} />
          <Route path="/coverage/uruguay" component={CoverageNotFound} />
          <Route path="/coverage/uzbekistan" component={CoverageNotFound} />
          <Route path="/coverage/vanuatu" component={CoverageNotFound} />
          <Route path="/coverage/venezuela" component={CoverageNotFound} />
          <Route path="/coverage/virginislandsbritish" component={CoverageNotFound} />
          <Route path="/coverage/virginislandsus" component={CoverageNotFound} />
          <Route path="/coverage/wallisandfutuna" component={CoverageNotFound} />
          <Route path="/coverage/westernsahara" component={CoverageNotFound} />
          <Route path="/coverage/yemen" component={CoverageNotFound} />
          <Route path="/coverage/zambia" component={CoverageNotFound} />
          <Route path="/coverage/zimbabwe" component={CoverageNotFound} />
          {/* Countries without specific page - END */}

          <Route exact path="/services" component={Services} />
          <Route path="/services/dedicated-lines" component={DedicatedLines} />
          <Route path="/services/wireless-connect" component={WirelessConnect} />
          <Route path="/services/business-broadband" component={BusinessBoradband} />
          <Route path="/partner-center" component={PartnerCenter} />
          <Route exact path="/cases" component={Cases} />
          <Route path="/cases/fiji" component={CaseFijiv2} />
          <Route path="/cases/lebanon" component={CaseLebanon} />
          <Route path="/cases/argentina" component={CaseColgate} />
          <Route exact path="/contact" component={Contact} />
          <Route path="/contact/support" component={ContactSupport} />
          <Route path="/contact/general" component={ContactGeneral} />
          <Route path="/contact/sales" component={ContactSales} />
          <Route path="/contact/partners" component={ContactPartners} />
          <Route path="/contact/quote" component={ContactSalesQuote} />
          <Route path="/global-nsoc" component={Nsoc} />
          <Route path="/policy-gtc-aup" component={Legal} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/blog" component={Blog} />
          <Route path="/blog/1" component={Blog1} />
          <Route path="/blog/2" component={Blog2} />
          <Route path="/blog/3" component={Blog3} />
          <Route path="/blog/4" component={Blog4} />
          <Route path="/blog/5" component={Blog5} />
          <Route path="/blog/6" component={Blog6} />
          <Route path="/blog/7" component={Blog7} />
          <Route path="/blog/8" component={Blog8} />
          <Route path="/events" component={Events} />
          <Route path="/cust280222-0125/airports" component={VideoPresentationAirports} />
          <Route path="/main-corporate-presentation-2018-ZazM&GmY&8DSLQsq" component={MainCorporatePresentation} />
          <Route path="/sitemap" component={Sitemap} />
          <Route path="/about-us" component={AboutUs} />
          <Route exact path="/testimonials" component={Testimonials} />
          <Route path="/testimonials/mx" component={TestimonialMx} />
          {/* <Route path="/test" component={Test} /> */}
          <Route path="*/*" component={NotFound} />
        </Switch>
         <Footer />
      </Router>
    </div>
  );
}

export default App;
