import React ,{ useState }from 'react';
import continentInfo from '../../data/continentInfo.json';
import './CountryDetailetInformation.scss';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AnimatedProgressProvider from '../AnimatedCircle/AnimatedProgressProvider';
import { easeQuadInOut } from 'd3-ease';
import GetInTouch from '../GetInTouch/GetInTouch';

export default function CountryDetailetInformation() {
  const [continent, setContinent] = useState(3)

  return (
    <div className="countryDetail flex-jc-c container">
    <div className='text-center mt-5 pt-5'>
      <h2 className='center-header'>Continents</h2>
        <div
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio1"
            autoComplete="off"
            onClick={() => setContinent(0)}
          />
          <label className="btn btn-outline-primary m-2" htmlFor="btnradio1">
            Americas
          </label>
  
          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio2"
            autoComplete="off"
            onClick={() => setContinent(1)}
          />
          <label className="btn btn-outline-primary m-2" htmlFor="btnradio2">
            Latam
          </label>
  
          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio3"
            autoComplete="off"
            onClick={() => setContinent(2)} 
          />
          <label className="btn btn-outline-primary m-2" htmlFor="btnradio3">
            Africa
          </label>

          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio4"
            autoComplete="off"
            onClick={() => setContinent(3)} 
          />
          <label className="btn btn-outline-primary m-2" htmlFor="btnradio4">
            Europe
          </label>

          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio5"
            autoComplete="off"
            onClick={() => setContinent(4)} 
          />
          <label className="btn btn-outline-primary m-2" htmlFor="btnradio5">
            MENA Region
          </label>

          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio6"
            autoComplete="off"
            onClick={() => setContinent(5)} 
          />
          <label className="btn btn-outline-primary m-2" htmlFor="btnradio6">
            APAC Region
          </label>
        </div>
    </div>
      <div className="countryDetail__box">
        <div className="text-center">
          <img
            className="countryDetail__img"
            src={continentInfo.continents[continent].img}
            alt="continent"
          />
        </div>
        <div className="countryDetail__padding" align="justify">
          <div className="b_read">
            <h2 className="mx-auto text-center center-header m-5">
              {continentInfo.continents[continent].name}
            </h2>
            <p>{continentInfo.continents[continent].information}</p>
            <ul>
              <li className="my-3">
                {continentInfo.continents[continent].options[1]}
              </li>
              <li className="my-3">
                {continentInfo.continents[continent].options[2]}
              </li>
              <li className="my-3">
                {continentInfo.continents[continent].options[3]}
              </li>
              <li className="my-3">
                {continentInfo.continents[continent].options[4]}
              </li>
            </ul>
            <p>
              <strong>Area:</strong> {continentInfo.continents[continent].area}
            </p>
            <p>
              <strong>Population:</strong>{' '}
              {continentInfo.continents[continent].population}
            </p>
            <p>
              <strong>GDP (nominal):</strong>{' '}
              {continentInfo.continents[continent].gdpNominal}
            </p>
            <p>
              <strong>GDP per capita:</strong>{' '}
              {continentInfo.continents[continent].gdpPerCapita}
            </p>
            <p>
              <strong>Language:</strong>{' '}
              {continentInfo.continents[continent].languages}
            </p>
            <p>
              <strong>Time Zone:</strong>{' '}
              {continentInfo.continents[continent].timeZone}
            </p>
          </div>
            <h2 className="mx-auto text-center center-header m-5">
              inte-QT Presence in {' '}
              {continentInfo.continents[continent].name} (Global Coverage, Local
              Expertise)
            </h2>
            <p className='text-center'>
              inte-QT, The trusted industry leader that provides
              internet solutions in {continentInfo.continents[continent].name}{' '}
              through Dedicated lines, Business Broadband and Wireless connects.
            </p>
          <div className="row text-center countryDetail__animatedCircle">
            <div className="col-lg-4 col-md-12">
              <div
                className="countryDetail__spacing"
                style={{ width: 200, height: 200 }}
              >
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={190}
                  duration={4}
                  easingFunction={easeQuadInOut}
                  repeat
                >
                  {(value) => {
                    const roundedValue = Math.round(value);
                    return (
                      <>
                        <CircularProgressbar
                          value={roundedValue}
                          text={`${roundedValue}+`}
                          maxValue={183}
                          /* This is important to include, because if you're fully managing the
                          animation yourself, you'll want to disable the CSS animation. */
                          styles={buildStyles({
                            pathTransition: 'none',
                            // Colors
                            pathColor: `rgba(${roundedValue / 6}, ${
                              value / 2
                            }, ${roundedValue}, ${roundedValue / 200})`,
                            textColor: '#1f55ba',
                            trailColor: '#f1f5fd',
                          })}
                        />
                        <h6 className="m-2">Countries with Local Partners</h6>
                      </>
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <div
                className="countryDetail__spacing"
                style={{ width: 200, height: 200 }}
              >
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={1153}
                  duration={4}
                  easingFunction={easeQuadInOut}
                  repeat
                >
                  {(value) => {
                    const roundedValue = Math.round(value);
                    return (
                      <>
                        <CircularProgressbar
                          value={value}
                          text={`${roundedValue}+`}
                          maxValue={1153}
                          /* This is important to include, because if you're fully managing the
                animation yourself, you'll want to disable the CSS animation. */
                          styles={buildStyles({
                            pathTransition: 'none',
                            // Colors
                            pathColor: `rgba(${roundedValue / 36}, ${
                              value / 13
                            }, ${roundedValue / 6}, ${roundedValue / 200})`,
                            textColor: '#1f55ba',
                            trailColor: '#f1f5fd',
                          })}
                        />
                        <h6 className="m-2">Trusted Partners Globally</h6>
                      </>
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <div
                className="countryDetail__spacing"
                style={{ width: 200, height: 200 }}
              >
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={73}
                  duration={4}
                  easingFunction={easeQuadInOut}
                  repeat
                >
                  {(value) => {
                    const roundedValue = Math.round(value);
                    return (
                      <>
                        <CircularProgressbar
                          value={value}
                          text={`${roundedValue}+`}
                          maxValue={73}
                          /* This is important to include, because if you're fully managing the
                animation yourself, you'll want to disable the CSS animation. */
                          styles={buildStyles({
                            pathTransition: 'none',
                            // Colors
                            pathColor: `rgba(${roundedValue / 2.3}, ${
                              value / 1.2
                            }, ${roundedValue * 2.56}, ${roundedValue / 100})`,
                            textColor: '#1f55ba',
                            trailColor: '#f1f5fd',
                          })}
                        />
                        <h6 className="m-2">
                          Countries with Multiple Live Services
                        </h6>
                      </>
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
            </div>
          </div>
          <div className="b_read">
            <h4 className="pt-5 pb-3">
              Solutions and Services in{' '}
              {continentInfo.continents[continent].name} include:
            </h4>
            <p>
              <strong>Country Wise Presence:</strong>{' '}
              {continentInfo.continents[continent].countryWisePresence}
            </p>
            <p>
              <strong>Product Solution Offered:</strong>{' '}
              {continentInfo.continents[continent].productSolutionOffered}
            </p>
            <p>
              <strong>Multiple Access Options:</strong>{' '}
              {continentInfo.continents[continent].multipleAccessOptions}
            </p>
            <p>
              <strong>Network Diversity:</strong>{' '}
              {continentInfo.continents[continent].networkDiversity}
            </p>
            <p>
              <strong>Minimal Latency:</strong>{' '}
              {continentInfo.continents[continent].minimalLatency}
            </p>
            <p>
              <strong>SLA:</strong> {continentInfo.continents[continent].sla}
            </p>
            <p>
              <strong>Service Delivery Lead Time:</strong>{' '}
              {continentInfo.continents[continent].serviceDeliveryLeadTime}
            </p>
            <p>
              <strong>Bandwidth:</strong>{' '}
              {continentInfo.continents[continent].bandwidth}
            </p>
            <p>
              <strong>IPV6 Ready Network:</strong>{' '}
              {continentInfo.continents[continent].ipv6ReadyNetwork}
            </p>
            <p>
              <strong>Service Assurance:</strong>{' '}
              {continentInfo.continents[continent].serviceAssurance}
            </p>
            <p>
              <strong>Guaranteed Domestic Transit Quality:</strong>{' '}
              {
                continentInfo.continents[continent]
                  .guaranteedDomesticTransitQuality
              }
            </p>
            <p>
              <strong>Premium Performance:</strong>{' '}
              {continentInfo.continents[continent].premiumPerformance}
            </p>
            <p>
              <strong>IP Range:</strong>{' '}
              {continentInfo.continents[continent].ipRange}
            </p>
            <h4 className="mt-5 pt-5">Reference by:</h4>
            <ul>
              <li>
                <a href="https://www.worldbank.org/en/home">
                  World Bank Website
                </a>
              </li>
              <li>
                <a href="https://bangkok.unesco.org/">Unesco Bangkok</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <GetInTouch />
    </div>
  );
}
