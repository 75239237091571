import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function Mexico() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Mexico - inte-QT</title>
        <meta
          name="title"
          content="Internet in Mexico - inte-QT"
        />
        <meta name="description" content="Our Coverage in Mexico" />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/mexico" />
      </Helmet>
      <img
        src="https://i.imgur.com/TGBlJ3B.jpg"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - Mexico's population in 2021 is
              128.97m*
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - Mexico is a country in
              southern North America, with extensive coastlines on the Gulf of
              Mexico and the Pacific Ocean. In the north there is a 3,169 km
              (1,969 mi) long border separating Mexico and the United States.
              Mexico is also bordered by Guatemala, and Belize and it shares
              maritime borders with Cuba and Honduras.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - Official language is Spanish.
              While most Mexicans speak Spanish, there are 68 recognized
              languages in the country, mostly indigenous. Four of these are
              language isolates, or languages that don't relate to any other
              language.
            </li>
            <li>
              <strong>CURRENCY</strong> - Mexican Peso
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Mexico has the ninth-largest economy
              in the world. Its main industries are food and beverages, tobacco,
              chemicals, iron and steel, petroleum, clothing, motor vehicles,
              consumer durables, and tourism. Mexico is a country rich in
              resources and potential. Among its notable industries are
              technology, oil production, brewing, automotive, mineral
              exploitation, tourism and manufacturing
            </li>
            <li>
              <strong>EMPLOYMENT*</strong> - Employment Rate in Mexico averaged
              95.77 percent from 2005 until 2021.
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Mexico City: Service, Manufacturing, Finance.</li>
                <li>Guadalajara: Manufacturing, Information Technology (IT)</li>
                <li>Monterrey: Steel, Software, Brewing.</li>
              </ul>
            </li>
            <li>
              <strong>WEATHER</strong> - The climate in Mexico is tropical with
              a rainy and dry season and little temperature fluctuation from
              season to season. The temperature in all areas of Mexico typically
              ranges between 50°F and 90°F throughout the year. Average annual
              humidity is around 70%.
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/c822dd5d11d972e7061ff8d2811d3793/raw/9233cd0f322a2af1c184d7eb04ff1dc17ca2af6f/mexico-flag.svg"
              alt="Mexico flag"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            Mexico's real name is the United States of Mexico (Estados Unidos
            Mexicanos). The country is divided into 31 states, plus the Federal
            District.
          </p>
          <p>
            Mexico is an enormous, diverse and vibrant country, situated at the
            southernmost part of North America, bordering the United States in
            the north and Central America (Guatemala and Belize) in the south.
            Mexico has 34 UNESCO World Heritage Sites.
          </p>
          <p>
            Mexico City is the oldest city in North America. Many believe the
            oldest city in North America is St. Augustine, but Mexico City was
            actually founded a little over 40 years before it in 1521. The city
            was built by the Spaniards on the ancient ruins of the Aztec city of
            Tenochtitlán.{' '}
          </p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15010240.95529012!2d-111.64713032811977!3d23.29363083213213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x84043a3b88685353%3A0xed64b4be6b099811!2sMexico!5e0!3m2!1sen!2ses!4v1646057351278!5m2!1sen!2ses"
            width="95%"
            title="mexico-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <h4 className="mt-4">Tourism</h4>
          <p>
            In 2020, Mexico welcomed 23.3 million international tourists, around
            half of the inbound tourism volume recorded a year earlier.{' '}
            <strong>
              US citizens Are The Largest Immigration Group in Mexico.
            </strong>
          </p>
          <h4 className="mt-4">Main Ports / Airports</h4>
          <table className="table table-striped text-center">
            <thead>
              <tr>
                <th scope="col">Rank</th>
                <th scope="col">Airport</th>
                <th scope="col">IATA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Mexico City International Airport</td>
                <td>MEX</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Cancun International Airport</td>
                <td>CUN</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Guadalajara International Airport</td>
                <td>GDL</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Monterrey International Airport</td>
                <td>MTY</td>
              </tr>
            </tbody>
          </table>
          <p>
            Mexico has 5 main ports:
            <strong>
              Port of Manzanillo, Port of Lazaro Cárdenas, Port of Veracruz,
              Port of Altamira, Port of Ensenada.
            </strong>
          </p>
          <h4 className="mt-5">National Sport</h4>
          <p>
            Charrería, the national sport of Mexico and a forerunner of the
            North American rodeo, originated among the Spanish conquistadors in
            the sixteenth century. Charros, or Mexican horsemen, adapted the
            equestrian contests of the Spaniards to produce a uniquely Mexican
            sport.
          </p>
          <h4 className="mt-5">Typical Food</h4>
          <p>
            From tacos, chilaquiles to empanadas to fajitas, the best Mexican
            food is tasty, spicy and oftentimes very easy to prepare.
          </p>
        </article>
      </section>

      <div className="container">
        <h4 className="my-5">Submarine Cables</h4>
        <a
          href="https://www.submarinecablemap.com/country/mexico"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/iQkpQnW.png"
            alt="submarine cables"
            width="100%"
            className=""
          />
        </a>
        <ol className="mt-4">
          <li>American Movil Sbmarine Cable System-1 (AMX-1)</li>

          <li>ARCOS</li>

          <li>AURORA Cable System</li>

          <li>Caribbean EXPRESS (CX)</li>

          <li>GigNet-1</li>

          <li>Gulf of California Cable</li>

          <li>Ixchel</li>

          <li>
            Lazaro Cardenas-Manzanillo Santiago Submarine Cable System (LCMSSCS)
          </li>

          <li>Maya-1</li>

          <li>Pan-American Crossing (PAC) </li>
        </ol>

        <h4 className="my-4">ACCESS TO LANDLINE AND MOBILE PHONE*</h4>
        <p>
          Mexico is the second largest mobile market in Latin America with 89
          million unique mobile subscribers, equivalent to 69% of the
          population. In 2020, the number of smartphone users in Mexico was
          estimated at nearly 81 million. This is an increase of about five
          percent from the 77 million users registered the previous year.{' '}
        </p>

        <p>
          In 2020, the Mexican telecommunication sector (which encompasses
          mobile and fixed-line telecommunication as well as pay TV) generated
          revenues of nearly 498.3 billion Mexican pesos, out of which over
          286.8 billion will be generated by the mobile subsector, 116.5 billion
          by pay TV and 95 by the fixed-line subsector.{' '}
        </p>

        <h4 className="my-4">ACCESS TO INTERNET*</h4>
        <p>
          {' '}
          Mexico has approximately 81 million Internet users representing 70.1%
          of the population. The country ranks 10 in number of Internet users in
          the world. Mexico is one of the few Latin American countries that
          has little or no Internet censorship.{' '}
        </p>

        <h4 className="my-4">Internet Providers*</h4>
        <p>
          Its an amazing fact that Mexico is very well connected, with 10
          submarine cables landing and every day more and more people having
          access to internet. The Average Internet Download speed in the country
          is 20.44 mbs and average upload speed is 11.03mbs.
        </p>

        <p>
          The country has 13 Internet providers owning infrastructure who
          provide the access throughout the country. inte-QT as a
          global access enabler partner with the local providers and today, we
          have 9 infrastructure owning partners in Mexico.
        </p>

        <h4 className="my-4">Our Capabilities</h4>
        <p>We deliver for the most happening events like the racing championships, leagues and also for many global enterprises who have their presence in the country. We give the best-in-class access and serve high uptime solutions on fiber.</p>
        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connect</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>SLA</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins"
          align="justify"
        >
          With our Global Business Solutions team, we can provide a{' '}
          <mark>Commercial Offer in 2-3 working days</mark> and our Global Ops
          team is capable to deliver services in the country in 2 to 4 weeks.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://www.nationsonline.org/oneworld/map/mexico-administrative-map.htm#:~:text=Mexico%20is%20a%20country%20in,borders%20with%20Cuba%20and%20Honduras"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.statista.com/statistics/1229858/incoming-tourists-land-mexico/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://www.liveandinvestoverseas.com/country-hub/mexico/climate-in-mexico/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
          <li>
            <a
              href="https://www.sidmartinbio.org/what-does-mexico-contribute-to-the-global-economy/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 4
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Mexico;
