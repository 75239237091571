import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function Vietnam() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Vietnam - inte-QT</title>
        <meta
          name="title"
          content="Internet in Vietnam - inte-QT"
        />
        <meta name="description" content="Our Coverage in Vietnam" />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/vietnam" />
      </Helmet>
      <img
        src="https://i.imgur.com/wJ1x0q1.jpg"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - In 2021, the population of Vietnam
              was estimated to reach 98.51 million*, indicating an increase by
              nearly a million people from the previous year.
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - Vietnam is a long,
              narrow nation. It is in Southeast Asia on the eastern edge of the
              peninsula known as Indochina. Vietnam borders China to the north,
              Laos and Cambodia to the west, and shares maritime borders with
              Thailand through the Gulf of Thailand, and the Philippines,
              Indonesia, and Malaysia through the South China Sea.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - Vietnamese is the official
              language; English is increasingly favored as a second language.
              French, Chinese, Khmer and various highlander languages are also
              spoken. Between 85 percent and 90 percent of Vietnam's residents
              are ethnically Vietnamese.
            </li>
            <li>
              <strong>CURRENCY</strong> - The Vietnamese dong is the official
              currency of Vietnam, abbreviated to VND
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Major Industries in Vietnam: food
              processing, garments, textiles, shoes, machine-building; mining,
              coal, steel; cement, chemical fertilizer, glass, tires, oil,
              mobile phones. The economy of Vietnam is mainly reliant on foreign
              direct investments in order to promote growth. The largest
              industries here are services which make up 49.75% of the GDP,
              industry which makes up 33.25%, and agriculture which makes up 17%
              of GDP.
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Ho Chi Minh City</li>
                <li>Hoi Ann</li>
                <li>Hue</li>
              </ul>
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/c98561f0287840e4b9493039b62bd50d/raw/6873349def7fe48cf05c29a7bf2b31d66e8b2548/vietnam-flag.svg"
              alt="Vietnam flag"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
          There are many things Vietnam is famous for, besides it being a Southeast Asian country, it is a top worth-visiting place due to its ancient history, diverse culture, and quintessential natural landscapes.
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7864040.465084591!2d101.41544887957!3d15.758358200014952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31157a4d736a1e5f%3A0xb03bb0c9e2fe62be!2sVietnam!5e0!3m2!1sen!2ses!4v1647338327204!5m2!1sen!2ses"
            width="95%"
            title="vietnam-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <h4 className="mt-4">Tourism</h4>
          <p>
            In 2019, Vietnam welcomed approximately 18 million* international
            visitor arrivals. The number of international arrivals to the
            country had been growing fast year on year since 2015. However, as
            it was expected to decrease significantly in 2020 due to the impact
            of the COVID-19 pandemic, and its related travel restrictions, it
            has been like that for the whole of 2021.
          </p>
          <h4 className="mt-4">Main Ports / Airports</h4>
          <p>
            Vietnam is served by three main international airports: Noi Bai
            International Airport (HAN), Tan Son Nhat International Airport
            (SGN) and Da Nang International Airport (DAD) located in Hanoi, Ho
            Chi Minh City and Da Nang respectively.
          </p>

          <p>
            The major ports in Vietnam include Hai Phong, Da Nang, Qui Nhon, and
            Ho Chi Minh City. However, Vietnam has a number of smaller ports as
            well, which takes the total number of ports in the country up to
            320.
          </p>
          <h4 className="mt-5">National Sport</h4>
          <p>
            Da Cau - The National Sport in Vietnam <br />
            It may like a “football badminton “. This sport has been practiced
            in Vietnam for centuries.
          </p>
          <h4 className="mt-5">Typical Food</h4>
          <p>
            Pho is arguably the most popular food in Vietnam. It is served both
            at restaurants and in families, eaten for breakfast, lunch or
            dinner. This famous dish is basically rice noodle soup but the broth
            is seasoned in a very unique way that appeals to almost everyone.
          </p>
          <h4 className="mt-5">Weather</h4>
          <p>
            Vietnam has both a tropical climate zone and a temperate climate
            zone, with all of the country experiencing the effects of the annual
            monsoon. Rainy seasons correspond to monsoon circulations, which
            bring heavy rainfall in the north and south from May to October, and
            in the central regions from September to January.
          </p>
          <h4 className="mt-5">Employment</h4>
          <p>
            Vietnam's unemployment rate is one of the lowest rates in the world.
            According to the World Bank, Vietnam has one of the lowest
            unemployment rates (2.2%) because many international companies have
            chosen Vietnam to invest im.
          </p>
        </article>
      </section>

      <div className="container">
        <h4 className="my-5">Submarine Cables</h4>
        <a
          href="https://www.submarinecablemap.com/country/vietnam"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/O2nPkbz.png"
            alt="submarine cables"
            width="100%"
          />
        </a>
        <ol className="mt-4">
          <li>Asia África Europe-1 (AAE-1)</li>

          <li>Asia-America Gateway (AAG) Cable System</li>

          <li>Asia Direct Cable (ADC)</li>

          <li>Asia Pacific Gateway (APG)</li>

          <li>SeaMeWe-3</li>

          <li>Southeast Asia-Japan Cable 2 (SJC2)</li>

          <li>Tata TGN-Intra Asia (TGN-IA)</li>
        </ol>

        <h4 className="my-4">
          ACCESS TO LANDLINE AND MOBILE PHONE ACCESS TO INTERNET*
        </h4>
        <p>
          Vietnam's telecommunication market is becoming more attractive to
          foreign investors as it has returned to the growth path in the first
          half of 2019 after a long period of saturation. According to experts,
          the government's plans for the Fourth Industrial Revolution, smart
          cities, start-ups, and the National Innovation Network Program,
          enabled by state-of-the-art 4G and 5G, IoT, and mobile
          telecommunication networks, are helping the ICT sector continue to
          record strong revenue growth.
        </p>

        <p>
          There is room to grow for foreign investors in Vietnam's
          telecommunication industry. The number of households with internet
          access at home is only 27 per 100 households while only 9.3% of
          Vietnamese households have a fixed-line telephone, an industry expert
          said.
        </p>

        <p>
          The number of mobile subscribers with a 3G data connection is also
          still below the world average (39 subscribers per 100 people) while
          the number of subscribers with a 4G data connection is much lower and
          the 5G network has only been deployed on a trial basis in Vietnam.
          Data demand is on the rise in the country and will keep increasing in
          the near future, according to estimations from the Ministry of
          Information and Communications (MIC). Telecom experts said that the
          number of 4G subscribers will increase by over nine-fold from 2019 to
          2024.
        </p>

        <h4 className="my-4">Internet Providers*</h4>
        <p>
          Speed test statistics showed that the download speed of mobile
          networks in Vietnam reached 42.46 Mbps and upload seppd 19.25 mbps in
          July 2021. Speedtest also said that for fixed broadband internet,
          Vietnam achieved a download speed of 78.43 Mbps and an average upload
          speed at 68.38 Mbps.
        </p>

        <p>
          The country has 8 Internet providers owning infrastructure who provide
          the access throughout the country. inte-QT as a global
          access enabler partner with the local providers and today, we have 5
          infrastructure owning partners in Vietnam.
        </p>

        <h4 className="my-4">Our Capabilities</h4>
        <p>We have capabilities to offer diversity at building entry and till gateway level. We can offer Broadbands, and stable FTTX with IP Pools, currently we are delivering in main cities; <strong>Ho Chi Minh</strong>, <strong>Ha Noi</strong> and <strong>Hai Phong</strong> with guaranteed 24/7 monitoring from our NSOC team.</p>
        
        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connectivity</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>SLA</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins"
          align="justify"
        >
          With our Global Business Solutions team, we can provide a{' '}
          <mark>Commercial Offer in 2-4 working days</mark> and our Global Ops
          team is capable to deliver services in the country in 4 to 6 weeks.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://tradingeconomics.com/vietnam/unemployment-rate"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.worldatlas.com/articles/languages-spoken-in-vietnam.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://www.statista.com/statistics/1197952/vietnam-number-of-international-visitor-arrivals/#:~:text=In%202019%2C%20Vietnam%20welcomed%20approximately,and%20its%20related%20travel%20restrictions"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
          <li>
            <a
              href="https://www.vietnam-briefing.com/news/port-infrastructure-vietnam-3-hubs-for-importers-exporters.html/#:~:text=The%20major%20ports%20in%20Vietnam,the%20country%20up%20to%20320"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 4
            </a>
          </li>
          <li>
            <a
              href="https://climateknowledgeportal.worldbank.org/country/vietnam/climate-data-historical#:~:text=Vietnam%20has%20both%20a%20tropical,regions%20from%20September%20to%20January"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 5
            </a>
          </li>
          <li>
            <a
              href="https://opengovasia.com/growth-in-vietnams-telecom-industry/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 6
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Vietnam;
