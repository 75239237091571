import React from 'react';
import './AboutUsGallery.scss';

export default function AboutUsGallery() {
  return (
    <div>
      <div id="background">
  <div id="gallery">
    <figure className="pic1">
      <img src="https://i.imgur.com/gR8woaJ.jpg" alt=""/>
      {/* <figcaption>Lorem</figcaption> */}
    </figure>
    <figure className="pic2">
      <img src="https://i.imgur.com/pdjkCeO.jpg" alt=""/>
      {/* <figcaption>Lorem</figcaption> */}
    </figure>
    <figure className="pic3">
      <img src="https://i.imgur.com/CllDVVU.jpg" alt=""/>
      {/* <figcaption>Lorem</figcaption> */}
    </figure>
    <figure className="pic4">
      <img src="https://i.imgur.com/CEdLLgE.jpg" alt=""/>
      {/* <figcaption>Lorem</figcaption> */}
    </figure>
    {/* <figure className="pic5">
      <img src="https://i.imgur.com/PBKF4yG.jpg" alt=""/>
      <figcaption>Lorem</figcaption>
    </figure> */}
    {/* <figure className="pic6">
      <img src="http://www.adventure-journal.com/wp-content/uploads/2009/11/jackbrull01_470.jpg" alt=""/>
      <figcaption>Lorem</figcaption>
    </figure>
    <figure className="pic7">
      <img src="http://www.webdesign.org/img_articles/22269/Sierra1.png" alt=""/>
      <figcaption>Lorem</figcaption>
    </figure> */}
    {/* <figure className="pic8">
      <img src="http://media-cache-ak0.pinimg.com/236x/55/82/2d/55822d7c9855f4751051328681cde4c0.jpg" alt=""/>
      <figcaption>Lorem</figcaption>
    </figure>
    <figure className="pic9">
      <img src="https://picsum.photos/200" alt=""/>
      <figcaption>Lorem</figcaption>
    </figure>
    <figure className="pic10">
      <img src="https://33.media.tumblr.com/tumblr_lgidr1McXV1qdimp1o1_400.jpg" alt=""/>
      <figcaption>Lorem</figcaption>
    </figure>
    <figure className="pic11">
      <img src="https://m1.behance.net/rendition/modules/4344109/disp/674ec054303b2c2e7e39527e2f8dd01b.jpg" alt=""/>
      <figcaption>Lorem</figcaption>
    </figure>
    <figure className="pic12">
      <img src="https://m1.behance.net/rendition/modules/4344109/disp/674ec054303b2c2e7e39527e2f8dd01b.jpg" alt=""/>
      <figcaption>Lorem</figcaption>
    </figure> */}
  </div>
</div>
    </div>
  )
}
