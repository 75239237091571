import React from 'react';
import './ConnectivitySolutions.scss';
import DinamicLink from '../DinamicLink/DinamicLink';

export default function ConnectivitySolutions() {
  return (
    <div>
      <div className="row">

        <div className="col-lg-4 col-md-12">
          <div className="card__bodyup">
            <div className="card__container">
              <div className="card__content">

                <div className="card__front card__front-background1">
                  <h3 className="card__title">Dedicated Lines</h3>
                </div>

                <div className="card__back">
                  <div className="card__body">
                    <p className="my-0">
                      Global coverage across Tier 1, Tier2, Tier3 cities and far-flung areas.
                    </p>
                    <hr />
                    <p className="my-0">
                      High Availability & Faster Delivery
                    </p>
                    <hr />
                    <p className="my-0">
                      Committed Bandwidth up to 10G.
                    </p>
                    <hr />
                    <p className="my-0">
                      Diverse, Scalable, Secure and Reliable Solutions.
                    </p>
                    <hr />
                    <p className="my-0">
                      IPV6 Ready, Stringent SLA with 24*7*365 Proactive monitoring.
                    </p>

                    <div className="text-end mt-2">
                      <DinamicLink name="Read more" url="/services/dedicated-lines" />
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-12">
          <div className="card__bodyup">
            <div className="card__container">
              <div className="card__content">

                <div className="card__front card__front-background2">
                  <h3 className="card__title">Business Broadband</h3>
                </div>

                <div className="card__back">
                  <div className="card__body">
                    <p className="my-0">
                      Worldwide delivery capability in the segment.
                    </p>
                    <hr />
                    <p className="my-0">
                      Fastest delivery with tailored options.
                    </p>
                    <hr />
                    <p className="my-0">
                      High bandwidth offering.
                    </p>
                    <hr />
                    <p className="my-0">
                      Convergent, Redundant Gateway, Hyper fast and fully flexible broadband services.
                    </p>
                    <hr />
                    <p className="my-0">
                      Best in class SLA with Proactive Network Health Monitoring.
                    </p>

                    <div className="text-end mt-2">
                      <DinamicLink name="Read more" url="/services/business-broadband" />
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-12">
          <div className="card__bodyup">
            <div className="card__container">
              <div className="card__content">

                <div className="card__front card__front-background3">
                  <h3 className="card__title">Wireless Connect</h3>
                  {/* <p class="card__subtitle">Time for some fun</p> */}
                </div>

                <div className="card__back">
                  <div className="card__body">
                    <p className="my-0">Global Wireless connectivity using 3G/4G/5G options. </p>
                    <hr />
                    <p className="my-0">Quick deployments with High-capacity Bandwidth.</p>
                    <hr />
                    <p className="my-0">Secure, optimal performance wireless connectivity solutions.</p>
                    <hr />
                    <p className="my-0">Low latency, High speed mobility.</p>
                    <hr />
                    <p className="my-0">Services monitoring and 24*7 Customer support.</p>

                    <div className="text-end">
                      <DinamicLink name="Read more" url="/services/wireless-connect" />
                    </div>
                  </div>
                  
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
