import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import { IconLocation } from '../IconLocation/IconLocation';

export default function Markers(props) {
  const { places } = props;
  const markers = places.map((place, i) => (
    <Marker key={i} position={place.geometry} icon={IconLocation}>
      <Popup>
        {place.name}
      </Popup>
    </Marker>
  ));
  return markers;
}
