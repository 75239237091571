import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import DinamicLink from '../../../components/DinamicLink/DinamicLink';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../Blog.scss';

function Blog3() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="blog">
      <Helmet>
        <title>Committed to Deliver - inte-QT</title>
        <meta name="title" content="Committed to Deliver - inte-QT" />
        <meta
          name="description"
          content="During the pandemic's peak, inte-QT received a critical request to delay a delivery in Lebanon. Learn how we managed to deliver despite the challenges."
        />
        <meta
          name="keywords"
          content="Service Delivery, Pandemic, Customer Service, Global Ops, inte-QT"
        />
        <meta name="author" content="Gaurav Daga" />
        <meta property="og:title" content="Committed to Deliver - inte-QT" />
        <meta
          property="og:description"
          content="During the pandemic's peak, inte-QT received a critical request to delay a delivery in Lebanon. Learn how we managed to deliver despite the challenges."
        />
        <meta property="og:image" content="https://i.imgur.com/38sP42d.jpg" />
        <meta property="og:url" content="https://www.inte-qt.com/blog/3" />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="BitsnByte Global" />
        <link rel="canonical" href="https://www.inte-qt.com/blog/3" />
      </Helmet>
      <h1 className="center-header center-header__title mx-auto text-center">
        Committed to Deliver, Even After Disastrous Conditions
      </h1>
      <div className="container">
        <div className="blog__text" align="justify">
          <div className="text-end">
            <p className="blogPosts__tags">
              <span className="blue mx-2">Opportunities</span>/
              <span className="blue mx-2">Service Assurance</span>/
              <span className="blue mx-2">Service Delivery</span>
            </p>
          </div>
          <p className="blogPosts__author">
            {`By `}
            <a
              href="https://www.linkedin.com/in/dagagaurav/"
              className="blue"
              target="_blank"
              rel="noopener noreferrer"
            >
              {`Gaurav Daga `}
              <i className="fab fa-linkedin-in ms-1"></i>
            </a>
            {` on May 14th, 2021 · 2 min read`}
          </p>
          <div className="text-center">
            <img
              src="https://i.imgur.com/38sP42d.jpg"
              alt="Service delivery during pandemic"
              className="blog__img blog__img-big"
              loading="lazy"
            />
          </div>
          <p>
            In the midst of the last year, while the pandemic was at its peak,
            we received a call from one of our Carrier customers asking us to
            delay an ongoing delivery in <strong>Lebanon</strong>. It was a{' '}
            <strong>Dedicated Line</strong> with a Microwave backup. Though we
            are headquartered in Madrid, Spain, our efficient{' '}
            <strong>Partner Center Model</strong> enables us to onboard local
            and infrastructure players in more than{' '}
            <strong>183 countries</strong>. This model and proactive approach
            give us a competitive edge as the preferred access partner for our
            customers.
          </p>
          <p>
            That call in August was unusual, as it was about delaying a
            delivery. Our <strong>Global Ops</strong> team was alerted and
            arranged a virtual meeting with the customer to understand the
            issue. Despite the disastrous blast in Beirut a week prior, we
            ensured the delivery was on time.
          </p>
          <div className="row mt-5 text-center">
            <div className="col-lg-4 col-md-12 my-2">
              <img
                src="https://i.imgur.com/Lwu32SK.jpg"
                alt="Delivery operations"
                className="blog__galleryimg"
                loading="lazy"
              />
            </div>
            <div className="col-lg-4 col-md-12 my-2">
              <img
                src="https://i.imgur.com/3fGn0Io.jpg"
                alt="Service delivery team"
                className="blog__galleryimg"
                loading="lazy"
              />
            </div>
            <div className="col-lg-4 col-md-12 my-2">
              <img
                src="https://i.imgur.com/E1Lalyv.jpg"
                alt="Operational support"
                className="blog__galleryimg"
                loading="lazy"
              />
            </div>
          </div>
          <div className="text-center">
            <img
              src="https://i.imgur.com/xf1gj0h.png"
              alt="Service delivery"
              className="blog__img"
              loading="lazy"
            />
          </div>
          <p>
            Our <strong>Global Ops</strong> team operates with a{' '}
            <strong>Customer First Approach</strong>. The virtual meeting
            revealed that the customer was struggling with the installation of
            their Network Security Device. The partner had increased their rates
            due to the chaos in the city. We stepped in with one of our January
            2020 offers:{' '}
            <strong className="blue" style={{ fontStyle: 'italic' }}>
              2 Hours of Free Installation
            </strong>{' '}
            with every access line. This offer provided immediate support and
            additional savings for the customer.
          </p>
          <p>
            We offer this service to many customers in various countries and
            islands, which they refer to as exotic locations.{' '}
            <span style={{ textDecoration: 'underline' }}>
              This free 2-hour onsite support and connectivity offer makes
              inte-QT stand out.
            </span>
          </p>
          <h6>
            "We are committed to deliver and give our clients the best support,
            even in such conditions."
            <br />
            Gaurav Daga
            <br />
            Founder & Director
            <br />
            inte-QT
          </h6>
        </div>

        <div className="blogPosts__box">
          <div className="blogPosts__imgbox me-5">
            <img
              src="https://i.imgur.com/mCLSqQE.png"
              alt="Business Analyst"
              className="blogPosts__img"
              loading="lazy"
            />
          </div>
          <p className="blogPosts__tags">
            <span className="blue mx-2">Business Analyst</span>/
            <span className="blue mx-2">Training</span>/
            <span className="blue mx-2">Induction Program</span>
          </p>
          <h2 className="blogPosts__title">
            And Here I Am, Part of the Internet Wave...
          </h2>
          <p className="blogPosts__author">
            {`By `}
            <a
              href="https://www.linkedin.com/in/viviana-passaro/"
              className="blue"
              target="_blank"
              rel="noopener noreferrer"
            >
              {`Viviana Passaro `}
              <i className="fab fa-linkedin-in ms-1"></i>
            </a>
            {` on April 15th, 2021 · 2 min read`}
          </p>

          <p>
            My experience has been in Market Intelligence (fast food and mass
            market), which was affected by the pandemic. I sought new
            opportunities to learn, grow, and prove myself.
          </p>
          <div className="text-end me-5">
            <DinamicLink url="/blog/2" name="Read more" />
          </div>
        </div>

        <GetInTouch />
      </div>
    </div>
  );
}

export default Blog3;


