import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AnimatedProgressProvider from './AnimatedProgressProvider';
import { easeQuadInOut } from 'd3-ease';
import './AnimatedCircle.scss';

export default function AnimatedCircle() {
  return (
    <div className="container mt-5">
      <div className="row animatedCircle text-center">
        <div style={{ width: 200, height: 180}} className="animatedCircle__each">
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={99}
            duration={4}
            easingFunction={easeQuadInOut}
            repeat={false}
          >
            {(value) => {
              const roundedValue = Math.round(value);
              return (
                <CircularProgressbar
                  value={value}
                  text={`${roundedValue}%`}
                  /* This is important to include, because if you're fully managing the
            animation yourself, you'll want to disable the CSS animation. */
                  styles={buildStyles({
                    pathTransition: 'none',
                    // Colors
                    pathColor: `rgba(${roundedValue / 3.1}, ${value /1.2}, ${roundedValue * 1.8}, ${roundedValue / 100})`,
                    textColor: '#1f55ba',
                    trailColor: '#f1f5fd',
                  })}
                />
              );
            }}
          </AnimatedProgressProvider>
          <h3 className="blue mt-2">Dedicated Line</h3>
        </div>
        <div style={{ width: 200, height: 180}} className="animatedCircle__each">
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={95}
            duration={4}
            easingFunction={easeQuadInOut}
            repeat={false}
          >
            {(value) => {
              const roundedValue = Math.round(value);
              return (
                <CircularProgressbar
                  value={value}
                  text={`${roundedValue}%`}
                  /* This is important to include, because if you're fully managing the
            animation yourself, you'll want to disable the CSS animation. */
                  styles={buildStyles({
                    pathTransition: 'none',
                    // Colors
                    pathColor: `rgba(${roundedValue / 3.1}, ${value /1.2}, ${roundedValue * 1.8}, ${roundedValue / 100})`,
                    textColor: '#1f55ba',
                    trailColor: '#f1f5fd',
                  })}
                />
              );
            }}
          </AnimatedProgressProvider>
          <h3 className="blue mt-2">Business Broadband</h3>
        </div>

        <div style={{ width: 200, height: 180}} className="animatedCircle__each">
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={99}
            duration={4}
            easingFunction={easeQuadInOut}
            repeat={false}
          >
            {(value) => {
              const roundedValue = Math.round(value);
              return (
                <CircularProgressbar
                  value={value}
                  text={`${roundedValue}%`}
                  /* This is important to include, because if you're fully managing the
            animation yourself, you'll want to disable the CSS animation. */
                  styles={buildStyles({
                    pathTransition: 'none',
                    // Colors
                    pathColor: `rgba(${roundedValue / 3.1}, ${value /1.2}, ${roundedValue * 1.8}, ${roundedValue / 100})`,
                    textColor: '#1f55ba',
                    trailColor: '#f1f5fd',
                  })}
                />
              );
            }}
          </AnimatedProgressProvider>
          <h3 className="blue mt-2">Wireless Connect</h3>
        </div>

        <div style={{ width: 200, height: 180}} className="animatedCircle__each">
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={99}
            duration={4}
            easingFunction={easeQuadInOut}
            repeat={false}
          >
            {(value) => {
              const roundedValue = Math.round(value);
              return (
                <CircularProgressbar
                  value={value}
                  text={`${roundedValue}%`}
                  /* This is important to include, because if you're fully managing the
            animation yourself, you'll want to disable the CSS animation. */
                  styles={buildStyles({
                    pathTransition: 'none',
                    // Colors
                    pathColor: `rgba(${roundedValue / 3.1}, ${value /1.2}, ${roundedValue * 1.8}, ${roundedValue / 100})`,
                    textColor: '#1f55ba',
                    trailColor: '#f1f5fd',
                  })}
                />
              );
            }}
          </AnimatedProgressProvider>
          <h4 className="blue mt-2">Average Service Level</h4>
        </div>

        <div style={{ width: 200, height: 180}} className="animatedCircle__each">
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={10}
            duration={4}
            easingFunction={easeQuadInOut}
            repeat={false}
          >
            {(value) => {
              const roundedValue = Math.floor(value * 100) / 100;
              return (
                  <CircularProgressbar
                    value={roundedValue}
                    text={`${roundedValue}GB`}
                    maxValue={10}
                    /* This is important to include, because if you're fully managing the
              animation yourself, you'll want to disable the CSS animation. */
                    styles={buildStyles({
                      pathTransition: 'none',
                      // Colors
                      pathColor: `rgba(${roundedValue * 4}, ${roundedValue * 8}, ${roundedValue * 18}, ${roundedValue / 10})`,
                      textColor: '#1f55ba',
                      trailColor: '#f1f5fd',
                    })}
                  />
              );
            }}
          </AnimatedProgressProvider>
          <h3 className="blue mt-2">Maximun Speed</h3>
        </div>
      </div>
    </div>
  );
}
