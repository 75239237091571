import React from 'react';
import './BlogPosts.scss';
import DinamicLink from '../DinamicLink/DinamicLink';

export default function BlogPosts() {
  return (
    <div className="blogPosts">
      <h1 className="center-header mx-auto text-center center-header__title">
        Blog
      </h1>
      <div className="container">


<div className="blogPosts__box">
          <div className="blogPosts__imgbox me-5">
            <img
              src="https://i.imgur.com/Q9CAlyz.jpg"
              alt=""
              className="blogPosts__img"
            />
          </div>
          <p className="blogPosts__tags">
            <span className="blue mx-2">Intro</span>/
            <span className="blue mx-2">Telecom</span>/
            <span className="blue mx-2">AI</span>
          </p>
          <h3 className="blogPosts__title">
            Transforming Lives With Technology
          </h3>
          <p className="blogPosts__author">
          By
            <a
              href="https://www.linkedin.com/in/daniel-jose-rodriguez-mendez-4235331a8/"
              className="blue"
            >
              Daniel Jose Rodriguez Mendez<i className="fab fa-linkedin-in ms-1"></i>
            </a>{' '}
            on October 26th, 2022 · 4 min read
          </p>
          <p>
          The internet is the global system architecture which is used to interconnect computer networks.
          It is a global Network of computers and all other electronic devices. It makes the devices smarter
          ...
          </p>
          <div className="text-end me-5">
            <DinamicLink url="/blog/8" name="Read more" />
          </div>
        </div>


        <div className="blogPosts__box">
          <div className="blogPosts__imgbox me-5">
            <img
              src="https://i.imgur.com/2wQZlxh.jpg"
              alt=""
              className="blogPosts__img"
            />
          </div>
          <p className="blogPosts__tags">
            <span className="blue mx-2">Intro</span>/
            <span className="blue mx-2">IOT</span>/
            <span className="blue mx-2">Applications</span>
          </p>
          <h3 className="blogPosts__title">
            Internet of Things: What is IOT?
          </h3>
          <p className="blogPosts__author">
          By
            <a
              href="https://www.linkedin.com/in/ashish-nankani-017621220/"
              className="blue"
            >
              Ashish Nankani<i className="fab fa-linkedin-in ms-1"></i>
            </a>{' '}
            on March 7th, 2022 · 4 min read
          </p>
          <p>
          Modern organizations use IOT applications and sensors for everything
            from optimizing manufacturing and supply chain practices to aiding
            the development of municipalities through use in smart cities.
          </p>
          <div className="text-end me-5">
            <DinamicLink url="/blog/7" name="Read more" />
          </div>
        </div>

        <div className="blogPosts__box">
          <div className="blogPosts__imgbox me-5">
            <img
              src="https://i.imgur.com/7CgnyPK.jpg"
              alt=""
              className="blogPosts__img"
            />
          </div>
          <p className="blogPosts__tags">
            <span className="blue mx-2">Intro</span>/
            <span className="blue mx-2">Mechanism</span>/
            <span className="blue mx-2">Benefits</span>
          </p>
          <h3 className="blogPosts__title">
          Our Access Makes it STRONG: SD-WAN
          </h3>
          <p className="blogPosts__author">
            {' '}
            By{' '}
            <a
              href="https://www.linkedin.com/in/mohammed-arshad30/"
              className="blue"
            >
              Mohammed Arshad<i className="fab fa-linkedin-in ms-1"></i>
            </a>{' '}
            on October 27th, 2021 · 3 min read
          </p>
          <p>
          SD WAN (Software Defined Wide Area Networking) is an
            Application-Centric Solution, and it refers to the use of software
            to implement and manage the WAN, including virtualisation of routers...
          </p>
          <div className="text-end me-5">
            <DinamicLink url="/blog/6" name="Read more" />
          </div>
        </div>

        <div className="blogPosts__box">
          <div className="blogPosts__imgbox me-5">
            <img
              src="https://i.imgur.com/Gt6jUPa.jpg"
              alt=""
              className="blogPosts__img"
            />
          </div>
          <p className="blogPosts__tags">
            <span className="blue mx-2">Service Assurance</span>/
            <span className="blue mx-2">SLA%</span>/
            <span className="blue mx-2">MTTR</span>
          </p>
          <h3 className="blogPosts__title">
            Service Assurance: A Responsibility That Cannot Have A No Show
          </h3>
          <p className="blogPosts__author">
            {' '}
            By{' '}
            <a
              href="https://www.linkedin.com/in/nikhil-jain-705a6943/"
              className="blue"
            >
              Nikhil Jain<i className="fab fa-linkedin-in ms-1"></i>
            </a>{' '}
            on September 28th, 2021 · 3 min read
          </p>
          <p>
            In the world of Digital Era, it is impossible to live without being
            connected. Lives and Businesses can’t remain healthy unless
            Information Technology can deliver...
          </p>
          <div className="text-end me-5">
            <DinamicLink url="/blog/5" name="Read more" />
          </div>
        </div>

        <div className="blogPosts__box">
          <div className="blogPosts__imgbox me-5">
            <img
              src="https://i.imgur.com/Vi0se0F.jpg"
              alt=""
              className="blogPosts__img"
            />
          </div>
          <p className="blogPosts__tags">
            <span className="blue mx-2">Connectivity</span>/
            <span className="blue mx-2">Bitcoin</span>/
            <span className="blue mx-2">Blockchain</span>
          </p>
          <h2 className="blogPosts__title">What is Blockchain about??</h2>
          <p className="blogPosts__author">
            {' '}
            By{' '}
            <a href="https://www.linkedin.com/in/juanrolo/" className="blue">
              Juan Rolo<i className="fab fa-linkedin-in ms-1"></i>
            </a>{' '}
            on September 22th, 2021 · 4 min read
          </p>
          <p>
            In the digital era where everything runs through a computer or a
            cell phone, it seems so natural to see the news of what is happening
            around the world that sometimes we do not stop to think about...
          </p>
          <div className="text-end me-5">
            <DinamicLink url="/blog/4" name="Read more" />
          </div>
        </div>

        <div className="blogPosts__box">
          <div className="blogPosts__imgbox me-5">
            <img
              src="https://i.imgur.com/38sP42d.jpg"
              alt=""
              className="blogPosts__img"
            />
          </div>
          <p className="blogPosts__tags">
            <span className="blue mx-2">Opportunities</span>/
            <span className="blue mx-2">Service Assurance</span>/
            <span className="blue mx-2">Service Delivery</span>
          </p>
          <h2 className="blogPosts__title">
            Committed to deliver, even after disastrous conditions..
          </h2>
          <p className="blogPosts__author">
            {' '}
            By{' '}
            <a href="https://www.linkedin.com/in/dagagaurav/" className="blue">
              Gaurav Daga<i className="fab fa-linkedin-in ms-1"></i>
            </a>{' '}
            on May 14th, 2021 · 2 min read
          </p>
          <p>
            Providing 2 Hours of Onsite Service free with every circuit
            installation. We offer Layer-3 Internet Managed service in 183
            countries.
          </p>
          <div className="text-end me-5">
            <DinamicLink url="/blog/3" name="Read more" />
          </div>
        </div>

        <div className="blogPosts__box">
          <div className="blogPosts__imgbox me-5">
            <img
              src="https://i.imgur.com/mCLSqQE.png"
              alt=""
              className="blogPosts__img"
            />
          </div>
          <p className="blogPosts__tags">
            <span className="blue mx-2">Business Analyst</span>/
            <span className="blue mx-2">Training</span>/
            <span className="blue mx-2">Induction Program</span>
          </p>
          <h2 className="blogPosts__title">
            And here I am, part of the Internet wave...
          </h2>
          <p className="blogPosts__author">
            {' '}
            By{' '}
            <a
              href="https://www.linkedin.com/in/viviana-passaro/"
              className="blue"
            >
              Viviana Passaro<i className="fab fa-linkedin-in ms-1"></i>
            </a>{' '}
            on April 15th, 2021 · 2 min read
          </p>
          <p>
            My experience has been in Market intelligence (fast food and mass
            market) which had its effect due to the pandemic. I was looking for
            new opportunities, where I could learn, grow and prove myself.
          </p>
          <div className="text-end me-5">
            <DinamicLink url="/blog/2" name="Read more" />
          </div>
        </div>

        <div className="blogPosts__box">
          <div className="blogPosts__imgbox me-5">
            <img
              src="https://i.imgur.com/52EZD6B.png"
              alt=""
              className="blogPosts__img"
            />
          </div>
          <p className="blogPosts__tags">
            <span className="blue mx-2">Service Delivery</span>/
            <span className="blue mx-2">Customer Delight</span>/
            <span className="blue mx-2">COVID-19</span>
          </p>
          <h2 className="blogPosts__title">Customer Delight Approach</h2>
          <p className="blogPosts__author">
            {' '}
            By{' '}
            <a
              href="https://www.linkedin.com/in/nikhil-jain-705a6943/"
              className="blue"
            >
              Nikhil Jain<i className="fab fa-linkedin-in ms-1"></i>
            </a>{' '}
            on August 17th, 2020 · 1 min read
          </p>
          <p>
            A significant part of world’s population is affected amidst COVID-19
            pandemic, the people are utilizing connectivity services more not
            only for normal browsing and streaming...
          </p>
          <div className="text-end me-5">
            <DinamicLink url="/blog/1" name="Read more" />
          </div>
        </div>
      </div>
    </div>
  );
}
