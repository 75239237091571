import React, {useEffect} from 'react';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import './WirelessConnect.scss';
import CarouselIndu from '../../../components/CarouselIndu/CarouselIndu';
import TestimonialCarousel from '../../../components/TestimonialCarousel/TestimonialCarousel';
import ConnectivitySolutions from '../../../components/ConnectivitySolutions/ConnectivitySolutions';
import { Helmet } from 'react-helmet';

export default function WirelessConnect() {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  return (
    <div className="xc">
      <Helmet>
        <title>Wireless Connect - inte-QT</title>
        <meta name="title" content="Wireless Connect - inte-QT" />
        <meta
          name="description"
          content="Networks that use 3G,4G Long Term Evolution (LTE) and 5G mobile broadband technologies."
        />
        <link rel="canonical" href="https://www.inte-qt.com/services/wireless-connect" />
      </Helmet>
      <img
        src="https://i.imgur.com/SXFDSV4.jpg"
        alt="12433124"
        className=" banner-img__black banner-img"
      />
      <div className="xc__gradient"></div>
      <h1 className="banner-img__header text-center">
        Wireless Connect
      </h1>
      <div className="container-fluid">
        <div className="container" align="justify">
          <div className="b_read">
            <p className="xc__subtitle text-center">
              Delivering the new era of wireless connectivity, Plug and Play
              3G/4G/5G and wireless LTE solutions.
            </p>
            <h3 className="xc__q">
              What is wireless connects (XC) and it’s Importance?
            </h3>
            <p>
              Networks that use 3G,4G Long Term Evolution (LTE) and 5G mobile
              broadband technologies.
            </p>
            <p>
              It offers global coverage, security, and other capabilities
              toorganizations which they need for uninterrupted internet access
              specially for more advanced IoT (Internet of Things) applications
              and automation.
            </p>
            <h3 className="xc__q">
              An end-to-end, fully managed Global wireless Connects
            </h3>
            We have relations with carriers and managed services partners in more than <strong>183 countries</strong> across the world, which gives us unmatched presence to
            provide wireless connects (XC) and LTE solutions with best SLA in the
            segment.
            <h3 className="xc__q">
              Enabling Network to hard-to-reach locations using SIM's XC
              connectivity
            </h3>
            <ol>
              <li>
                <p>Quick deployments with High-capacity Bandwidth</p>
              </li>
              <li>
                <p>Uninterrupted Connectivity with minimal disruptions</p>
              </li>
              <li>
                <p>SIM device pairing with specific devices.</p>
              </li>
              <li>
                <p>Dedicated portal integration for optimising tariffs.</p>
              </li>
              <li>
                <p>
                  Secure, optimal performance wireless connectivity solutions.
                </p>
              </li>
            </ol>
            <h3 className="xc__q">What makes inte-QT unique.</h3>
            <ol>
              <li>
                <p>Unmatched Fast delivery.</p>
              </li>
              <li>
                <p>Global presence with Local expertise.</p>
              </li>
              <li>
                <p>Proactive monitoring by NSOC team <strong>24*7*365</strong>.</p>
              </li>
              <li>
                <p>Standardized Process</p>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="xc__space"><ConnectivitySolutions /></div>
      <div className="my-5 g-5">
        <h2 className="mt-5 text-center center-header mx-auto">
          We help not one, but many companies.
        </h2>
        <CarouselIndu />
      </div>
      <TestimonialCarousel />
      <GetInTouch />
    </div>
  );
}
