import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import './Legal.scss';

export default function Legal() {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  return (
    <div className="legal">
      <Helmet>
        <title>Legal - inte-QT</title>
        <meta name="title" content="Legal - inte-QT" />
        <meta name="description" content="All our legal information can be found on this page." />
        <link rel="canonical" href="https://www.inte-qt.com/policy-gtc-aup" />
      </Helmet>
      <div className="legal__box">
        <h3 className="text-center">ACCEPTABLE USAGE POLICY (AUP)</h3>

        <p>
          INTELLIGENT QUOTING TECHNOLOGY S.L. has adopted this Acceptable Use Policy to
          define the scope of prohibited activities relating to the use of
          Services leased by customers of INTELLIGENT QUOTING TECHNOLOGY S.L. and their
          users. This Acceptable Use Policy outlines certain types of activity
          that are expressly prohibited by INTELLIGENT QUOTING TECHNOLOGY S.L. and
          partners of b&amp;B to Customers of the INTELLIGENT QUOTING TECHNOLOGY S.L. 
          Partner Network, INTELLIGENT QUOTING TECHNOLOGY S.L. services and related
          products (the "Services"). This Policy is in addition to any
          restrictions or other limitations on use that a user may be subject to
          by virtue of any service agreement or other terms and conditions
          executed between a Customer and INTELLIGENT QUOTING TECHNOLOGY S.L. (“Service
          Agreement”). INTELLIGENT QUOTING TECHNOLOGY S.L. may modify this Policy at any
          time, effective upon posting of the modified policy on this page.
        </p>
        <h6>Definitions</h6>
        <p>
          Please refer to the definitions mentioned on the above section of
          General Terms &amp; Conditions (GTC).
        </p>
        <h6>Unacceptable Use of Service</h6>
        <p>
          Illegal Activity: The Internet Service shall not be used for any
          unlawful activities or in connection with any criminal or civil
          violation. b&amp;B strictly prohibits the use of Services for the
          transmission, distribution, retrieval, or storage of any information
          or data violation of any applicable law or regulation.
        </p>
        <p>In particular, you should not use any services in a way that:</p>
        <ul>
          <li>
            <p>
              Introduction of malicious programs into the network or server.
            </p>
          </li>
          <li>
            <p>
              Attempts to break the security of any computer network or uses.
            </p>
          </li>
          <li>
            <p>
              Miscellaneous activities like advertising, intentionally
              transmitting files containing a computer virus or corrupted data.
            </p>
          </li>
          <li>
            <p>
              Attempting to mail bomb a site with mass amount of email in order
              to flood their server.
            </p>
          </li>
          <li>
            <p>
              Attempts to manipulate or bypass any limitations on the Service by
              any means.
            </p>
          </li>
          <li>
            <p>
              User may not attempt to gain unauthorized access to, or attempt to
              interfere with or compromise the normal functioning, operation or
              security of, any portion of The INTELLIGENT QUOTING TECHNOLOGY S.L. Network
              or the partner network of INTELLIGENT QUOTING TECHNOLOGY S.L.
            </p>
          </li>
          <li>
            <p>
              Users are prohibited from intentionally or negligently add false
              data into the Internet.
            </p>
          </li>
        </ul>
        <p>
          Each INTELLIGENT QUOTING TECHNOLOGY S.L.customer is responsible for the
          activities of its users and, by accepting service from INTELLOGENT QUOTING TECHNOLOGY S.L., 
          is agreeing to ensure that its customers/representatives
          or end-users obey by this Policy.
        </p>
        <p>Violation of INTELLIGENT QUOTING TECHNOLOGY S.L.</p>
        <p>
          Any complaints and notification regarding prohibited use of INTELLIGENT QUOTING TECHNOLOGY S.L. 
          Services or violations of AUP should sent as an email
          to &nbsp;
          <a href="mailto:security.policy@inte-QT.com">
            security.policy@inte-QT.com
          </a>
          &nbsp; If Users engage in conduct while using the Internet Service
          that violates this AUP, INTELLIGENT QUOTING TECHNOLOGY S.L. reserves the right
          to suspend or terminate your Internet Service or User’s access to the
          Internet Service.
        </p>
        <p>
          If you are unsure whether any inspect to use or action is permitted,
          Submit your quires / comments to INTELLIGENT QUOTING TECHNOLOGY S.L. at&nbsp;
          <a href="mailto:security.policy@inte-QT.com">
            security.policy@inte-QT.com
          </a>
        </p>
      </div>
      <div className="legal__box">
        <h3 className="text-center">GENERAL TERMS & CONDITIONS</h3>
        <p>
          These General Terms and Conditions of Supply of Connectivity and
          Managed Services represent b&B’s standard terms and conditions of
          supply and in the absence of fully executed terms of supply at the
          time of Order Form or CSOF acceptance, govern all Order Forms or CSOFs
          for Services to be provided by b&B.
        </p>
        <p>
           <h6>1. DEFINITIONS AND INTERPRETATION RULES</h6>
        </p>
        <p>
          <strong>1.1</strong> The words and phrases in bold fonts mentioned in these General
          Terms and Conditions (GTC) and any Customer Service Order Form or CSOF
          and Confirmation will have the meaning as stated in the definitions
          mentioned below:{' '}
        </p>

        <p>
          AUP or Acceptable Use Policy means b&B’s global policy on usage of the
          Services and applies to all the services in terms of connectivity set
          forth at www.inte-qt.com, to be followed by the purchaser
          which is amended from time to time, a new section of the AUP can be
          found at the footer of the website or be accessed in the below section
          (AUP)
        </p>
        <p>b&B: INTELLIGENT QUOTING TECHNOLOGY S.L. </p>
        <p>CSOF: means Customer Service Order Form or Service Order</p>
        <p>
          Agreement: means these General Terms and Conditions (GTC) generally
          and with respect to any Service provided hereunder, shall include the
          Specific Terms and the CSOF directly in relation to each Service.
        </p>
        <p>
          Affiliate: means, in relation to a Party, any other entity which
          directly or indirectly controls, is controlled by, or is under common
          control with such Party.
        </p>
        <p>
          Applicable Law: means any laws, statutes or ordinances and any
          regulations, rules, practice notes, circulars and any other
          notification issued by any government entity, taxing authority, or
          regulatory authority pursuant to such laws, statutes and ordinances
          that apply to the Services or Parties in any jurisdiction.{' '}
        </p>
        <p>
          Cancellation Charge: means the liquidated damages that may be payable
          by the Customer as described in clause 13.4.
        </p>
        <p>
          NRC: Non Recurring Charges, normally paid at the time of order
          placement.
        </p>
        <p>
          MRC: Monthly Recurring Charges, first MRC normally paid with the NRC
          and the next MRC is paid in advance every month from the date of
          acceptance of circuit.
        </p>
        <p>
          Charges means the charges payable for each Service, as set forth in
          the relevant Order Form or CSOF, including, without limitation, any
          one time fees, recurring, usage, rental, or other fees, or other
          charges payable by Customer in relation to a Service pursuant to the
          terms of this Agreement.
        </p>
        <p>
          Consequential Loss: means any loss of profit, loss of goodwill, loss
          of production, loss of business, loss of opportunity, business
          interruption, loss of revenue, loss of contract, loss of anticipated
          savings, loss or corruption of data or loss of privacy of
          communications; and any consequential, special, indirect, exemplary or
          punitive damages, of any nature.
        </p>

        <p>
          {' '}
          Customer Equipment: means all hardware, software and consumables
          dedicated for the provision of the Services but owned, licensed or
          controlled by the Customer, a Customer Affiliate or a third party
          agent of the Customer.
        </p>
        <p>
          Customer Requested Target Completion Date: means the date on which the
          Customer requests that the Service be provided and which date is
          specified in the applicable Order Form or CSOF.
        </p>

        <p>
          Effective Date: means the date set forth above, or, if no date is set
          forth, then the date of the first Customer Order Form or CSOF
          hereunder.
        </p>
        <p>
          Firm Order Commitment Date or FOC Date: means the date on which the
          Customer is intended to be able to use the Service and which date
          shall be specified in writing by b&B to the Customer via email or
          letter as the FOC Date after the site survey and second phase of the
          delivery has been completed.
        </p>
        <p>
          Force Majeure Event: means an event outside the reasonable control of
          a Party, including, without limitation, industrial disputes of any
          kind, war declared or undeclared, blockade, disturbance, a natural
          disaster such as lightning, earthquake, storm, flood, explosion or
          meteor, fire, epidemics, law or any power lawfully exercised by a
          government agency, any change in any Applicable Law, inability or
          delay in granting governmental or other approvals, consents, permits,
          licenses or authorities, or a telecommunication network outage or
          degradation.
        </p>
        <p>GTC: means General Terms and Conditions</p>
        <p>
          Intellectual Property Rights: means all intellectual property rights
          subsisting throughout the world conferred under statute, common law
          and equity, including, without limitation, patents, copyrights,
          trademarks.
        </p>
        <p>
          inte-QT or Inte-QT: means INTELLIGENT QUOTING TECHNOLOGY S.L.
        </p>
        <p>
          Interest Rate: means 3.5% per month or, if such amount is not
          permitted by governing law, the highest rate permitted by law.
        </p>
        <p>
          Loss: includes any loss, costs, damages, expense, liability and charge
          incurred by a Party, including Consequential Loss.
        </p>
        <p>
          Minimum Contract Period: means the fixed period during which Customer
          agrees to pay for the Services, commencing on the Service Commencement
          Date, and as used herein, refers to both the initial term of an Order
          Form or CSOF or any renewal term of the same Order Form or CSOF.
        </p>
        <p>
          Network: means the telecommunications network owned or operated by b&B
          or its Third Party Supplier and used in connection with providing the
          Services and all facilities and associated equipment used in, or in
          connection with, that network, but excluding the Customer Equipment.
        </p>
        <p>NSOC: means b&B’s Network and Security Operations Center</p>
        <p>
          Order Form or CSOF (Customer Service Order Form): means a written or
          electronic application (in a form approved by b&B) made by the
          Customer requesting provision of one or more Services or modifications
          to one or more Services.
        </p>
        <p>Parties: means b&B and Customer collectively.</p>
        <p>Party: means b&B or Customer.</p>
        <p>
          GVNO Portal: means an Online portal for Customers in order to access
          real time quotes, order progress data and performance statistics{' '}
        </p>
        <p>
          Customer Premises: means the location or locations occupied by
          Customer or its end user to which a Service is delivered, as indicated
          in an Order Form.
        </p>
        <p>
          Service: means a service supplied by b&B to the Customer under this
          Agreement pursuant to an Order Form or CSOF, and which service is more
          fully described in the Specific Terms.
        </p>
        <p>
          Customer Service Commencement Date: means the date on which b&B
          delivers the Service to Customer pursuant to clause 2.2.
        </p>
        <p>
          Customer Service Commencement Notice or CSCN: means a written or
          electronic notice from b&B to Customer stating that the applicable
          Service is operational.
        </p>
        <p>
          Customer Service Demarcation Point: means the point where b&B’s
          Service, as described in the Order Form or CSOF or CSOF, ends.{' '}
        </p>
        <p>
          Customer Premise Equipment: means the equipment (including any
          software contained in that equipment) installed at a Customer Premises
          and owned or controlled by b&B or a Third Party Supplier, which is not
          Customer Equipment and which is used in connection with the provision
          of Services to Customer.
        </p>
        <p>
          Service Levels: means, where applicable to a Service, the committed
          levels of service in accordance with which b&B will use its reasonable
          endeavours to provide that Service, as specified in the Specific
          Terms.
        </p>
        <p>
          Specific Terms: means the schedule that sets forth the specific terms
          applicable to a particular Service.
        </p>
        <p>
          Tax: means any income tax, withholding tax, VAT, business tax, sales
          tax, turnover tax, excise tax, tariff, levies, impost, deduction,
          charge, duties and any other similar liabilities that are imposed on
          any amount payable under this Agreement, including penalty interest
          and other additions to such liabilities imposed by any taxing
          authority in any jurisdiction, except for liabilities imposed by
          reason of b&B carrying on a business in a jurisdiction or being
          incorporated in that jurisdiction.
        </p>
        <p>
          Third Party Supplier: means a service provider, including an
          Affiliate, from whom b&B procures services or service components in
          order to provide the Services to the Customer.
        </p>

        <p><strong>1.2 </strong>The Specific Terms and Order Form or CSOF(s) form a part of this Agreement and any reference to the Agreement includes the Specific Terms and Order Form or CSOF. In the event of a conflict among these General Terms and Conditions, the Specific Terms, and the Order Form or CSOF, the conflict will be resolved in the following order of precedence: the Order Form or CSOF, the Specific Terms relevant to that Service and these General Terms and Conditions.</p>

<p><strong>1.3</strong> The Agreement shall be deemed drafted equally by both Parties. Its language shall be construed as a whole and according to its fair meaning. The headings in this Agreement are only for convenience and are not intended to affect construction or interpretation. Any references to articles, clauses or paragraphs are to those parts of this Agreement, unless the context clearly indicates to the contrary.Words importing the singular shall include the plural and vice versa and words importing a gender include every gender.</p>

<h6>2. SCOPE OF THE AGREEMENT AND SERVICES</h6>

<p><strong>2.1</strong> b&B shall provide the Services with the care and skill of a competent telecommunications or managed service provider, in accordance with the terms of the Agreement and any and all Applicable Laws up to the Customer Service Demarcation Point. In respect of any jurisdiction in which b&B is not itself permitted by Applicable Law to provide the Services, b&B shall arrange for the provision of any such Services by a licensed Third Party Supplier, provided that, b&B shall remain liable to the Customer for the performance of the Services and the conduct of any such Third Party Supplier. The Customer is responsible for ensuring that the End-Customer complies with all local regulatory provisions.</p>
<p><strong>2.2</strong> All new Services shall be subject to acceptance by Customer on the date of b&B’s CSCN. Though the billing shall start on the date of delivery but in case the customer opens ticket at b&B NSOC in 3 days of CSCN and the NSOC declares a service issue due to b&B or b&B’s third party supplier then this period will be termed as acceptance period. The service when confirmed stable will on that date be declared as the new date of delivery. Prior to the end of the Acceptance Period of 3 days, Customer shall provide b&B Global with a written notice of any deficiencies in the Service, as measured against the applicable Service Levels set out in the Specific Terms or service levels mentioned in the Order Form or CSOF (s).</p>
<p><strong>2.3</strong> These GTC shall govern any and all Order Form or CSOFs entered into between the Parties, between a Party and the other Party’s Affiliate, or between the Affiliates of the Parties. In each such case, unless otherwise agreed in writing, neither b&B nor Customer shall be liable for the obligations undertaken by an Affiliate. In the case of Customer Affiliates, where Customer has not guaranteed the performance of its Affiliate, b&B or its Affiliate shall be entitled to apply credit guidelines to such Customer Affiliate and impose credit terms appropriate to that Customer Affiliate. With respect to an Order Form or CSOF executed by a Customer Affiliate, all references to “Customer” in this Agreement will mean such Customer Affiliate.</p>

<h6>3. TERM OF GTC</h6>

<p><strong>3.1 </strong>These GTC shall come into force on the Effective Date and shall continue until terminated by either Party on not less than ninety (90) days prior written notice to the other Party. Except as permitted by Article 12 below, the termination of this Agreement shall not operate to terminate any existing, accepted Order Form or CSOF and the terms of this Agreement shall continue to apply to each Order Form or CSOF until the Order Form or CSOF terminates or expires.</p>
<p><strong>3.2</strong> An Order Form or CSOF shall become effective and binding on the Parties on the date it is accepted by b&B. Unless otherwise specified in the Specific Terms or the relevant Order Form or CSOF, the Minimum Contract Period for each Service shall remain in effect for twelve (12) months after the applicable Service Commencement Date. Upon expiration of the Minimum Contract Period, the Service term shall automatically renew on the similar stated contract term or a minimum of 1 Year contract term unless and until negotiated and agreed by the Parties in written and signed. Notwithstanding the foregoing, in the event a Party terminates a Service without cause, b&B will continue to provide, and Customer shall be obligated to pay for such Services through the end of the calendar month in which the notice period expires.</p>

<h6>4. CUSTOMER’S OBLIGATIONS</h6>

<p>The Customer shall, at its own expense, provide the proper facilities and resources for installation, maintenance, parking and operation of the Services, including, without limitation, any Customer Equipment necessary to permit Customer to make use of the Services, any services, including, without limitation, local loops or cross-connects not included in the Service being provided by b&B (as set forth in the Specific Terms or Order Form or CSOF), obtaining and maintaining any required licences or permits, and ensuring that the Services are used, whether by Customer or its end users, in accordance with the terms of this Agreement and any Applicable Law. In addition, Customer shall, at its own expense, be responsible to connect to b&B at the Service Demarcation Point.</p>

<h6>5. MANAGED CONNECT INDIA</h6>

<p>Connectivity and other managed Services in India (collectively referred as “Managed Connect India”) shall be provided to the Customer under additional conditions of this clause.</p>
<p>Customer acknowledges and agrees that Managed Connect India shall be provided by b&B Global in alliance with b&B Global’s managed services licensed partner in India. Managed Connect India are connectivity services provided solely within India. In this respect, the customer acknowledges that b&B Global is acting as an agent of the partner in India. In addition to the normal Order Form or CSOF, Managed Connect India will require the customer and their end customer who is an entity based out of India to fill additional documents as well as provide certain documented proofs. These documents will include Letter of Authorization, Letter of Understanding, Certificate of Incorporation, Photo IDs, Company PAN or Tax Card, Address Proofs and No Object certificates. These documents and proofs will be submitted to the partner in India for their compliance with the Indian authorities.</p>

<h6>6. CHARGES AND TAXES</h6>


<p>  <strong>6.1</strong> Calculation of Charges and Invoicing</p>
<ul>
  <li>  <p>(a) Fixed or recurring Charges are billed monthly in advance and in some circumstances variable (usage-based) Charges shall be billed monthly in arrears. NRC or One-time Charges and installation Charges will be invoiced on acceptance of the relevant Order Form or CSOF. In addition to NRC, First MRC will also be charged on acceptance of the relevant Order Form or CSOF.</p></li>
  <li><p>(b) Customer is responsible for all Charges incurred through the use of the Services provided hereunder. Subject to the dispute procedures set forth herein, b&B’s usage records will be prima facie evidence of the usage as well as availability of a Service and the Charges payable by the Customer.</p></li>
  <li><p>(c) If the calculation for any amount or Charges under this Agreement commences on a date other than the first day of a month or terminates on a date other than the last day of a month, the relevant amount or Charges due for the partial month shall be calculated pro-rata, on the basis of the actual number of days in the relevant month.</p></li>
  <li><p>(d) Where b&B incurs costs associated with Customer-initiated delays in the FOC Date after acceptance of the Order Form or CSOF, Customer shall be liable for any such costs.</p></li>
  <li><p>(e) Where Customer requests b&B to dispatch personnel to Customer’s Premises in connection with a Service failure, Customer shall be liable for the costs of such service call where the failure is found to be in the Customer Equipment, or any other equipment, software, services, or facility not provided by b&B.</p></li>
</ul>


<p><strong>6.2</strong> Variation of Charges and Currency Variation</p>
<p>The Charges set forth in the Order Form or CSOF are fixed during the Minimum Contract Period, provided that, b&B may pass through to Customer, without mark up, any additional fees or costs or surcharges resulting from a change in regulation, Tax, currency exchange rate hike or otherwise imposed by any regulatory or governmental body on the Services. The commercials of the CSOF provided outside the region where Euro is not the local currency but are also mentioned in Euro. The currency exchange calculation is done on the day of commercial proposal submission. At the time of the service order raised, in case the variation of the currency is higher or equal to 5%, then the commercial figures in the service order will be adjusted.</p>

<p><strong>6.3</strong> Payment</p>
<ul>
  <li><p>(a) Subject to paragraph (b) below, the Customer must pay invoices for one-time Charges and installation Charges prior to the delivery of Service i.e. acceptance of the Order Form or CSOF, First MRC will also be charged on acceptance of the relevant Order Form or CSOF and each invoice for recurring and variable Charges within thirty (30) days (“Credit Period”) of the date of the invoice, unless the Order Form or CSOF provides a different Credit Period (“Due Date”). Payment shall be made in Euro or the currency set forth in the Order Form or CSOF to the address provided in the related invoice without set off, deduction, or counterclaim for any amount (including, without limitation, Taxes) in the manner agreed by the Parties. Notwithstanding the foregoing, in the event Customer pays the Charges in a currency other than the currency indicated on the invoice, Customer shall be responsible for any currency exchange loss suffered by b&B so that b&B receives an amount equal to the Charges invoiced.</p></li>
  <li><p>(b) Customer must present any disputes regarding the correctness of an invoice within seven (07) days of the date of the relevant invoice or such dispute shall be deemed waived. Customer shall provide b&B with documentation that demonstrates the improper charge. To the extent that Customer has raised a bona fide dispute within the agreed period for raising disputes, Customer may withhold the disputed portion, provided the remaining payment has been received by b&B by the Due Date. b&B and the Customer shall work together in good faith to resolve any bona fide invoice enquiry or dispute notified to b&B under this clause 6.3(b) in accordance with Article 15.</p></li>
  <li><p>(c) If any payment due by the Customer to b&B that has not been disputed pursuant to clause 6.3(b) is not received by the Due Date then b&B shall be entitled, in addition to any other rights it may have under the terms of this Agreement or at law, to (i) charge and Customer shall be liable for: daily interest on outstanding amounts, at the Interest Rate from the Due Date until paid in full, and any and all costs of collection incurred by b&B, including, without limitation, legal costs, as a result of Customer’s failure to pay; and (ii) offset such Charges, costs and interest against any amounts that b&B or its Affiliates owe to Customer or its Affiliates pursuant to any other agreement between the Parties.</p></li>
</ul>

<p><strong>6.4</strong> Taxes</p>

<p>The Charges are exclusive of any Taxes. The Customer agrees to pay all Taxes which may be imposed by any taxing authority in relation to any amount payable under this Agreement whether existing on the Effective Date or coming into effect at any later time. If the Customer is required by Applicable Law to deduct any Taxes or make a withholding from any amount payable under this Agreement then, notwithstanding anything to the contrary contained in this Agreement, the Customer shall ensure that the deduction or withholding does not exceed the minimum amount required by law and the gross amount payable by the Customer to b&B shall be increased so that, after any such deduction or withholding for Taxes, b&B receives an amount equal to the sum it would have received had no such deduction or withholding been made, and the Customer shall make timely payment of the amount withheld (before penalties attach thereto or interest accrues thereon) to the relevant taxing authority and promptly provide to b&B acceptable evidence of such payments. In the event Customer is exempt from payment of certain Taxes, it shall provide to b&B a copy of a valid tax exemption certificate or other equivalent information acceptable to the relevant taxing authority. In such instance, b&B shall not charge such exempt Taxes, provided such certification is valid and current.</p>

<p><strong>6.5</strong> Suspension or Termination of the Services If payment in full for Services performed under any Service Order (other than for charges validly disputed by Customer in good faith) is not received by b&B within thirty (30) days after the invoice date, b&B shall have the right to take the following actions: (i) no earlier than forty five (45) days after the invoice date refuse new Service Order(s); (ii) no earlier than sixty (60) days after the invoice date, mail notice of payment default with fifteen (15) days to cure; (iii) if payment default is not cured, no earlier than seventy five (75) days after the invoice date, place on site denial and mail notice of power termination with ten (10) days to cure; and, (iv) if payment default is still not cured by the deadline, terminate Service. Following payment, b&B shall reinstate the account provided that Customer furnishes to b&B satisfactory assurance of its ability to pay for the Services. Failure by Customer to pay for such Services within five (5) days after power termination shall be deemed to constitute a termination of the Services. No cancellation or termination under this provision shall relieve Customer from its obligations to pay for Services under any Service Order not so canceled or terminated.</p>

<h6>7. SECURITY</h6>

<p><strong>7.1</strong> Customer shall be subject to b&B’s initial and on-going credit policies and procedures. b&B reserves the right to withhold initiation or full implementation of any new Services pending satisfactory credit review and approval thereof, which may be conditioned upon terms specified, including, but not limited to, security for payments due hereunder in the form of a cash deposit, guarantee or irrevocable letter of credit (“Security”). In addition, in the event that (a) Customer experiences a material, adverse change to its financial position that, in b&B’s reasonable commercial judgment, impacts Customer’s creditworthiness; (b) Customer’s actual usage exceeds the estimated usage on which any initial or existing credit amount was determined, or (c) b&B is required to issue a notice to Customer under clause 13.3(a) below three (3) times in any twelve (12) month period, then b&B reserves the right to require the Customer to increase the Security. If Customer fails to provide the Security within ten (10) days of b&B’s written demand, then b&B may suspend Services without liability to Customer of any kind until such Security is received.</p>
<p><strong>7.2</strong> The Customer authorizes b&B, without prejudice to any other rights and remedies available to b&B, to apply any Security against any past due, undisputed, outstanding Charges. The existence of any type of Security does not affect any right of b&B to suspend, cancel or terminate this Agreement for non-payment by the Customer of the Charges.</p>
<p><strong>7.3</strong> Any Security in the form of a deposit or other advance payment shall, after termination of this Agreement, be refunded to the Customer without any interest and less any amounts deducted by b&B in accordance with clause 7.2.</p>
<h6>
  8. EQUIPMENT
</h6>

<p><strong>8.1 </strong>Customer Premise Equipment</p>
<ul>
  <li><p>(a) All ownership interest in Customer Service Equipment shall at all times remain with b&B or a Third Party Supplier, as the case may be. Except where resulting from the negligence or wilful misconduct of b&B or its subcontractors, Customer shall be liable for any damage to Customer Premise Equipment installed on Customer’s Premises, including, without limitation, losses due to vandalism or theft. Customer shall keep the Customer Premise Equipment free and clear of any and all liens and shall not use the Customer Premise Equipment for any purpose or in any manner other than as approved by b&B in written. Any Customer Premise Equipment in the care and custody of Customer shall be returned to b&B in good working order, reasonable wear and tear excepted, within thirty (30) days after termination of this Agreement or the Service to which the Customer Premise Equipment relates.</p></li>
  <li><p>(b) The Customer shall ensure that such Customer Premise Equipment is not removed, relocated, modified, interfered with, or attached to other equipment without the prior written authorization of b&B or as expressly authorized by the terms of this Agreement.</p></li>
  <li><p>(c) b&B shall be responsible to repair or replace, at its sole discretion and expense, any faulty or non-operational Customer Premise Equipment located at the Customer Premises, except where the cause of the failure of the Customer Premise Equipment is due to Customer’s non-compliance with clauses 8.1(a) or 8.1(b) above.</p></li>
  <li><p>(d) The Customer is responsible for and will bear the costs associated with (i) customs clearance; (ii) any Taxes or duties of whatever nature incurred in relation to the importation of Customer Premise Equipment into Customer’s Premises; and (iii) Taxes or duties associated with the presence of Service Equipment at Customer’s Premises.</p></li>
</ul>





<p><strong>8.2</strong> Customer Equipment</p>
<ul>
  <li>
    <p>(a) Where b&B has sold Customer equipment to be used in connection with the provision of Services hereunder, or where b&B has otherwise notified Customer in writing that Customer must obtain certain equipment to facilitate the provision of the Services, then such equipment will be deemed to be Customer Equipment. Customer shall ensure that Customer Equipment is wholly dedicated to the provision of the Services and is configured as required by b&B from time to time. Where required by b&B, Customer will also ensure that b&B can dial remotely into or gain access to such Customer Equipment to ensure the proper provision of the Services. Save as otherwise set out in this Agreement, Customer will be expected to maintain/service Customer Equipment in accordance with the manufacturer’s instructions and any other reasonable instructions from b&B, and shall ensure that b&B is appropriately licensed to use such Customer Equipment as reasonably anticipated by this Section.</p>
  </li>
  <li>
    <p>(b) Save as otherwise expressly set out in an Order Form or CSOF, b&B is not responsible for the installation, maintenance, compatibility or performance of any Customer Equipment. (c) If any Customer Equipment impairs a Service, the Customer remains liable for the Charges.</p>
  </li>
  <li>
    <p>(d) If any Customer Equipment is likely to cause hazard or an impairment of a Service, the Customer shall promptly eliminate such likelihood at b&B’s request.</p>
  </li>
  <li>
    <p>(e) If the Customer provides any router to interface with a Service, the Customer must cooperate with b&B in configuring and managing such router.</p>
  </li>
</ul>




<p><strong>8.3</strong> Sale of Equipment</p>
<p>Customer acknowledges that b&B is not an equipment manufacturer and where it offers to procure equipment on the Customer’s behalf, b&B’s sole liability with respect thereto shall be to pass through to Customer any rights and remedies offered by the manufacturer.</p>

<h6>9. CUSTOMER PREMISES</h6>

<p><strong>9.1</strong> b&B’s Access Rights</p>
Where b&B has placed Customer Premise Equipment on the Customer’s Premises, the Customer shall ensure that b&B and its representatives and agents have access to the Premises at all times in the case of emergency and otherwise at reasonable times to be agreed with the Customer to carry out any of its obligations with respect to the Customer Premise Equipment, subject always to Customer’s reasonable security requirements. Notwithstanding the foregoing, where Customer delays, restricts, or otherwise denies b&B reasonable access to the Premises, b&B shall not be liable for, and Customer shall not be entitled to, service credits or other liability with respect to any interruption of Service that, but for such delay, restriction, or denial, might have been avoided.
<p><strong>9.2</strong> Environment Specifications</p>
<p>In the event that Customer is required to house Customer Premise Equipment at its Premises, Customer shall be responsible for providing a suitable and secure environment, in compliance with the equipment manufacturer requirements, free from environmental hazards for any such Customer Premise Equipment. Customer shall be responsible for external surveillance, appropriate and redundant power supply, parking and fire, flood, lightning and theft protection.</p>

<h6>10. INDEMNITY</h6>

<p><strong>10.1</strong> Indemnification</p>
<p>Except to the extent attributable to the gross negligence or willful misconduct of Customer, b&B shall indemnify and hold Customer harmless from and against any and all loss, liability, damage and expense (including reasonable attorneys’ fees) arising out of any demand, claim, suit or judgment for damages to any property or injury to or death of any person which may arise out of or be caused by any act or omission of b&B.</p>
<p>Except to the extent attributable to the gross negligence or willful misconduct of b&B, Customer shall indemnify and hold b&B harmless from and against any and all loss, liability, damage and expense (including reasonable attorneys’ fees) arising out of any demand, claim, suit or judgment for damages to any property or injury to or death of any person which may arise out of or be caused by any act or omission of Customer.</p>

<h6>11. LIABILITY AND DISCLAIMER OF WARRANTIES</h6>

<p><strong>11.1</strong> Limitation of Liability</p>
<ul>
  <li>
    <p>(a) Nothing in this Agreement shall be construed as limiting or restricting a Party’s liability for death, or personal injury resulting from its negligence, breach of confidentiality, fraud, or the obligation to indemnify the other Party hereunder.</p>
  </li>
  <li>
    <p>(b) The Customer acknowledges that the Services may not be fault free. Where in relation to any Service the Specific Terms relevant to that Service provides for a system of rebates or credits against Charges, such rebates or credits shall be the exclusive remedy of Customer in respect of such failure.</p>
  </li>
  <li>
    <p>(c) Subject to paragraph (a) above, in no event shall a Party be liable to the other Party for any Consequential Loss, regardless of the cause of action and whether or not such Consequential Loss was foreseeable, or a Party was advised of the possibility of such Consequential Loss.</p>
  </li>
  <li>
    <p>(d) Subject to paragraph (a) above, a Party’s liability to the other Party for any and all damages arising out of a Party’s breach of this Agreement, regardless of the cause of action, shall be limited to direct, proven damages and shall not exceed the amount payable by Customer to b&B for the Service(s) to which the damages relate during the twelve (12) month period immediately preceding the occurrence that led to or caused the damages.</p>
  </li>
  <li>
    <p>(e) In no event shall b&B’s Third Party Suppliers be liable to Customer for any Loss arising out of the provision of Services hereunder and Customer hereby waives the right to make a claim against any such supplier, except to the extent that Customer’s cause of action is unrelated to the provision of Services hereunder.</p>
  </li>
  <li>
    <p>(f) Each of the provisions of this clause 11.1 is to be construed as a separate provision applying and surviving even if for any reason one or more of the other of the said provisions is held inapplicable or unreasonable in any circumstance. The provisions of this clause 11.1 shall apply regardless of the nature of the claim asserted; whether in contract, tort, statutory duty, strict liability or otherwise.</p>
  </li>
</ul>






<p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL EITHER PARTY, ITS AFFILIATES, SUBSIDIARIES, EMPLOYEES, OFFICERS, DIRECTORS, CONTRACTORS, AGENTS AND OTHER REPRESENTATIVES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OR LOST OR IMPUTED PROFITS OR ROYALTIES, LOST DATA OR COST OF PROCUREMENT OF SUBSTITUTE SERVICES ARISING FROM OR RELATED TO THE SERVICES OR PERFORMANCE OF ITS OBLIGATIONS HEREUNDER, WHETHER FOR, AMONG OTHER THINGS, BREACH OF WARRANTY OR ANY OBLIGATION ARISING THEREFROM, AND WHETHER LIABILITY IS ASSERTED IN CONTRACT OR TORT (INCLUDING NEGLIGENCE AND STRICT PRODUCT LIABILITY) IRRESPECTIVE OF WHETHER THE OTHER PARTY HAS ADVISED OR HAS BEEN ADVISED OF THE POSSIBILITY OF ANY SUCH LOSS OR DAMAGE. B&B GLOBAL’S LIABILITY HEREUNDER TO CUSTOMER SHALL IN NO EVENT EXCEED AN AMOUNT EQUAL TO THE MONTHLY RECURRING CHARGE PAID BY CUSTOMER FOR THE PARTICULAR SERVICE, OR SERVICE ORDER TO WHICH THE CLAIM PERTAINS. THE PARTIES HEREBY WAIVE ANY CLAIM THAT THESE EXCLUSIONS DEPRIVE THEM OF AN ADEQUATE REMEDY OR CAUSE THIS AGREEMENT TO FAIL OF ITS ESSENTIAL PURPOSE.</p>
<p><strong>11.2</strong> Disclaimer of Warranties</p>
<ul>
  <li>
    <p><strong>A.</strong> CUSTOMER ASSUMES TOTAL RESPONSIBILITY AND RISK FOR CUSTOMER’S USE AND ITS END USERS’ USE OF THE SERVICES PROVIDED BY b&B. CUSTOMER ACKNOWLEDGES THAT THE INTERNET SERVICE (1) CONTAINS MATERIALS SOME OF WHICH ARE SEXUALLY EXPLICIT OR MAY BE OFFENSIVE TO SOME PEOPLE AND (2) IS ACCESSIBLE BY PERSONS WHO MAY ATTEMPT TO BREACH THE SECURITY OF b&B’S AND/OR CUSTOMER’S NETWORK FACILITIES. b&B HAS NO CONTROL OVER AND EXPRESSLY DISCLAIMS ANY LIABILITY OR RESPONSIBILITY WHATSOEVER FOR THE CONTENT OF MATERIALS TRANSMITTED OVER THE INTERNET SERVICE, SERVICE INTERRUPTIONS ATTRIBUTABLE TO CUSTOMER’S NETWORK, ANY CUSTOMER EQUIPMENT FAILURES, OR ANY OTHER SUCH CAUSES, AND CUSTOMER AND CUSTOMER’S END USERS ACCESS THE SERVICES AT THEIR OWN RISK.</p>
  </li>
  <li>
    <p><strong>B.</strong> EXCEPT AS SPECIFICALLY SET FORTH HEREIN OR IN ANY SERVICE ORDER, THE SERVICES PROVIDED BY b&B ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE, NONINFRINGEMENT OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. NO ADVICE OR INFORMATION GIVEN BY b&B, ITS AFFILIATES OR ITS CONTRACTORS OR THEIR RESPECTIVE EMPLOYEES SHALL CREATE A WARRANTY.</p>
  </li>
</ul>



<h6>12. SERVICE SUSPENSION</h6>

<p><strong>12.1</strong> Maintenance</p>
<p>b&B may suspend Services during the maintenance or upgrade of its Third Party Supplier Network. In the event of routine, planned maintenance, b&B will provide Customer with reasonable prior written notice. For emergency maintenance, b&B will provide as much notice as is practical under the circumstances. In all cases, b&B will work with Customer to minimize disruptions to the Service.</p>
<p><strong>12.2</strong> Network Security</p>
<p>b&B may suspend Service(s) where, in b&B’s sole discretion, Customer’s use of such Service results, or is likely to result in imminent risk to the integrity or operation of b&B’s Network or that of its Third Party Suppliers or other end users. In addition, b&B may suspend one or more Services where use of the Services appears to be fraudulent or otherwise in violation of this Agreement or Applicable Law or Local Regulations. In all such cases, b&B will provide Customer with as much notice as is practical under the circumstances and shall cooperate with Customer to suspend only so much of the Service(s) as may be necessary to remove the risk and only for so long as the risk remains.</p>

<h6>13. TERMINATION OF AGREEMENT OR SERVICE TERMINATION</h6>

<p>Either party in accordance with the following may terminate this Agreement and any Service Order(s):</p>
<p><strong>13.1</strong> Default</p>
<p>If either party is in breach of this Agreement or any CSOF, (other than for failure by Customer to pay any undisputed amounts due under any CSOF which are covered under Clause 6.5 above), in order for such breach to constitute a default, the other party shall give the breaching party notice in writing of such breach. If the breach has not been cured to the non-breaching party’s reasonable satisfaction within thirty (30) days of such written notice (the “Notice Period”), then the non-breaching party may terminate the applicable CSOF effective at the end of the Notice Period immediately upon written notice to the breaching party and without penalty. No termination for breach pursuant to this Section shall constitute or permit termination of any portions of this Agreement or any CSOF not breached or affected by such breach, provided, however, that b&B may revoke or suspend this Agreement in its entirety if Customer (or any of its affiliate companies) is in material breach of any other agreement with b&B or (or any of its affiliate companies).</p>
<p><strong>13.2</strong> Cause</p>
<p>This Agreement may be terminated for cause by either party in the event that the other party: (i) shall become insolvent; (ii) admits in writing its inability to pay its debts; (iii) ceases to function as a going concern or to conduct its operations in the normal course of business; or (iv) violates AUP.</p>
<p><strong>13.3</strong> Remedies</p>
<p>In the event of termination for breach or cause, the non-breaching party shall have the right to pursue any or all remedies available to it at law or in equity.</p>
<p><strong>13.4 </strong>Early Termination</p>
<p>If Customer terminates or cancels service under any CSOF for reason other than b&B’s breach prior to its agreed expiration date, Customer will pay b&B a termination charge (as liquidated damages and not as a penalty) including all non-recurring, disconnection or termination charges reasonably incurred by b&B on Customer’s behalf, and the full amount of the remaining monthly charges for the remainder of the Term. All termination charges shall be due and payable within five (5) days after the effective date of termination of the CSOF.</p>
<p>No termination pursuant to this Section shall relieve either party of any of its obligations under this Agreement or any CSOF intended to continue, including, without limitation, the obligation to pay for Service prior to such termination.</p>
<p><strong>13.5</strong> Survival</p>
<ul>
  <li>
    <p>(a) Termination of this Agreement will not extinguish or otherwise affect any rights of any Party against the other, which accrued before the date of termination of this Agreement.</p>
  </li>
  <li>
    <p>(b) To the extent possible, Articles 6 (Charges and Taxes), 8 (Equipment), 10 (Indemnity), 11 (Liability and Disclaimer of Warranties), 13 (Termination and Suspension), 14 (Confidentiality and Intellectual Property Rights) and any other clause of the Agreement, which by its nature is intended to survive termination of this Agreement, will survive termination of this Agreement.</p>
  </li>
</ul>



<h6>14. CONFIDENTIALITY AND INTELLECTUAL PROPERTY RIGHTS</h6>

<p><strong>14.1</strong> Confidentiality</p>
<ul>
  <li>
    <p>(a) Each Party agrees that any and all written and/or oral information of any kind relating to this Agreement or disclosed by one Party (the “disclosing Party”) to the other Party (the “receiving Party”) pursuant to this Agreement, prior to it, or in the course of performance of it, whether or not such information is identified as being confidential at the time of disclosure (collectively, “Confidential Information”), shall remain the property of the disclosing Party, shall be treated as confidential by the receiving Party and shall be used solely for the purpose for which it is supplied, subject to the remaining provisions of this clause 14.1. The obligations of the Parties with respect to the non-disclosure and non-use of such Confidential Information shall not apply in relation to information already in the public domain or which becomes so through no fault of the receiving Party or is approved for release by prior written authorization of the disclosing Party.</p>
  </li>
  <li>
    <p>(b) Save as otherwise expressly permitted by this clause 14.1, the receiving Party shall not divulge Confidential Information other than to those employees (including employees of Affiliates) and professional advisors, who are directly involved in the performance of this Agreement, and shall ensure that such persons are aware of and comply with these obligations as to confidentiality.</p>
  </li>
  <li>
    <p>(c) The receiving Party may disclose Confidential Information pursuant to judicial or governmental request, requirement or order, where lawfully required by such authorized institutions. The receiving Party shall, however, to the extent permissible, give the disclosing Party sufficient prior notice to contest such request, requirement or order and disclose strictly only that part of the Confidential Information that has been requested.</p>
  </li>
  <li>
    <p>(d) Upon the request of the disclosing Party or upon termination of the Agreement, the receiving Party shall return or destroy the Confidential Information provided to the receiving Party. Subject to the provisions in this clause 14.1, these obligations of confidentiality shall continue for a period of two (2) years after termination of this Agreement.</p>
  </li>
</ul>




<p><strong>14.2</strong> Intellectual Property Rights</p>
<p>Neither party shall use any copyrights, patents, trade secrets, software, trademarks, trade names, service marks, license rights or other intellectual property rights (collectively “Intellectual Property”) owned, licensed or used by the other party. Notwithstanding the foregoing, each party may use the other party’s name and logo in any and all media, whether now known or hereafter developed (including Internet pages) for the sole purpose of listing one or more representative customers or vendors or to issue press releases (each, a “Permitted Use”). Upon expiration or termination of this Agreement, or any affected CSOF, all Permitted Uses shall be discontinued, and any Intellectual Property, including all copies thereof, shall be returned to the other party. Each party hereby disclaims any right, title and interest in any Intellectual Property, owned, used or licensed by the other party.</p>

<h6>15. RESOLUTION OF DISPUTE</h6>

<p>The Parties will work together in good faith to amicably settle any dispute or difference that arises between the Parties in respect of any aspect of this Agreement promptly by negotiations between management representatives of the Parties with authority to settle the dispute. In the event that the dispute or difference has not been settled within thirty (30) days of the date that a Party receives written notification of the dispute or difference, then either Party may elect instead to rely on its rights at law, including the right to institute court proceedings. Notwithstanding the foregoing, the dispute resolution procedure described in this Article 15 does not prevent a Party from seeking urgent interlocutory relief.</p>

<h6>16. GOVERNING LAW AND JURISDICTION</h6>

<p>This Agreement and any claims or disputes arising out of, relating to or in connection with it, shall be governed by the laws of Spain. It is the will of the parties to specifically waive their judicial privilege and to submit to arbitration all controversies, matters or incidents between them that might arise with respect to this Contract. </p><p>This arbitration shall be at law and its administration is commended to the Civil and Mercantile Arbitration Court (CIMA), whose Statutes and Procedural Regulations are known by the signatories of this document. The cognizance and the decision of the litigious matters shall be incumbent on a single arbitrator, who shall be appointed from among the members of the Civil and Mercantile Arbitration Court through agreement between the parties at the time that the difference arises or, if this is not achieved, by the President of the Court, in accordance with its own Statutes, with which the parties are likewise familiar. It shall be understood that no agreement exists when within the period of fifteen calendar days from the time of the notification of one party to the other or others, no affirmative response is received from all of them.</p>

<h6>17. inte-QT PORTAL, AUTOMATION TOOL AND DIGITAL ECOSYSTEM</h6>

<p>1. User Credentials and Responsibility:

The customer's password is strictly non-transferable. The customer is prohibited from allowing any third party to use their password and must exclusively use it personally, assuming all associated risks in the event of any password disclosure. It is the sole responsibility of the Customer to monitor the use of its user ID and password ("access codes") for all purposes, including but not limited to ordering from inte-QT’s website/portal. All operations conducted using the customer's password will be deemed the responsibility of the customer. inte-QT shall bear no responsibility for any misuse or fraudulent use of the password.

2. Security Disclaimer:

While using the portal, the customer acknowledges that inte-QT cannot guarantee the absolute security of information transmitted through the website.

3. Changes to Terms and Conditions:

inte-QT reserves the right to modify the terms and conditions at its sole discretion. The customer is responsible for regularly reviewing these terms, and continued use of the portal constitutes acceptance of any revised terms.

4. Suspension/Termination of Access:

inte-QT reserves the right, without notice, to suspend or terminate customer access to the portal, without incurring any liability.

5. Access Register and Dispute Resolution:

inte-QT will maintain a register of customer access and information provided by customers. This information may be used to address issues between the parties or presented to competent authorities in the event of a dispute.

6. Personal Data Processing:

The customer's personal data will be electronically processed and stored by inte-QT in compliance with applicable law. The customer has the right to access, correct, or delete their personal data.

7. Accuracy of Information:

By using the portal or integrating through an application programming interface, the customer acknowledges that inaccurate or missing information may result in incorrect and unenforceable outcomes. inte-QT will not be held responsible for such inaccuracies.

8. Automation Tool Usage and Output:

inte-QT Automation Tool utilizes geographical layers, machine learning, historical data intel, and inte-QT's supplier information for price calculations. The output for addresses or coordinates provided as quotes should be further validated and clarified by inte-QT Pricing and Account interface teams.

9. Acceptance of Terms of Use:

By using this site and/or clicking the "ACCEPT" or “TICK” or “AGREE” or “CREATE” button (or logical equivalent) in an online order or registration form, or otherwise displayed as part of the ordering or registration process, you acknowledge that you have read these Terms of Use, understand them, and agree to be bound by their terms, including all policies and guidelines incorporated by reference in these Terms of Use. This agreement is considered as if you had signed these Terms of Use.

If you are entering into these Terms of Use on behalf of a company or other legal entity, including but not limited to your employer, you represent and warrant that you are duly authorized to bind such entity to these Terms of Use. If you do not have such authority, or if you do not agree to the terms of these Terms of Use, you must not use this site and must not click the "ACCEPT" or “TICK” or “AGREE” or “CREATE” button (or logical equivalent).

You expressly consent to these Terms of Use being formed electronically without the need for a physical signature by either you or us. You agree that a printed version of these Terms of Use and any notice given by us in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to these Terms of Use to the same extent and subject to the same conditions as other business documents and records generated and maintained in printed form by us.

10. Orders and Admissibility:

All orders placed using the access codes or API shall, for all purposes, be deemed to be in writing signed by the Customer. Such orders are admissible and enforceable as other business records in documentary form. The Customer accepts all consequences and legal implications arising from orders placed using their access codes.

11. Ownership:

The Site, along with all information (in text, excel, pdf, graphical, video, and audio forms), images, icons, software, design, applications, and other elements available on or through the Site, are the exclusive property of inte-QT, its Affiliates, and other relevant entities. These assets are protected by both Spanish and international copyright, trademark, and other applicable laws. Your use of the Site does not confer upon you or any other party any ownership or other rights in the Site or its content. You are permitted to use the Site only in accordance with the specific terms outlined in these Terms of Use.

12. Linking and Framing the Site:

Links to the Site without express written permission from inte-QT are strictly prohibited. inte-QT reserves the right to cancel and revoke any granted permission to link to the Site at any time, without notice, and without incurring any liability to you or any other party. Additionally, the framing of the Site or any of its content in any form and by any method is strictly prohibited.

13. Disclaimer of UN Convention:

We explicitly disclaim the applicability of the UN Convention on Contracts for the International Sale of Goods, whether as amended, replaced, or re-enacted from time to time. This disclaimer also extends to any provincial legislation in Spain that enacts or adopts such convention.</p>


<p><strong>Contact Us </strong><a href="mailto:legal@inte-QT.com">legal@inte-QT.com</a></p>
      </div>
    </div>
  );
}
