import React from 'react';
import './CarouselIndu.scss';

export default function CarouselIndu() {
  return (
    <div className="carouselIndu">
      <section className="carouselIndu__track">
        <div className="carousel__container">
          <div className="carousel__item carousel__item-1">
            <h3>Telecom</h3>
          </div>
          <div className="carousel__item carousel__item-2">
            <h3>Airline</h3>
          </div>
          <div className="carousel__item carousel__item-3">
            <h3>Cloud</h3>
          </div>
          <div className="carousel__item carousel__item-4">
            <h3>Pharma</h3>
          </div>
          <div className="carousel__item carousel__item-5">
            <h3>Financial</h3>
          </div>
          <div className="carousel__item carousel__item-6">
            <h3>Events</h3>
          </div>
          <div className="carousel__item carousel__item-7">
            <h3>Energy</h3>
          </div>
          <div className="carousel__item carousel__item-8">
            <h3>Hotels</h3>
          </div>
          <div className="carousel__item carousel__item-1">
            <h3>Telecom</h3>
          </div>
          <div className="carousel__item carousel__item-2">
            <h3>Airline</h3>
          </div>
          <div className="carousel__item carousel__item-3">
            <h3>Cloud</h3>
          </div>
          <div className="carousel__item carousel__item-4">
            <h3>Pharma</h3>
          </div>
          <div className="carousel__item carousel__item-5">
            <h3>Financial</h3>
          </div>
          <div className="carousel__item carousel__item-6">
            <h3>Events</h3>
          </div>
          <div className="carousel__item carousel__item-7">
            <h3>Energy</h3>
          </div>
          <div className="carousel__item carousel__item-8">
            <h3>Hotels</h3>
          </div>
          <div className="carousel__item carousel__item-1">
            <h3>Telecom</h3>
          </div>
          <div className="carousel__item carousel__item-2">
            <h3>Airline</h3>
          </div>
          <div className="carousel__item carousel__item-3">
            <h3>Cloud</h3>
          </div>
          <div className="carousel__item carousel__item-4">
            <h3>Pharma</h3>
          </div>
          <div className="carousel__item carousel__item-5">
            <h3>Financial</h3>
          </div>
          <div className="carousel__item carousel__item-6">
            <h3>Events</h3>
          </div>
          <div className="carousel__item carousel__item-7">
            <h3>Energy</h3>
          </div>
          <div className="carousel__item carousel__item-8">
            <h3>Hotels</h3>
          </div>
        </div>
      </section>
    </div>
  );
}
