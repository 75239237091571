import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import DinamicLink from '../../../components/DinamicLink/DinamicLink';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../Blog.scss';

export default function Blog7() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="blog">
      <Helmet>
        <title>Internet of Things: What is IOT? - inte-QT</title>
        <meta name="title" content="Internet of Things: What is IoT? - inte-QT" />
        <meta
          name="description"
          content="Modern organizations use IOT applications and sensors for everything from optimizing manufacturing and supply chain practices to aiding the development of municipalities through use in smart cities."
        />
        <link rel="canonical" href="https://www.inte-qt.com/blog/7" />
      </Helmet>
      <h1 className="center-header center-header__title mx-auto text-center">
        Internet of Things: What is IOT?
      </h1>
      <div className="container">
        <div className="blog__text" align="justify">
          <div className="text-end mb-5">
            <p className="blogPosts__tags">
              <span className="blue mx-2">Intro</span>/
              <span className="blue mx-2">IOT</span>/
              <span className="blue mx-2">Applications</span>
            </p>
          </div>
          <p>
            By
            <a
              href="https://www.linkedin.com/in/ashish-nankani-017621220/"
              className="blue"
            >
              Ashish Nankani<i className="fab fa-linkedin-in ms-1"></i>
            </a>{' '}
            on March 7th, 2022 · 4 min read
          </p>
          <p>
            The internet of things is driving many of today's most significant
            technology developments.
          </p>

          <p>
            Modern organizations use IOT applications and sensors for everything
            from optimizing manufacturing and supply chain practices to aiding
            the development of municipalities through use in smart cities.
          </p>

          <p>Effective use of IoT technology can create numerous benefits.</p>

          <h2 className="my-5">What was it Without the IOT?</h2>

          <ul>
            <li>
              The internet was used for and consisted of information made by and
              for the people.
            </li>
            <li>We sent messages and called each other.</li>
            <li>We posted and downloaded images, videos and MP3 files.</li>
            <li>These actions were all for our own use.</li>
            <li>
            We need anyone to control the device like if air conditioner is to be turned on someone needs to reach the switch.
            </li>
          </ul>

          <img
            src="https://i.imgur.com/mdrh8gQ.jpg"
            alt="SD-WAN"
            className="blog__img"
          />
          <h2>What is IOT?</h2>
          <p>
            IOT, Internet of things. IOT devices contain sensors and micro-computer capabilities to communicate.
          </p>

          <p>
            We use IOT for communicating one device from another smart device
            using the internet. We can communicate with other devices also using
            the voice command in the devices like Alexa, Google Assistant, Siri (iPhone or any other apple devices), etc.
          </p>

          <h2>How it works?</h2>
          <p>The Smart devices like smart air conditioner, smart Fan and smart geyser, etc, are IOT devices which contains sensors  and minicomputer processors that act on the data collected by sensors via machine learning connected on the internet and other device like smartphone, laptop, Smart watch, etc. </p>
          
          <p>Which can communicate with other smart Devices and executes the command on other device are connected to the other device using the internet sends the action (what to do) in the form of commands which are executed at IOT devices.</p>

          <h2>What we do?</h2>
          <p>inte-QT is a global aggregator in 183+ countries which provide high speed internet for business so that the IOT devices can work smoothly and using the internet we can communicate with other smart devices and control them Using the wireless media.</p>

            <h2 className='mb-3'>Applications of IOT</h2>
            <ol>
              <li className='mb-3'>Smart City</li>
              <li className='mb-3'>Self-driven Cars</li>
              <li className='mb-3'>Smart Homes</li>
              <li className='mb-3'>Farming</li>
              <li className='mb-3'>Wearables</li>
              <li className='mb-3'>Smart Grids</li>
              <li className='mb-3'>Industrial Internet</li>
              <li className='mb-3'>Smart Supply-chain Management</li>
            </ol>


          <h5 className='mb-3'>Smart City</h5>
          <p>
            The use of IOT is growing day by day, there are many services which are
            working with the internet to make the city smart like traffic
            management, waste management, water distribution, bill generation
            electricity management, etc.
          </p>
          <img
            src="https://i.imgur.com/NeX0EOT.jpg"
            alt="SD-WAN"
            className="blog__img"
          />
          <h5 className='mb-3'>Self-driven cars</h5>
          <p>
            The cars use several sensors and embedded systems connected to the
            Cloud and the internet to keep generating data and sending them to
            the Cloud for informed decision-making through Machine Learning.
          </p>
          <p>
            Though it will take a few more years for the technology to evolve
            completely and for countries to amend laws and policies.
          </p>
          <h5 className='mb-3'>Smart Homes</h5>
          <p>
            Smart homes are used for convenience and home security, to the next
            level.
          </p>

          <p>
            For instance, your electricity meter with an IoT device giving you
            insights into your everyday water usage, your set-top box that
            allows you to record shows from remote, Automatic Illumination
            Systems, Advanced Locking Systems, Connected Surveillance Systems
            all fit into this concept of smart homes. As IoT evolves, we can be
            sure that most of the devices will become smarter, enabling enhanced
            home security.
          </p>
            <img
            src="https://i.imgur.com/ETn6zlq.jpg"
            alt="SD-WAN"
            className="blog__img"
          />
          <h5 className='mb-3'>Farming</h5>
          <p>
            The one of the sectors is the farming where the IOT has grown it is
            very beneficial for the farmers. Activities like Drip Irrigation,
            understanding crop patterns, Water Distribution, drones for Farm
            Surveillance, and more.
          </p>
          <p>
            These will allow farmers to come up with a more productive yield and
            take care of the concerns better.
          </p>
          <img
            src="https://i.imgur.com/ZfkqyCp.jpg"
            alt="SD-WAN"
            className="blog__img"
          />
          <h5 className='mb-3'>Wearable</h5>
          <p>
            Wearables are one of the hot topics which can be used for wide range
            of purposes like medical, wellness and fitness and these devices can
            also be used for controlling the other IOT devices
          </p>
          <h5 className='mb-3'>Smart Grids</h5>
          <p>
            Smart grids is useful for reducing the wastage of electricity and
            cost. A future smart grid improves the efficiency, reliability, and
            economics of electricity.
          </p>
          <h5 className='mb-3'>Industrial Grids</h5>
          <p>
            The Industrial Internet of Things consists of interconnected
            sensors, instruments, and other devices connected with computers
            industrial applications like manufacturing, energy management, etc.
          </p>
          <h5 className='mb-3'>Smart supply-chain Management</h5>
          <p>
            The IOT can be used to track the product so that we can know the
            exact location of the product by using the IOT.
          </p>
        </div>

        <div className="blogPosts__box">
          <div className="blogPosts__imgbox me-5">
            <img
              src="https://i.imgur.com/7CgnyPK.jpg"
              alt=""
              className="blogPosts__img"
            />
          </div>
          <p className="blogPosts__tags">
            <span className="blue mx-2">Intro</span>/
            <span className="blue mx-2">Mechanism</span>/
            <span className="blue mx-2">Benefits</span>
          </p>
          <h3 className="blogPosts__title">
          Our Access Makes it STRONG: SD-WAN
          </h3>
          <p className="blogPosts__author">
            {' '}
            By{' '}
            <a
              href="https://www.linkedin.com/in/mohammed-arshad30/"
              className="blue"
            >
              Mohammed Arshad<i className="fab fa-linkedin-in ms-1"></i>
            </a>{' '}
            on October 27th, 2021 · 3 min read
          </p>
          <p>
          SD WAN (Software Defined Wide Area Networking) is an
            Application-Centric Solution, and it refers to the use of software
            to implement and manage the WAN, including virtualisation of routers...
          </p>
          <div className="text-end me-5">
            <DinamicLink url="/blog/6" name="Read more" />
          </div>
        </div>

        <GetInTouch />
      </div>
    </div>
  );
}
