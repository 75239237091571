import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import DinamicLink from '../../../components/DinamicLink/DinamicLink';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../Blog.scss';

export default function Blog6() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="blog">
      <Helmet>
        <title>Our Access Makes it STRONG: SD-WAN - inte-QT</title>
        <meta name="title" content="ADOPTION OF SD-WAN - inte-QT" />
        <meta
          name="description"
          content="SD WAN (Software Defined Wide Area Networking) is an
          Application-Centric Solution, and it refers to the use of software
          to implement and manage the WAN, including virtualisation of routers..."
        />
        <link rel="canonical" href="https://www.inte-qt.com/blog/6" />
      </Helmet>
      <h1 className="center-header center-header__title mx-auto text-center">
        Our Access Makes it STRONG: SD-WAN
      </h1>
      <div className="container">
        <div className="blog__text" align="justify">
          <div className="text-end mb-5">
            <p className="blogPosts__tags">
              <span className="blue mx-2">Intro</span>/
              <span className="blue mx-2">Mechanism</span>/
              <span className="blue mx-2">Benefits</span>
            </p>
          </div>
          <p>
            {' '}
            By{' '}
            <a
              href="https://www.linkedin.com/in/mohammed-arshad30/"
              className="blue"
            >
              Mohammed Arshad<i className="fab fa-linkedin-in ms-1"></i>
            </a>{' '}
            on October 27th, 2021 · 3 min read
          </p>
          <p>We, <a
              href="https://www.linkedin.com/company/bitsandbyteglobal/"
              className="blue"
            >
              inte-QT
            </a> enabler of connectivity and communication solutions for businesses, provide managed internet access services to SD-WAN solution providers. We have partnership in 183+ countries across the globe and effectively providing our services to our customers. We work on requirements in-depth and intelligently to deliver modern, smart and cost-effective connectivity solutions.</p>
            <h2 className="mb-3">What is SD-WAN?</h2>
          <p>
            SD-WAN (Software Defined Wide Area Networking) is an
            Application-Centric Solution, and it refers to the use of software
            to implement and manage the WAN, including virtualisation of routers
            and firewalls (NFV) and techniques to orchestrate the network
            centrally.
          </p>
          <p>
            In today’s world, traditional or static WAN concepts are not suited
            to fulfil the requirements for flexible bandwidths and performance
            parameters of modern business processes and cloud services.{' '}
          </p>
          <p>
            And bandwidth requirements are also increasing, especially for
            applications featuring high-definition video, streaming media, and
            virtualized applications which requires low latency. It can be
            expensive and difficult to expand WAN capability, with corresponding
            difficulties related to network management and troubleshooting.
          </p>
          <p>
            The next generation SD-WAN technology function is to connect the
            various business premises to the same, secure network through the
            internet or a cloud-native private network. It allows enterprises to
            leverage any combination of transport services including MPLS, 5G/
            LTE and broadband internet services to securely connect users to
            applications while delivering the highest levels of application
            performance.
          </p>
          <img src="https://i.imgur.com/7CgnyPK.jpg" alt="SD-WAN" className="blog__img"/>
          <h2>How does SD-WAN work?</h2>
          <p>
            SD-WAN is an overlay technology and it implements a centralized
            controller, acting as a single pane of glass for managing the entire
            solution. It sperate the control and data planes thus management and
            provisioning happen centrally through controller.{' '}
          </p>
          <p>
            Control plane manages the rules for the routing traffic through the
            overlay network hence less processing is needed on the data planes,
            so local edge router on data plane can be focused on actual data
            traffic delivery.{' '}
          </p>
          <p>
            Control layer in SD-WAN architecture do the authentication and
            licence-management for all edge routers and distribute the
            controller orders towards the edge routers and create secure tunnels
            for data transportation across the SD-WAN topology.
          </p>
          <div className="partnerBenefits text-center my-5">
            <h2 className="my-5">The benefits of SD-WAN: </h2>
            <p>
              Reduced <strong className="blue">Cost</strong>
            </p>
            <i className="fas fa-chevron-down my-4 partnerBenefits__arrow"></i>
            <p>
              <strong className="blue">Hybrid WAN </strong>architecture
            </p>
            <i className="fas fa-chevron-down my-4 partnerBenefits__arrow"></i>
            <p>
              Faster <strong className="blue">Provisioning</strong> 
            </p>
            <i className="fas fa-chevron-down my-4 partnerBenefits__arrow"></i>
            <p>
              Improved 
              <strong className="blue"> Application Performance </strong>
            </p>
            <i className="fas fa-chevron-down my-4 partnerBenefits__arrow"></i>
            <p>
              <strong className="blue">Centrally Manage </strong> all devices
            </p>
            <i className="fas fa-chevron-down my-4 partnerBenefits__arrow"></i>
            <p>
              Built-in robust <strong className="blue">Security</strong>
            </p>
            <i className="fas fa-chevron-down my-4 partnerBenefits__arrow"></i>
            <p>
              Real time traffic <strong className="blue">Visibility </strong>   
              across the WAN
            </p>
            <i className="fas fa-chevron-down my-4 partnerBenefits__arrow"></i>
            <p>
              <strong className="blue">Cloud</strong> on ramps
            </p>
            <i className="fas fa-chevron-down my-4 partnerBenefits__arrow"></i>
            <p>
              Performance{' '}
              <strong className="blue">Monitoring </strong> & Network  
              <strong className="blue"> Analytics </strong>
            </p>
          </div>

          
        </div>

        <div className="blogPosts__box">
          <div className="blogPosts__imgbox me-5">
            <img
              src="https://i.imgur.com/Gt6jUPa.jpg"
              alt=""
              className="blogPosts__img"
            />
          </div>
          <p className="blogPosts__tags">
            <span className="blue mx-2">Service Assurance</span>/
            <span className="blue mx-2">SLA%</span>/
            <span className="blue mx-2">MTTR</span>
          </p>
          <h3 className="blogPosts__title">Service Assurance: A Responsibility That Cannot Have A No Show</h3>
          <p className="blogPosts__author">
            {' '}
            By{' '}
            <a
              href="https://www.linkedin.com/in/nikhil-jain-705a6943/"
              className="blue"
            >
              Nikhil Jain<i className="fab fa-linkedin-in ms-1"></i>
            </a>{' '}
            on September 28th, 2021 · 3 min read
          </p>
          <p>
          In the world of Digital Era, it is impossible to live without being connected. Lives and Businesses can’t remain healthy unless Information Technology can deliver...
          </p>
          <div className="text-end me-5">
            <DinamicLink url="/blog/5" name="Read more" />
          </div>
        </div>

        <GetInTouch />
      </div>
    </div>
  );
}
