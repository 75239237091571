import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import './NotFound.scss';

export default function NotFound() {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  return (
    <div className="container-fluid notFound mb-5">
      <Helmet>
        <title>404 Not Found - inte-QT</title>
        <meta name="title" content="404 Not Found - inte-QT" />
        <meta name="description" content="If you are seeing this message it means that you have not chosen the right path, you can go back to the home page or see our sitemap in the footer of this page." />
      </Helmet>
      <h1 className="notFound__title center-header mx-auto text-center">
        Sorry, page not found!
      </h1>
      <p className="notFound__sentence">
        Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
        mistyped the URL? Be sure to check your spelling.
      </p>
      <div className="notFound__container">
        <div>
          <div className="flex-item">
            <a href="/">
              <img
                className="notFound__img"
                src="https://error404.fun/img/illustrations/24@2x.png"
                alt="404"
              />
            </a>
          </div>
          <div className="flex-item">
            <a className="button" href="/">
              Go To Home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
