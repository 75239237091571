import React from 'react';

export default function BigBanner() {
  return (
    <div>
      <img
        src="https://i.imgur.com/o0I3t65.jpg"
        alt="bigbanner-background"
        className="banner-img"
      />
      <h1 className="banner-img__header text-center">
        Your Global Access Enabler in 190+ Countries
      </h1>
    </div>
  );
}
