import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import './Services.scss';
import CarouselDesk from '../../components/CarouselDesk/CarouselDesk';
import ConnectivitySolutions from '../../components/ConnectivitySolutions/ConnectivitySolutions';
import ManagedSolutions from '../../components/ManagedSolutions/ManagedSolutions';
import GetInTouch from '../../components/GetInTouch/GetInTouch';

export default function Services() {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  return (
    <div className="services">
      <Helmet>
        <title>Global Network Connectivity Services - inte-QT</title>
        <meta name="title" content="Global Network Connectivity Services - inte-QT"/>
        <meta property="og:title" content="Global Network Connectivity Services - inte-QT" />
        <meta
            property="twitter:title"
            content="Global Network Connectivity Services - inte-QT"
            data-react-helmet="True"
        />
        <meta
            name="description"
            content="Looking for a global network connectivity service supplier? We offer network connectivity solutions for worldwide enterprises."
        />
        <meta
            property="og:description"
            content="Looking for a global network connectivity service supplier? We offer network connectivity solutions for worldwide enterprises."
        />
        <meta
            property="twitter:description"
            content="Looking for a global network connectivity service supplier? We offer network connectivity solutions for worldwide enterprises."
            data-react-helmet="True"
        />
        <link rel="canonical" href="https://www.inte-qt.com/services" />
        <meta property="og:url" content="https://www.inte-qt.com/services"/>
        <meta property="twitter:url" content="https://www.bitsnbyteglobal.com/services"/>
        <meta
            name="keywords"
              content="network connectivity solutions, global network connectivity, network connectivity service supplier, enterprise network connectivity"
              data-react-helmet="True"/>
      </Helmet>

      <CarouselDesk />
      <div className="services__colorGradient"></div>
      <h2 className="text-center services__header mx-auto center-header blue">
        Global Connectivity Solutions
      </h2>
      <div className="b_read"><p className="text-center my-3">Our Global Connectivity Solutions are fast and reliable service for you or your end customer’s business that connects you directly to Local Internet backbone & Gateway of that  specific country.</p></div>

      <ConnectivitySolutions />

      <h2 className="text-center services__header mx-auto center-header blue">
        Global Managed Solutions
      </h2>
      <div className="b_read"><p className="text-center my-3">Our Global Managed Solutions enable our customers to maintain control of their network strategy associated with their priority business needs. Our certified Network Specialist closely monitor and manage network operations and maintenance, change management and resolve any network interruptions in quick Turn Around Time.</p></div>
      
      <ManagedSolutions />
      <GetInTouch />
      </div>
  );
}
