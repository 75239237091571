import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import DinamicLink from '../../../components/DinamicLink/DinamicLink';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../Blog.scss';

function Blog2() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="blog">
      <Helmet>
        <title>And here I am - inte-QT</title>
        <meta name="title" content="And here I am - inte-QT" />
        <meta
          name="description"
          content="My experience has been in Market intelligence (fast food and mass market) which had its effect due to the pandemic. I was looking for new opportunities, where I could learn, grow and prove myself."
        />
        <meta
          name="keywords"
          content="Market Intelligence, Business Analyst, Training, Induction Program, inte-QT"
        />
        <meta name="author" content="Viviana Passaro" />
        <meta property="og:title" content="And here I am - inte-QT" />
        <meta
          property="og:description"
          content="My experience has been in Market intelligence (fast food and mass market) which had its effect due to the pandemic. I was looking for new opportunities, where I could learn, grow and prove myself."
        />
        <meta property="og:image" content="https://i.imgur.com/mCLSqQE.png" />
        <meta property="og:url" content="https://www.inte-qt.com/blog/2" />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="BitsnByte Global" />
        <link rel="canonical" href="https://www.inte-qt.com/blog/2" />
      </Helmet>
      <h1 className="center-header center-header__title mx-auto text-center">
        And here I am, part of the Internet wave...
      </h1>
      <div className="container">
        <div className="blog__text" align="justify">
          <div className="text-end">
            <p className="blogPosts__tags">
              <span className="blue mx-2">Business Analyst</span>/
              <span className="blue mx-2">Training</span>/
              <span className="blue mx-2">Induction Program</span>
            </p>
          </div>
          <p>
            {`By `}
            <a
              href="https://www.linkedin.com/in/viviana-passaro/"
              className="blue"
              target="_blank"
              rel="noopener noreferrer"
            >
              {`Viviana Passaro `}
              <i className="fab fa-linkedin-in ms-1"></i>
            </a>
            {` on April 15th, 2021 · 2 min read`}
          </p>

          <p>
            My experience has been in Market intelligence (fast food and mass
            market) which had its effect due to the pandemic. I was looking for
            new opportunities, where I could learn, grow and prove myself.
          </p>
          <p>
            Last year, in October I went for an interview in{' '}
            <a
              href="https://www.linkedin.com/company/bitsandbyteglobal/"
              className="blue"
              target="_blank"
              rel="noopener noreferrer"
            >
              inte-QT
            </a>
            . The first thing I had heard about them was that they are a global
            aggregator, young and disruptive. My interview went well and I was
            selected for this role as a Global Business Analyst. I have been
            connected and that’s due to the Internet industry, and I was aware
            of the dynamics from the far end.
          </p>

          <div className="blog__img-float">
            <img
              src="https://i.imgur.com/mCLSqQE.png"
              alt="Service delivery illustration"
              loading="lazy"
            />
          </div>
          <p>
            But I was getting into it from day one. It has been a roller-coaster
            ride as I started my training, learning technologies like{' '}
            <span className="blue">#Cloud</span>,{' '}
            <span className="blue">#SDWAN</span>.. and getting the best of
            knowledge on the last miles. It’s truly global where my normal day
            starts with talking to a partner in Japan, dealing with a client in
            Singapore, being part of onboarding in Croatia, and the eve hours
            are with my partners in <span className="blue">#LATAM</span>. And
            that is a normal day.
          </p>
          <p>
            We have partners everywhere and they love us, support us and make us
            win. My training months also allowed me to be part of the GSM
            (Global Service Management) team, here it is mandatory in the
            training and induction to touch every main vertical of the business.
            The best exposure is that you realize the ecosystem and here I am
            part of this industry. Thanks, inte-QT to allow me to be part of the
            team and for the freedom to do my best. At inte-QT, we go the extra
            mile to get the best for our customers.
          </p>
          <div className="text-end my-5">
            <img
              src="https://i.imgur.com/nHiIOhi.png"
              alt="Global team collaboration"
              loading="lazy"
            />
          </div>
        </div>

        <div className="blogPosts__box">
          <div className="blogPosts__imgbox me-5">
            <img
              src="https://i.imgur.com/52EZD6B.png"
              alt="Customer Delight Approach"
              className="blogPosts__img"
              loading="lazy"
            />
          </div>
          <p className="blogPosts__tags">
            <span className="blue mx-2">Service Delivery</span>/
            <span className="blue mx-2">Customer Delight</span>/
            <span className="blue mx-2">COVID-19</span>
          </p>
          <h2 className="blogPosts__title">Customer Delight Approach</h2>
          <p className="blogPosts__author">
            {`By `}
            <a
              href="https://www.linkedin.com/in/nikhil-jain-705a6943/"
              className="blue"
              target="_blank"
              rel="noopener noreferrer"
            >
              {`Nikhil Jain `}
              <i className="fab fa-linkedin-in ms-1"></i>
            </a>
            {` on August 17th, 2020 · 1 min read`}
          </p>

          <p>
            A significant part of world’s population is affected amidst COVID-19
            pandemic, the people are utilizing connectivity services more not
            only for normal browsing and streaming...
          </p>
          <div className="text-end me-5">
            <DinamicLink url="/blog/1" name="Read more" />
          </div>
        </div>

        <GetInTouch />
      </div>
    </div>
  );
}

export default Blog2;
