import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function Bulgaria() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Bulgaria - inte-QT</title>
        <meta
          name="title"
          content="Internet in Bulgaria - inte-QT"
        />
        <meta name="description" content="Our Coverage in Bulgaria" />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/bulgaria" />
      </Helmet>
      <img
        src="https://i.imgur.com/QOxVANy.jpg"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - The current population of Bulgaria
              in 2022 is 6,844,597*
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - Romania, Serbia,
              Macedonia, Greece and Turkey.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - <strong>Bulgarian</strong>. 85%
              of the approximate 8.7m population of Bulgaria speak the official
              language, Bulgarian. 2.5% speak Macedonian, considered in Bulgaria
              as a dialect of Bulgarian and not as a separate language.
            </li>
            <li>
              <strong>CURRENCY</strong> - The Bulgarian lev (BGN) is the
              official currency of Bulgaria. While Bulgaria is an EU member
              state, it has not adopted the euro as its currency. Each lev
              (meaning 'lion') can be divided into 100 stotinki. The plural of
              lev is leva.
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Metallurgical industry, electricity,
              electronics, machinery and equipment, shipbuilding,
              petrochemicals, cement and construction, textiles, food and
              beverages, mining, tourism.
            </li>
            <li>
              <strong>EMPLOYMENT*</strong> - Employment Rate in Bulgaria
              averaged 47.42 percent from 2000 until 2021.
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Sofia, 1.204.685* inhabitants</li>
                <li>Plovdiv, 334.153* inhabitants</li>
                <li>Varna, 334.870* inhabitants</li>
                <li>Burgas, 200.271* inhabitants</li>
              </ul>
            </li>
            <li>
              <strong>WEATHER</strong> - Bulgaria is characterized by two
              climatic regions: a continental climate in the north and a
              Mediterranean climate in the south. The country's Mediterranean
              climate tends to be hot and dry in the summers and cool in
              winters... The mean monthly temperature in the country ranges from
              -1 °C to 22 °C.
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/75d86fa70faac7bd9de3a9e08741271d/raw/859a3c4f9dea3824d1793a6b479ad057fadfc0cf/bulgaria-flag.svg"
              alt="Bulgaria flag"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            Bulgaria is one of the oldest European countries established in 681
            AD. Since then Bulgaria is the only one who hasn't change its name.{' '}
          </p>
          <p>
            Bulgaria ranks third in Europe for the number of its valuable
            archeological monuments after Italy and Greece.{' '}
          </p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3002379.306725311!2d23.3001916928295!3d42.703486968186645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a8fec1c85bf089%3A0xa01269bf4c10!2sBulgaria!5e0!3m2!1sen!2ses!4v1645524826631!5m2!1sen!2ses"
            width="95%"
            title="bulgaria-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>

          <h4 className="mt-4">Tourism</h4>
          <p>
            According to the data from UNWTO, the number of tourists arriving in
            Bulgaria for personal purposes has increased over the years. In
            2019, more than 5.8 million people visited Bulgaria for vacation or
            recreational purposes.
          </p>

          <h4 className="mt-4">Main Ports / Airports</h4>
          <table className="table table-striped text-center">
            <thead>
              <tr>
                <th scope="col">Rank</th>
                <th scope="col">Airport</th>
                <th scope="col">City</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Sofia Airport</td>
                <td>Sofia</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Varna Airport</td>
                <td>Varna</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Burgas Airport</td>
                <td>Burgas</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Plovdiv Airport</td>
                <td>Plovdiv</td>
              </tr>
            </tbody>
          </table>
          <p>
            Bulgaria has 6 main ports:{' '}
            <strong>Balchik, Burgas, Kavarna, Nessebar, Sofia, Varna.</strong>
          </p>

          <h4 className="mt-5">National Sport</h4>
          <p>
            Football is the most popular sport in Bulgaria. Many Bulgarians
            closely follow the top Bulgarian league, currently known as the
            First Professional Football League; as well as the leagues of other
            European countries.
          </p>

          <h4 className="mt-5">Typical Food</h4>
          <p>Banitsa, Tarator, Meshana Skara, Sarmi.</p>
        </article>
      </section>

      <div className="container">
        <h4 className="my-5">Submarine Cables</h4>
        <a
          href="https://www.submarinecablemap.com/country/bulgaria"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/2Zv6hxd.png"
            alt="submarine cables"
            width="100%"
            className=""
          />
        </a>
        <ol className="mt-4">
          <li>Caucasus Cable System</li>

          <li>Kafos</li>
        </ol>

        <h4 className="my-4">ACCESS TO LANDLINE AND MOBILE PHONE </h4>
        <p>
          In 2020, 79 percent of households in Bulgaria had access to a
          broadband internet connection. This was an increase of four percent in
          comparison to the previous year.{' '}
        </p>

        <h4 className="my-4">ACCESS TO INTERNET </h4>
        <p>
          Bulgaria ranks at the 20-th position in the global Net Index Explorer
          for broadband internet accessibility and speed. The survey was
          presented by Bulgaria's Society for Electronic Communications (SEC).{' '}
        </p>

        <p>
          The average download speed in Bulgaria is 33.5 Mbps and the average
          upload speed is 22.8 Mbps. For comparison, in Romania the download
          speed is 55.7 Mbps for download and 29.3 Mbps for upload. The fastest
          internet is in the towns of Pazardzhik, Rakovski and Troyan{' '}
        </p>

        <p>
          According to SEC, the main reason for the good internet speed in
          Bulgaria is the fragmentation and competitiveness of the ISP sector in
          the country. ... According to the survey, Bulgaria has 600 regional
          ISPs, which provide internet to more than 45% of the users.{' '}
        </p>

        <h4 className="my-4">Internet Providers*</h4>
        <p>
          The country has 10 Internet providers owning infrastructure who
          provide the access throughout the country. inte-QT as a
          global access enabler partners with the local providers and today, we
          have 7 infrastructure owning partners in the Bulgaria.
        </p>

        <h4 className="my-4">Our Capabilities</h4>
        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connect</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>SLA</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins"
          align="justify"
        >
          With our Global Business Solutions team, we can provide a{' '}
          <mark>Commercial Offer in 2-3 working days</mark> and our Global Ops
          team is capable to deliver services in the country in 4 to 6 weeks.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://climateknowledgeportal.worldbank.org/country/bulgaria/climate-data-historical#:~:text=Bulgaria%20is%20characterized%20by%20two,summers%20and%20cool%20in%20winters.&text=The%20mean%20monthly%20temperature%20in,%E2%84%83%20to%2022%20%C2%B0C"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.postoffice.co.uk"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://www.infobusiness.bcci.bg/econ3-17-10-14.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
          <li>
            <a
              href="https://www.bbc.co.uk/languages/european_languages/languages/bulgarian.shtml"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 4
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Bulgaria;
