import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import DinamicLink from '../../../components/DinamicLink/DinamicLink';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../Blog.scss';

function Blog4() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="blog">
      <Helmet>
        <title>What is Blockchain about?? - inte-QT</title>
        <meta
          name="description"
          content="In the digital era where everything runs through a computer or a cell phone, it seems so natural to see the news of what is happening around..."
        />
        <meta name="title" content="What is Blockchain about?? - inte-QT" />
        <link rel="canonical" href="https://www.inte-qt.com/blog/4" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://inte-qt.com/blog/4" />
        <meta
          property="og:title"
          content="What is Blockchain about?? - inte-QT"
        />
        <meta
          property="og:description"
          content="In the digital era where everything runs through a computer or a cell phone, it seems so natural to see the news of what is happening around..."
        />
        <meta property="og:image" content="https://i.imgur.com/Vi0se0F.jpg" />
        <meta property="og:site_name" content="inte-QT" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://inte-qt.com/blog/4" />
        <meta
          property="twitter:title"
          content="What is Blockchain about?? - inte-QT"
        />
        <meta
          property="twitter:description"
          content="In the digital era where everything runs through a computer or a cell phone, it seems so natural to see the news of what is happening around..."
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/Vi0se0F.jpg"
        />
        <meta name="twitter:image:alt" content="Blockchain Image" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <header>
        <h1 className="center-header center-header__title mx-auto text-center">
          What is Blockchain about??
        </h1>
      </header>

      <main className="container">
        <article>
          <section className="blog__text">
            <div className="text-end">
              <p className="blogPosts__tags">
                <span className="blue mx-2">Connectivity</span>/
                <span className="blue mx-2">Bitcoin</span>/
                <span className="blue mx-2">Blockchain</span>
              </p>
            </div>

            <p className="blogPosts__author">
              {`By `}
              <a
                href="https://www.linkedin.com/in/juanrolo/"
                className="blue"
                target="_blank"
                rel="noopener noreferrer"
              >
                Juan Rolo
                <i className="fab fa-linkedin-in ms-1"></i>
              </a>
              {` on September 22nd, 2021 · 4 min read`}
            </p>

            <div className="text-center">
              <img
                src="https://i.imgur.com/X3YXo9Q.jpg"
                alt="Blockchain Overview"
                className="blog__img"
              />
            </div>

            <p>
              In the digital era where everything runs through a computer or a
              cell phone, it seems so natural to see the news of what is
              happening around the world that sometimes we do not stop to think
              about how the information moves and where it has to go through to
              reach us.
            </p>

            <p>
              The world of telecommunications has shown me how the process
              behind a WhatsApp message (for example), one of the most
              controversial points around all those submarine cables, CDNs, and
              different services that keep us connected and that are on
              everyone's lips is the <strong>Bitcoin</strong>.
            </p>

            <p>
              Before talking about the <strong>Blockchain</strong> as the title
              indicates, it is necessary to know where the idea comes from and
              what other technologies it is linked to. There is a lot of
              information about it but if you ask me for a brief explanation I
              would say that the <u>Blockchain is the engine of Bitcoin</u>, it
              is responsible for the decentralization and boom that Bitcoin has
              had but be careful not to confuse the concepts because Bitcoin is
              not Blockchain.
            </p>

            <p>
              Everything was born when mysteriously a Whitepaper appeared on the
              internet forums in 2009 called
              <a
                href="https://bitcoin.org/bitcoin.pdf"
                className="blue"
                target="_blank"
                rel="noopener noreferrer"
              >
                "A Peer-to-Peer Electronic Cash System"
              </a>
              published by someone unknown under the pseudonym "
              <strong className="blue">Satoshi Nakamoto</strong>" proposing an
              algorithmic solution for the creation of a peer-to-peer network
              for the exchange of money between people without the need for a
              central institution to do the job.
            </p>

            <p>
              Now that we know about the existence of this Whitepaper we can
              define the <u>key points</u> for a better understanding of what
              the Blockchain is all about and how through this proposal, the
              movement that has given way to endless technologies such as NFTs
              was created.
            </p>

            <p>
              Centralization and decentralization are two terms that refer to
              two contrasting models of administration and control of authority
              that can be applied, one or the other, in an organization,
              department, or political system.
            </p>

            <p>
              <strong>Centralization</strong> indicates the action of
              concentrating authority, i.e., decision making is centralized on a
              specific person or group of individuals, in order to optimize the
              work system of an organization or company's administration.
            </p>

            <p>
              <strong>Decentralization</strong> is the action of transferring or
              delegating authority to other hierarchies, i.e., other departments
              or units of an organization or government can make decisions and
              generate greater benefits. It is even possible to have a
              decentralized system of education.
            </p>

            <p>
              Both centralization and decentralization have a number of
              advantages in terms of authority management and effectiveness.
              Likewise, we can find several disadvantages that may make people
              doubt which of these is more convenient. However, one or the other
              option will be less recommended depending on the situation in
              question.
            </p>

            <p>
              P2P or <strong>Peer-to-Peer</strong> networks are a type of
              decentralized network. Networks that are made up of hundreds or
              even millions of computers located all over the world. All of them
              working under the same communications protocol, with the aim of
              creating a huge network to share information of any kind. If today
              we can buy bitcoins it is thanks to this type of network.
            </p>

            <p>
              Knowing the differences between centralized and decentralized, and
              defining peer-to-peer networks, we can give a brief definition to
              Bitcoin. When we talk about a digital currency like this, we are
              actually talking about the entire record of transactions that
              indicate how users are transferring them to each other; this
              record is the currency and the information of it is stored and
              distributed in each of the participants of the network.
            </p>

            <p>
              We have talked about different points in this blog and you may be
              asking yourself, "Ok Juan, I know what Bitcoin is, I know the
              difference between centralized and decentralized, I know the
              meaning of peer-to-peer networks but,{' '}
              <strong>what is Blockchain?</strong>"
            </p>

            <p>
              Blockchain is a giant ledger in which the records (the blocks) are
              linked and encrypted to protect the security and privacy of
              transactions. A distributed and secure database (thanks to
              encryption).
            </p>

            <p>
              Each transaction within the network is encrypted by functions that
              allow us to modify a data entry in a sequence of apparently random
              numbers (Hash), specifically a 256-bit number if we are operating
              with the SHA-256 algorithm. You enter a file, it encodes it and
              returns a 256-bit hexadecimal number; with that number, you can
              obtain the initial information by decoding it with the same
              algorithm that was encoded (SHA-256).
            </p>

            <p>
              There is nothing to worry about if in this decentralized network
              one of the participants decides not to continue because according
              to Satoshi's statement we must continue with the longest
              blockchain, thus leaving the blockchain to which the missing node
              belonged invalid.
            </p>

            <p>
              In this blog I will not explain how the process of mining a block
              and how miners have to invest a lot of computational power to be
              able to find these blocks because it would become too long, but I
              will explain the security within the Blockchain.
            </p>

            <p>
              Remember that I have explained how Hashes are used within the
              network; what I did not explain is that if there is a minimal
              change in the process it will leave the transaction invalid
              because the resulting hash will not be the same. Imagine that you
              introduce an image into the Blockchain and get a Hash (You have
              created an NFT), you can get the image again doing the reverse
              process, but if the code or the initial image suffers a change in
              a number of the chain or a pixel of the image, it will invalidate
              that transaction (NFT without value). This makes the network
              extremely secure because it prevents even the smallest changes.
            </p>

            <p>
              Concluding, the Blockchain is a structure of mined data with
              computational power connected together through the last and first
              Hash of each block where we record all the transactions we make
              under the name Bitcoin.
            </p>
          </section>

          <section className="blogPosts__box">
            <div className="blogPosts__imgbox me-5">
              <img
                src="https://i.imgur.com/38sP42d.jpg"
                alt="Service Assurance"
                className="blogPosts__img"
              />
            </div>

            <p className="blogPosts__tags">
              <span className="blue mx-2">Opportunities</span>/
              <span className="blue mx-2">Service Assurance</span>/
              <span className="blue mx-2">Service Delivery</span>
            </p>

            <h2 className="blogPosts__title">
              Committed to deliver, even after disastrous conditions..
            </h2>

            <p className="blogPosts__author">
              {`By `}
              <a
                href="https://www.linkedin.com/in/dagagaurav/"
                className="blue"
                target="_blank"
                rel="noopener noreferrer"
              >
                Gaurav Daga
                <i className="fab fa-linkedin-in ms-1"></i>
              </a>
              {` on May 14th, 2021 · 2 min read`}
            </p>

            <p>
              Providing 2 Hours of Onsite Service free with every circuit
              installation. We offer Layer-3 Internet Managed service in 183
              countries.
            </p>

            <div className="text-end me-5">
              <DinamicLink url="/blog/3" name="Read more" />
            </div>
          </section>
        </article>
      </main>

      <footer>
        <GetInTouch />
      </footer>
    </div>
  );
}

export default Blog4;
