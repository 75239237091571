import Leaflet from 'leaflet';
import icon from '../../data/icon.svg';

export const IconLocation = Leaflet.icon({
  iconUrl: icon,
  iconRetinaUrl: icon,
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor:null,
  iconSize: [35, 35],
  className: "LeafLet-venue-icon",
});