import React from 'react';
import './EventsComponent.scss';
import DinamicLink from '../DinamicLink/DinamicLink';

export default function EventsComponent() {
  return (
    <div className="news container-fluid">
      <div className="row text-center my-5">
      <div className="col-lg-3 col-md-8 col-12 card m-auto">
          <img src="https://i.imgur.com/IJBhBsd.jpg" alt="2" className="news__img p-2 mt-3"/>
          <div className="card-body">
            <h3 className="card-title">ITW Africa And Data Cloud Africa 2024</h3>
            <p className="card-text">10 - 12 September,2024  <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Kenya</span></p>
            <div className="text-end">
              <DinamicLink url="/events" name="Read more"/>
            </div>
          </div>
        </div>  
      <div className="col-lg-3 col-md-8 col-12 card m-auto">
          <img src="https://i.imgur.com/vIt6jue.jpg" alt="2" className="news__img p-2 mt-3"/>
          <div className="card-body">
            <h3 className="card-title">Cloud Expo Europe 2022</h3>
            <p className="card-text">28 - 29 October 2022 <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">Madrid, ES</span></p>
            <div className="text-end">
              <DinamicLink url="/events" name="Read more"/>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-8 col-12 card m-auto">
          <img src="https://i.imgur.com/89ne2Om.jpg" alt="2" className="news__img p-2 mt-3"/>
          <div className="card-body">
            <h3 className="card-title">Capacity EUROPE 2022</h3>
            <p className="card-text">17 - 20 October 2022 | InternationalContinental London | The 02  <br /> <i className="fas fa-map-marker-alt blue"></i><span className="blue mx-2">London, UK</span></p>
            <div className="text-end">
              <DinamicLink url="/events" name="Read more"/>
            </div>
          </div>
        </div>
       


      </div>
    </div>
  );
}
