import React, { useEffect } from 'react';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import EventsComponent from '../../../components/EventsComponent/EventsComponent';
import '../../Cases/Cases.scss';
import '../../Cases/CaseFiji/CaseFiji.scss';
import '../Testimonials.scss';
import { ImQuotesLeft, ImQuotesRight } from 'react-icons/im';
import { Helmet } from 'react-helmet';

export default function TestimonialMx() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Testimonial Mx - inte-QT</title>
        <meta name="title" content="Testimonial Mx - inte-QT" />
        <meta name="description" content="We asked the Associate Director of Media Services a few questions about the experience of us serving at this event..." />
        <link rel="canonical" href="https://www.inte-qt.com/testimonials/mx" />
      </Helmet>
      <img
        src="https://i.imgur.com/6Q7TYsB.jpg"
        alt="bigbanner-background"
        className="banner-img banner-img__black"
      />
      <h1 className="banner-img__header banner-img__black">Brilliant Work</h1>

      <div className="container-fluid caseFiji">
        <div className="container">
          <div className="caseFiji__aux">
            <p className="caseFiji__title text-center center-header mx-auto">
              <ImQuotesLeft className="me-2" />
              <span className="blue">Brilliant work by inte-QT</span>
              <ImQuotesRight className="ms-2" />
            </p>
            <p>
              We asked the Associate Director of Media Services a few questions
              about the experience of us serving at this event:
            </p>
            <p>
              <strong className="testimonials__question">
                Describe the Project, Challenge and Goals:
              </strong>
              <br />
              <br /> This is a media event and mostly the venues are at remote
              places. Moreover, we are going through unprecedented situation and
              the end customer expects to have best of the best support
              including COVID protocol due to remote production facilities.
            </p>
            <p>
              <strong className="testimonials__question">
                How did we come to your mind?
              </strong>{' '}
              <br />
              <br /> The inte-QT is a global VNO aggregator with
              good handholding and niche suppliers who are able to deliver
              connectivity at such remote places. You guys offer first class
              connectivity.
            </p>
            <p>
              <strong className="testimonials__question">
                What finally made you to decide, it´s inte-QT?
              </strong>{' '}
              <br />
              <br /> Promptness and agile to manage last moment changes in the
              solution and aligning delivery.
            </p>
            <p>
              <strong className="testimonials__question">
                How was our first level impact after you finalized us?
              </strong>{' '}
              <br />
              <br /> The solution offered along with commercials and all other
              details (Eg: Route Map/SLA/OLO Information) trimmed down approach
              to select the supplier.
            </p>
            <p>
              <strong className="testimonials__question">
                How we manage the project?
              </strong>{' '}
              <br />
              <br /> It was seamlessly managed by inte-QT, right
              from Quotation to timely delivery well within event schedule.
              Overall its delighted experience.
            </p>
            <p>
              <strong className="testimonials__question">
                How did we deliver?
              </strong>{' '}
              <br />
              <br /> On Dot – as it was agreed in the specs and within
              timelines.
            </p>
            <p>
              <strong className="testimonials__question">
                Moments we made a total difference
              </strong>
              <br />
              <br /> Regular updates and follow-ups on deliverables with onsite
              engagement.
            </p>
            <p>
              <strong className="testimonials__question">
                How did we finish the job?
              </strong>{' '}
              <br />
              <br /> <span className="blue">Brilliant.</span>
            </p>
            <p>
              <strong className="testimonials__question">
                Do you refer us for the future projects?
              </strong>{' '}
              <br />
              <br /> That proves inte-QT’s ability to be utilized
              for more and more events like that along with BAU requirements.
            </p>
            <p>
              <strong className="testimonials__question">
                How was your overall experience working with inte-QT?
              </strong>{' '}
              <br />
              <br /> Delighted Experience.
            </p>
          </div>

          <EventsComponent />
        </div>
      </div>

      <GetInTouch />
    </div>
  );
}
