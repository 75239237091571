import Leaflet from 'leaflet';
import icon from '../../data/pin.svg';

export const PinLocation = Leaflet.icon({
  iconUrl: icon,
  iconRetinaUrl: icon,
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor:null,
  iconSize: [25, 25],
  className: "LeafLet-venue-icon",
});