import React, { useEffect } from 'react';
import '../CoverageFormat.scss';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import { Helmet } from 'react-helmet';

function Indonesia() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Indonesia - inte-QT</title>
        <meta
          name="title"
          content="Internet in Indonesia - inte-QT"
        />
        <meta name="description" content="Our Coverage in Indonesia" />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/indonesia" />
      </Helmet>
      <img
        src="https://i.imgur.com/2Wqitmk.jpg"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - 277.846 million*
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES</strong> - Indonesia shares borders
              with <strong>Malaysia</strong> on Borneo island, with Papua New
              Guinea on Papua island, and with East Timor on Timor island. Other
              neighboring countries include Singapore, the Philippines,
              Australia, and the union territory of the Andaman and Nicobar
              Islands in India.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - Bahasa Indonesia
            </li>
            <li>
              <strong>CURRENCY</strong> - Indonesia Rupiah
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Major industrial sectors include{' '} 
              <strong>
                petroleum and natural gas, textiles and apparel, mining,
                footwear, plywood, rubber and chemical fertilisers
              </strong>
              . The services sector is equally as important to Indonesia's
              economy, accounting for 43 per cent of GDP in 2015. Agriculture on
              the other hand only accounted for 14 per cent.
            </li>
            <li>
              <strong>EMPLOYMENT</strong> - reported at <strong>22.65%</strong>{' '}
              in 2020*
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>

              <ul>
                <li>Jakarta (Jakarta Province): 10.135 million. </li>
                <li>Surabaya (East Java): 2.843 million. </li>
                <li>Bandung (West Java): 2.575 million. </li>
              </ul>
            </li>
            <li>
              {' '}
              <strong>TOURISM</strong> - More than 16.1 million*{' '}
            </li>
            <li>
              <strong>WEATHER</strong> - Indonesia's climate is{' '}
              <strong>largely hot and humid</strong>, with rainfall occurring
              mostly in low-lying areas and mountainous regions experiencing
              cooler temperatures. The cities of Jakarta, Ujung Padang, Medan,
              Padang, and Balikpapan have an average minimum temperature of
              22.8°C and a high of 30.2°C.
            </li>
            <li>
              <strong>NATIONAL SPORT</strong> - <strong>Badminton</strong> is
              Indonesia's most successful sport, described by the New York Times
              as "part of the national identity". Indonesia has won gold medals
              in badminton in every Olympic Games since the sport was first
              introduced to the Olympics in 1992, except in 2012.
            </li>
          </ul>{' '}
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/1f04b22aa44a01f87349464972455de4/raw/7355c99666757619f7155a0369c0ffcb4de70f6f/indonesia-flag.svg"
              alt="indonesia flag"
              className="my-5 CoverageFormat__flag"
            />
          </div>
        </nav>
        <article>
          <p>
            The Republic of Indonesia (RI), or familiarly known as Indonesia, is
            a country in Southeast Asia that is located on the equator and
            situated between the Asian and Australian continents and between the
            Pacific and Indian Oceans.
          </p>

          <p>
            As it lies between two continents and two oceans, Indonesia is also
            called "Nusantara" (archipelago in between). Consisting of 17,508
            islands, Indonesia is the largest archipelagic country in the world.
            With a population of 222 million people in 2006, Indonesia is the
            fourth most populous country in the world and the country with the
            largest Muslim population in the world, although it is officially
            not an Islamic state.{' '}
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16321500.197052902!2d109.79691469855621!3d-2.890768660449413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2c4c07d7496404b7%3A0xe37b4de71badf485!2sIndonesia!5e0!3m2!1sen!2ses!4v1643709441874!5m2!1sen!2ses"
            width="95%"
            title="indonesia-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>

          <h4 className="mt-4">Main Ports / Airports</h4>

          <table className="table table-striped mb-3">
            <thead>
              <tr>
                <th scope="column">IATA</th>
                <th scope="column">Name</th>
                <th scope="column">City</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>CGK</td>
                <td>Soekarno-Hatta International Airport</td>
                <td>Jakarta</td>
              </tr>
              <tr>
                <td>DPS</td>
                <td>Ngurah Rai (Bali) International Airport</td>
                <td>Denpasar-Bali Island</td>
              </tr>
              <tr>
                <td>SUB</td>
                <td>Juanda International Airport</td>
                <td>Surabaya</td>
              </tr>
              <tr>
                <td>PKU</td>
                <td>Sultan Syarif Kasim li (Simpang Tiga)</td>
                <td>Pekanbaru-Sumatra Island</td>
              </tr>
            </tbody>
          </table>
          <p>
            The 7 designated hub ports are the{' '}
            <strong>
              port of Belawan or Kuala Tanjung, Tanjung Priok, Tanjung Perak,
              Kijing, Makassar, Bitung, and Sorong.
            </strong>
          </p>

          <h4 className="mt-5">Typical Food</h4>
          <ol>
            <li>
              Indonesian Satay - Satay is meat skewers that are cooked over
              coals.
            </li>
            <li>Beef Rendang - This dish originated from Padang, Sumatra.</li>
            <li>
              Nasi Rawon - Nasi Rawon is a dish made of beef stew from East
              Java.
            </li>
            <li>
              Sop Buntut (Oxtail Soup) - The title says it all. It is a soup
              with Oxtail as its main hero.
            </li>
            <li>
              Sweet Martabak - One of our favorite desserts would be Indonesian
              Sweet Martabak. It is like a pancake.{' '}
            </li>
          </ol>

          <h4 className="mt-5">Connectivity details*</h4>
          <table className="table table-striped table-responsive">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Millions</th>
                <th scope="col">Porcentage</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Broadband users</td>
                <td>201.37 m</td>
                <td>72.49 %</td>
              </tr>
              <tr>
                <td>Landlines</td>
                <td>4.5 m</td>
                <td>1.65 %</td>
              </tr>
              <tr>
                <td>Mobile cellulars</td>
                <td>199.2 m</td>
                <td>71.7 %</td>
              </tr>
              <tr>
                <td>Access to internet</td>
                <td>193.9 m</td>
                <td>69.8 %</td>
              </tr>
            </tbody>
          </table>
        </article>
      </section>
      <div className="container">
        <p className="my-4">
          In 2020, approximately 1.65 percent of all households in Indonesia had
          access to a cable phone at home. As the importance of having landlines
          decrease over time due to the increase in mobile phone ownership in
          Indonesia, this number is expected to continue to trend downwards in
          the near future.{' '}
        </p>

        <h4 className="mt-5">Submarine Cables (Up to 70)</h4>
        <a
          href="https://www.submarinecablemap.com/country/indonesia"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/lJxEb5x.jpg"
            alt="submarine cables"
            width="100%"
            className=""
          />
        </a>
        <div className="row my-4">
          <div className="col-md-6 col-sm-12">
            <ul>
              <li>Apricot </li>

              <li>Asia Connect Cable 1 (ACC1) </li>

              <li>Australia-Singapore Cable (ASC) </li>

              <li>B2JS (Jakarta-Bangka-Batam-Singapore) Cable System </li>

              <li>BALOK </li>

              <li>Batam Dumai Melaka (BDM) Cable System </li>

              <li>Batam-Rengit Cable System (BRCS) </li>
              <li>Batam Sarawak Internet Cable System (BaSICS) </li>

              <li>Batam Singapore Cable System (BSCS) </li>

              <li>Bifrost2024 </li>

              <li>DAMAI Cable System </li>
            </ul>
          </div>
          <div className="col-md-6 col-sm-12">
            <ul>
              <li>Denpasar-Waingapu Cable Systems </li>

              <li>Dumai-Melaka Cable System </li>

              <li>East-West Submarine Cable System </li>

              <li>Echo2023 </li>

              <li>Hawaiki Nui2025 </li>

              <li>INDIGO-West </li>

              <li>Indonesia Global Gateway (IGG) System </li>

              <li>JaKa2LaDeMa </li>

              <li>JAKABARE </li>

              <li>Jakarta-Bangka-Bintan-Batam-Singapore </li>
            </ul>
          </div>
        </div>

        <h4 className="my-4">Internet Providers*</h4>

        <p>
          Indonesia is known because it is made up by 17,000 islands, being
          every island very well communicated. It has more than 40 submarine
          cables giving more people the access to internet. Indonesia has an
          average download speed of 17.26 mbps and average upload speed of 11.44
          mbps.
        </p>

        <p>
          Fiber cables are available between the island and also wireless
          connectivity every day has more presence. The country has a plan to
          continue growing and investing in the infraestructure to get better
          connectivity throughout the country.
        </p>

        <p>
          The country has 15 Internet providers owning infrastructure who
          provide the access throughout the country. inte-QT as a
          global access enabler with the local providers and today, we have 10
          infrastructure owning partners in Indonesia. We deliver Gigs of
          Internet in total, for many top notch enterprises as our end
          customers.
        </p>

        <h4 className="my-4">Our Capabilities</h4>
        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connect</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>SLA</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins"
          align="justify"
        >
          With our global business solutions team, we can provide a{' '}
          <mark>Commercial Offer in 1-2 working days.</mark>
          Our Global Ops team is capable to deliver services in the region, in
          major cities in 10 to 14 working days and in other regions in 4 to 6
          weeks.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://worldpopulationreview.com/countries/indonesia-population"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.aseanip.org/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://asialinkbusiness.com.au/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
          <li>
            <a
              href="https://climateknowledgeportal.worldbank.org/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 4
            </a>
          </li>
          <li>
            <a
              href="https://www.statista.com/statistics/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 5
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Indonesia;
