import React, { useEffect } from 'react';
import '../CoverageFormat.scss';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import { Helmet } from 'react-helmet';

function Austria() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Austria - inte-QT</title>
        <meta
          name="title"
          content="Internet in Austria - inte-QT"
        />
        <meta
          name="description"
          content="The country has 10 Internet providers owning infrastructure who provide the access throughout the country. inte-QT as a global access enabler partner with the local providers and today, we have 8 infrastructure owning partners in Austria."
        />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/austria" />
      </Helmet>
      <img
        src="https://i.imgur.com/YrjxC8I.jpg"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - 9.08 million*
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES</strong> - It is bordered by the{' '}
              <strong>Czech Republic</strong> and <strong>Germany</strong> to
              the north, <strong>Slovakia and Hungary</strong> to the east,{' '}
              <strong>Slovenia and Italy</strong> to the south, and{' '}
              <strong>Switzerland and Liechtenstein</strong> to the west
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - German is the official language
              of Austria and an important prerequisite for participating in the
              working, economic and social life of the country. Croatian,
              Slovenian and Hungarian are recognised as official languages of
              autonomous population groups in some regions.
            </li>
            <li>
              <strong>CURRENCY</strong> - Euro €
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Austria is a highly developed
              industrialized country with an important service sector. The most
              important industries are food and luxury commodities, mechanical
              engineering and steel construction, chemicals, and vehicle
              manufacturing.
            </li>
            <li>
              <strong>EMPLOYMENT</strong> - Employment Rate in Austria increased
              to 74 percent in the third quarter of 2021 from 71.60 percent in
              the second quarter of 2021.*
            </li>
            <li>
              <strong>TOP 3 CITIES</strong> - Vienna, Graz, Linz
            </li>
            <li>
              {' '}
              <strong>TOURISM</strong> - More than 31.9 million*{' '}
            </li>
            <li>
              <strong>WEATHER</strong> - Temperate and alpine climate
            </li>
            <li>
              <strong>NATIONAL SPORT </strong>- Austrians have a great love for
              winter sports like alpine skiing and ice hockey but they also love
              sport like association football or soccer and rugby.
            </li>
          </ul>{' '}
          <div className="text-center">
            <img
              src="https://i.imgur.com/QcyNtcX.png"
              alt="austria flag"
              className="my-5"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            Austria is a parliamentary representative democracy. The capital and
            largest city, with a population exceeding 1.9 million*, is Vienna.
            Austria is one of the wealthiest countries in the world, with a
            nominal per capita GDP of $46,972 (2018 est.)*. The country has
            developed a high standard of living and in 2019 was ranked 18th in
            the world on the Human Development Index. Austria has been a member
            of the United Nations since 1955, joined the European Union in 1995,
            and is a founder of the OECD. Austria also signed the Schengen
            Agreement in 1995, and adopted the European currency, the euro, in
            1999.
          </p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2750785.79354466!2d11.102982330416456!3d47.678316722159266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d079b259d2a7f%3A0x1012d47bdde4c1af!2sAustria!5e0!3m2!1sen!2ses!4v1643219487670!5m2!1sen!2ses"
            width="95%"
            title="austria-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>

          <h4 className="mt-4">Main Ports / Airports</h4>
          <p>
            Austria's three major airports in Vienna, Salzburg and Innsbruck
            ensure the country is well connected both nationally and
            internationally.
          </p>
          <ul>
            <li>Salzburg Airport.</li>
            <li>Vienna International Airport.</li>
            <li>Graz Airport.</li>
          </ul>
          <h4 className="mt-5">Typical Food</h4>
          <ul>
            <li>Viennese Apfelstrudel: Austrian National Food. </li>

            <li>Wiener Schnitzel: Fried Delight. </li>

            <li>Vienna Sausage: Austrian Authenticity. </li>

            <li>Knödel: Flavorful Dumpling. </li>

            <li>Tafelspitz: Boiled Beef.</li>

            <li>Tiroler Gröstl: Tradition Inspired. </li>

            <li>Käsespätzle: Cheesy Delight. </li>

            <li>Potato Gulasch: Tasty Treat. </li>
          </ul>
        </article>
      </section>
      <div className="container">
        <h4 className="mt-5">Connectivity details*</h4>
        <table className="table table-striped table-responsive">
          <thead>
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Millions</th>
              <th scope="col">Porcentage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Broadband users</td>
              <td>8.22 m</td>
              <td>95 %</td>
            </tr>
            <tr>
              <td>Landlines</td>
              <td>-</td>
              <td>118.61 per 100 habitants </td>
            </tr>
            <tr>
              <td>Mobile cellulars</td>
              <td>13.08 m</td>
              <td>144 %</td>
            </tr>
            <tr>
              <td>Access to internet</td>
              <td>8.03 m</td>
              <td>89 %</td>
            </tr>
          </tbody>
        </table>

        <h4 className="mt-4">Internet Providers*</h4>

        <p>
          In 2020, 90 percent of households in Austria had access to the
          internet.{' '}
        </p>

        <p>
          There were 13.08 million mobile connections in Austria in January
          2021. The number of mobile connections in Austria decreased by 158
          thousand (-1.2%) between January 2020 and January 2021. The number of
          mobile connections in Austria in January 2021 was equivalent to 145.0%
          of the total population.{' '}
        </p>

        <p>
          The country counts with an average download speed of 20,30mbs and
          average upload speed of 8,8mbs.{' '}
        </p>

        <p>
          The country has 10 Internet providers owning infrastructure who
          provide the access throughout the country. inte-QT as a
          global access enabler partner with the local providers and today, we
          have 8 infrastructure owning partners in Austria.{' '}
        </p>

        <p>Our main capabilities are described in the table below:</p>
        <table className="table table-striped">
          <thead>
            <th scope="col">Capabilities</th>
            <th scope="col">
              <i class="fas fa-check"></i>
            </th>
          </thead>
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connect</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>SLA</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins"
          align="justify"
        >
          With our global business solutions team, we can provide a{' '}
          <mark>Commercial Offer in 1-2 working days.</mark>
          Our Global Ops team is capable to deliver services in the region, in
          major cities in 10 to 14 working days and in other regions in 4 to 6
          weeks.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://www.worldometers.info/world-population/austria-population/ "
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.austria.org/overview"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://tradingeconomics.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Austria;
