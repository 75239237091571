import React, { useEffect } from 'react';
import '../CoverageFormat.scss';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import { Helmet } from 'react-helmet';

function Ethiopia() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Ethiopia - inte-QT</title>
        <meta
          name="title"
          content="Internet in Ethiopia - inte-QT"
        />
        <meta name="description" content="Our Coverage in Ethiopia" />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/ethiopia" />
      </Helmet>
      <img
        src="https://i.imgur.com/5CqSGjp.jpg"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - 117.8 million*
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - It is bordered by the{' '}
              <strong>Sudan</strong> in the west, Somalia and Djibouti in the
              east, Eritrea in the north and Kenya in the south.
              <ul>
                <li>It is the 10th largest country in Africa.</li>
                <li>The 2nd-most populous country in Africa after Nigeria.</li>
                <li>100 mts. Below sea level lowest pt.</li>
                <li>4620 mts the highest pt.. Ras Dashen.</li>
              </ul>
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - <strong>Amharic</strong> is the
              government's official language and a widely used lingua franca,
              but as of 2007, only 29%* of the population reported speaking
              Amharic as their main language. Oromo is spoken by over a third of
              the population as their main language and is the most widely
              spoken primary language in Ethiopia.
            </li>
            <li>
              <strong>CURRENCY</strong> -{' '}
              <strong>The Ethiopian birr (ETB)</strong>, the national currency
              of the Federal Democratic Republic of Ethiopia, is issued by the
              National Bank of Ethiopia, which manages its value through a dirty
              float. In the foreign exchange market, the birr is considered to
              be an exotic currency or thinly traded currency.
            </li>
            <li>
              <strong>INDUSTRIES</strong> - Food processing, beverages,
              textiles, leather, chemicals, metals processing, cement.
            </li>
            <li>
              <strong>EMPLOYMENT*</strong> - Employment in industry (% of total
              employment) (modeled ILO estimate) in Ethiopia was reported at{' '}
              <strong>10.35 percent</strong> in 2020, according to the World
              Bank collection of development indicators, compiled from
              officially recognized sources.
            </li>
            <li>
              <strong>TOP 3 CITIES</strong> - Addis Ababa (Finfinne), Dire Dawa,
              Mek'ele.
            </li>
            <li>
              <strong>WEATHER</strong> - Overall, Ethiopia is{' '}
              <strong>considered largely arid</strong>, but exhibits a high
              variability of precipitation
            </li>
            <li>
              <strong>NATIONAL SPORT</strong> - <strong>Football</strong>.
              Football is the most popular sport in Ethiopia.
            </li>
          </ul>{' '}
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/273b46b3dc5f2b8b5c28d29c610ae7fb/raw/aed7f61b45f84619ccb81300278b5e919a97f354/ethiopia-flag.svg"
              alt="ethiopia flag"
              className="my-5"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            Ethiopia, in the Horn of Africa, is a rugged, landlocked country
            split by the Great Rift Valley. With archaeological finds dating
            back more than <strong>3 million*</strong> years, it's a place of ancient culture.
            Among it's important sites are Lalibela with its rock-cut Christian
            churches from the 12th-13th centuries. Aksum is the ruins of an
            ancient city with obelisks, tombs, castles and Our Lady Mary of Zion
            church.
          </p>

          <p>
            It is strategically proximate to the Middle East and Europe,
            together with its easy access to the major ports of the region,
            enhances its international trade.
          </p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7388883.609510792!2d36.526389358997605!3d8.06756548664737!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1635d0cedd6cfd2b%3A0x7bf6a67f5348c55a!2sEthiopia!5e0!3m2!1sen!2ses!4v1644315762590!5m2!1sen!2ses"
            width="95%"
            title="dominican-republic-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>

          <h4 className="mt-4">TOURISM</h4>
          <p>
            The number of foreign tourists visiting Ethiopia in the year
            2016-2017 was reported to be <strong>871.000*</strong>, an impressive
            surge from previous years. Yet the Ethiopian Ministry of Tourism and
            Culture expects this to increase even further and predicts that
            there will be 2.5 million* arrivals per year by 2020.
          </p>

          <h4 className="mt-4">Main Ports / Airports</h4>
          <p>
            There are <strong>53 Airports</strong> in Ethiopia.
          </p>
          <ul>
            <li>Ethiopia's Main AirportsBole Airport, Addis Ababa.</li>
            <li>Arba Mintch Airport, Arba Mintch.</li>
            <li>Asela Airport, Asela. </li>
          </ul>

          <h4 className="mt-5">Typical Food</h4>
          <p>
            Ethiopian cuisine consists of a variety of tasty stews, fresh
            vegetables, and meat, all served on the traditional bread "injera."
            Most Ethiopian food is served alongside injera, the staple food of
            the country. Injera is a flat, soft, and spongy bread.
          </p>

          <ol>
            <li>
              Injera (sourdough flatbread) a ubiquitous pancake that is eaten
              all over Ethiopia{' '}
            </li>
            <li>Tibs (sautéed meat chunks)</li>
            <li>Shiro be Kibbe (legume stew)</li>
            <li>Berbere (typical spice blend) </li>
          </ol>
        </article>
      </section>
      <div className="container">
        <h4 className="mt-5">Connectivity details*</h4>
        <table className="table table-striped table-responsive">
          <thead>
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Millions</th>
              <th scope="col">Porcentage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Broadband users</td>
              <td>21.5 m</td>
              <td>18.5 %</td>
            </tr>
            <tr>
              <td>Landlines</td>
              <td>21.1 m</td>
              <td>18.4 %</td>
            </tr>
            <tr>
              <td>Mobile cellulars</td>
              <td>44.77 m</td>
              <td>38.5 %</td>
            </tr>
            <tr>
              <td>Access to internet</td>
              <td>23.96 m</td>
              <td>20.6 %</td>
            </tr>
          </tbody>
        </table>

        <h4 className="my-4">Internet Providers*</h4>

        <p>
          As of January 2020, Datareportal reports that 21.1 million people use
          the internet in Ethiopia,1 representing <strong>18.5 percent </strong> 
          of the total population. The 2.6 percent gain in internet penetration
          in 2019 mirrors the country's population growth rate during the same
          period.
        </p>

        <p>
          There were <strong>23.96 million internet users</strong> in Ethiopia
          in January 2021. The number of internet users in Ethiopia increased by
          2.8 million (+13%) between 2020 and 2021. Internet penetration in
          Ethiopia stood at 20.6% in January 2021.
        </p>

        <p>
          The number of mobile connectons in Ethiopia in January 2021 was
          equivalent to 38.5% of the total population.
        </p>

        <p>
          21.5 million people use the internet in Ethiopia, representing 18,5%
          of the population. The country counts with an average download speed
          of 1.13mpbs and average upload speed of 2.45mpbs.
        </p>

        <p>
        The country has 6 Internet providers with owning infrastructure who give connectivity access throughout Ethiopia. As Global access enabler, inte-QT partners with local Internet providers and today, we have 3 partners in Ethiopia  with their own infrastructure.
        </p>

        <h4 className="my-4">Our Capabilities</h4>
        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connect</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i class="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins"
          align="justify"
        >
          With our Global Business Solutions team, we can provide a <mark>Commercial Offer in 2-3 working days</mark> and our Global Ops team is capable to deliver services in the country in 15 to 20 working days. 
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://ethiopianembassy.org"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.britannica.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://freedomhouse.org/country/ethiopia/freedom-net/2020"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
          <li>
            <a
              href="https://datareportal.com/reports/digital-2021-ethiopia"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 4
            </a>
          </li>
          <li>
            <a
              href="https://absoluteethiopia.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 5
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Ethiopia;
