import React from 'react';
import { Link } from 'react-router-dom';
import './GlobalNsocComponent.scss';

export default function GlobalNsocComponent() {
  return (
        <div className="nsocComponent nsocComponent__white">
      <h2 className="nsocComponent__title text-center">
      Sit back & Relax, We got our EYES on it. <br /><Link to="/global-nsoc" className="nsocComponent__white" >NSOC 24X7</Link>
      </h2>
          <Link to="/global-nsoc">
            <img
          src="https://i.imgur.com/JFZsxJB.jpg"
          alt="bigbanner-background"
          className="banner-img mt-5"
        />
          </Link>
        </div>
  )
}
