import React from 'react';
import './Inteqt.scss';

export default function Inteqt() {
  return (
    <div className="inteqt mt-5">
      <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop" className="rounded mx-auto d-block w-100">
        <source src="https://res.cloudinary.com/dql1gaina/video/upload/v1726146342/website/aeta_o9ijuy.mov" />
      </video>
    </div>
  )
}
