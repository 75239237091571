import React, {useEffect} from 'react';
import DinamicLink from '../../../components/DinamicLink/DinamicLink';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../../Cases/Cases.scss';
import cases from '../../../data/casesInfo.json';
import '../CaseFiji/CaseFiji.scss';
import { Helmet } from 'react-helmet';

export default function CaseLebanon() {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  return (
    <div className="caseFiji">
      <Helmet>
        <title>Case Lebanon - inte-QT</title>
        <meta name="title" content="Case Lebanon - inte-QT" />
        <meta name="description" content={cases[1].title} />
        <link rel="canonical" href="https://www.inte-qt.com/cases/lebanon" />
      </Helmet>
      <img
        src={cases[1].img}
        alt="bigbanner-background"
        className="banner-img banner-img__black"
      />
      <h1 className="banner-img__header text-center">{cases[1].title}</h1>

      <div className="container-fluid caseFiji">
        <div className="container">
          <div className="caseFiji__aux">
            <p className="caseFiji__title text-center center-header mx-auto">
              {cases[1].title}
            </p>
            <h3 className="my-5">Challenge</h3>
            <p>{cases[1].p1}</p>
            <p>{cases[1].p2}</p>
            <h3 className="my-5">Goals</h3>
            <ul>
              <li>
                <p>{cases[1].p3}</p>
              </li>
              <li>
                <p>{cases[1].p4}</p>
              </li>
            </ul>
            <h3 className="my-5">Solution</h3>
            <p>{cases[1].p5}</p>
            <h3 className="my-5">Learnings</h3>
            <p>{cases[1].p6}</p>
            <p>{cases[1].p7}</p>
            <p>{cases[1].p8}</p>
            <p>{cases[1].p9}</p>
            <p>{cases[1].p10}</p>
          </div>
          <div className="cases__box">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h2 className="cases__companyName mx-3">
                  {cases[0].title}
                </h2>
                <p className="cases__text m-3" align="justify">
                  {cases[0].p3}
                </p>
                <div className="text-end mx-3">
                  <DinamicLink url="/cases/fiji" name="Read more" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <img
                  src={cases[0].img}
                  alt="1"
                  className="cases__img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <GetInTouch />
    </div>
  )
}