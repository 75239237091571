import React from 'react';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../CoverageFormat.scss';

function Bermuda() {
  return (
    <div className="CoverageFormat">
      <Helmet>
        <title>Internet in Bermuda - inte-QT</title>
        <meta
          name="title"
          content="Internet in Bermuda - inte-QT"
        />
        <meta name="description" content="Our Coverage in Bermuda" />
        <link rel="canonical" href="https://www.inte-qt.com/coverage/bermuda" />
      </Helmet>
      <img
        src="https://i.imgur.com/XY3pfTX.jpg"
        alt="bigbanner-background"
        className="banner-img CoverageFormat__saturation"
      />

      <section className="mt-5">
        <nav className="container">
          <ul>
            <li>
              <strong>POPULATION</strong> - The current population of Bermuda in
              2022 is <strong>61,939*</strong>.
            </li>
            <li>
              <strong>NEIGHBOURING COUNTRIES*</strong> - Located off the east
              coast of the United States, it is situated around 1,770 km (1,100
              mi) northeast of Miami, Florida and 1,350 km (840 mi) south of
              Halifax, Nova Scotia, west of Portugal, northwest of Brazil, 1,759
              km (1,093 mi) north of Cuba and north-northeast of Puerto Rico.
            </li>
            <li>
              <strong>LANGUAGE SPOKEN</strong> - The predominant language is
              Bermudian English which makes the island a diverse place that's
              navigable for most English-speaking individuals. It's a unique
              dialect that's a fusion of many different languages, accents and
              influences. Spanish language is also spoken in the island but by a
              very small population.
            </li>
            <li>
              <strong>CURRENCY</strong> - Legal tender on the island is the
              Bermuda Dollar, which trades at the same rate as the U.S. dollar
              and carries the same symbol ($). Bermuda and the U.S. Dollar are
              accepted interchangeably. When dispensing change, most retailers
              and businesses will try to provide change in U.S. dollars to
              visitors.
            </li>
            <li>
              <strong>INDUSTRIES</strong> - International Business, Tourism and
              Light Manufacturing.
            </li>
            <li>
              <strong>TOP 3 CITIES</strong>
              <ul>
                <li>Saint George</li>
                <li>Hamilton City</li>
                <li>Stovel Bay, Pembroke</li>
              </ul>
            </li>
            <li>
              <p>
                <strong>WEATHER</strong> - A semitropical island, Bermuda enjoys
                a mild climate; the term "Bermuda high" has come to mean sunny
                days and clear skies. The Gulf Stream, which flows between the
                island and North America, keeps the climate temperate. There's
                no rainy season, and no typical month of excess rain.
              </p>
            </li>
          </ul>
          <div className="text-center">
            <img
              src="https://gistcdn.githack.com/juan-bits/5cd1e74b93cb6dadd77c903633f887f1/raw/8b565516602b3d4d43de88d6f3076d89038e1843/bermuda-flag.svg"
              alt="Bermuda flag"
              className="my-5 CoverageFormat__flag"
              style={{ width: 150 }}
            />
          </div>
        </nav>
        <article>
          <p>
            Bermuda, self-governing British overseas territory in the western
            North Atlantic Ocean. It is an archipelago of 7 main islands and
            about 170 additional (named) islets and rocks, situated about 650
            miles (1,050 km) east of Cape Hatteras (North Carolina, U.S.). The
            island is famous for its pink sand beaches, which get their colour
            from one of the sand's main components, pulverized coral and shells.
          </p>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d107894.98148513412!2d-64.83643972296025!3d32.31942447999867!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8a2d139e8668b0a5%3A0x3cdffdc72c99b8bc!2sBermuda!5e0!3m2!1sen!2ses!4v1651138164390!5m2!1sen!2ses"
            width="95%"
            title="Bermuda-map"
            className="CoverageFormat__margins"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <h4 className="mt-4">Tourism</h4>
          <p>
            Currently well over 650,000* people visit Bermuda annually. Out of
            the total number of visitors, close to 85%* are from the USA.
          </p>

          <h4 className="mt-4">Main Ports / Airports</h4>

          <p>
            L.F. Wade International Airport, formerly named Bermuda
            International Airport, is the sole airport serving the British
            overseas territory of Bermuda in the North Atlantic Ocean.
          </p>

          <p>
            There are three ports in Bermuda for cruise ships to dock. Royal
            Naval Dockyard located at the western end on Great Sound (the
            busiest port), Hamilton Port located centrally on Hamilton Harbor,
            and St. George's Port located at the eastern end of the island on
            St. George's harbor.
          </p>

          <h4 className="mt-5">National Sport</h4>
          <p>
            Cricket is the national sport of Bermuda and an extremely popular
            one to play and watch on the island.
          </p>
          <h4 className="mt-5">Typical Food</h4>
          <p>
            Bermuda fish chowder is considered a national dish, which is a
            staple food not only in restaurants and hotels but also in homes;
            the main ingredients are fish stock, fish, vegetables and bacon fat
            and served with spices, but a Bermudan specialty is to serve it with
            black rum and sherry peppers.
          </p>

          <h4>Employment</h4>
          <p>
            Employers, total (% of total employment) (modeled ILO estimate) in
            Bermuda was reported at 2.491 % in 2020, according to the World Bank
            collection of development indicators, compiled from officially
            recognized sources.
          </p>
        </article>
      </section>

      <div className="container">
        <h4 className="my-5">Submarine Cables</h4>
        <a
          href="https://www.submarinecablemap.com/country/bermuda"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/RrLNMI7.png"
            alt="submarine cables"
            width="100%"
          />
        </a>
        <ol className="mt-4">
          <li>Caribbean-Bermuda U.S. (CBUS)</li>
          <li>Challenger Bermuda-1 (CB-1)</li>
          <li>Gemini Bermuda</li>
          <li>GlobeNet</li>
        </ol>

        <h4 className="my-4">
          ACCESS TO LANDLINE AND MOBILE PHONE ACCESS TO INTERNET*
        </h4>
        <p>
          Though Bermuda may be small in size, its international
          telecommunication system ranks among the best in the world for
          reliability, quality and sophistication.
        </p>

        <p>
          The island provides telephone, fax, Internet, wireless and wireless
          roaming services.
        </p>

        <p>
          Bermuda's country code is 441. Direct dialling is possible to and from
          the island.
        </p>
        <p>
          The island has the fastest “true” Fibre offering of any other country
          across the Caribbean and is connected by 3 subsea cable systems.
          Bermuda's connectivity up to 500Mbps speeds, and available WiFi mesh
          technology, in particular, allows for 'all over' coverage so there is
          no worry about spotty service.
        </p>

        <h4 className="my-4">Internet Providers*</h4>
        <p>
          Its surprisingly how Bermuda being an island is very well
          communicated, with 4 submarine cables and every day more people are
          having access to internet being a total of 60,000 people in the entire
          country, 98% of their total population.
        </p>

        <p>
          The country counts with an average download speed of 2,75 mbps and
          average upload speed of 2,40 mbps.
        </p>

        <p>
          The country counts with 5 important Internet providers who give the
          access throughout the country.{' '}
        </p>
        <strong>
          <p>
            inte-QT as a global access enabler makes partnerships
            with local infrastructure owners' providers and today, we count with
            4 different Partners in Bermuda.
          </p>
          <p>
            Today we are delivering with the help of our NSOC team circuits in
            main city Saint George and Pembroke with guaranteed 24/7 monitoring.
          </p>
          <p>
            We are providing traffic shaping capabilities in this region. For
            one of the first customers who is a mayor investment company, we are
            providing them 1G internet connectivity with prefered Gateway,
            Gemini cable preference and cloud connect solution at a data center
            in NYC.
          </p>
        </strong>

        <h4 className="my-4">Our Capabilities</h4>

        <table className="table table-striped mb-5">
          <tbody>
            <tr>
              <td>Dedicated Lines</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Business Broadband</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Wireless Connectivity</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>SLA</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>CPE / Router</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>GEMS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Diverse Gateway Solutions</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>DDoS</td>
              <td>
                <i className="fas fa-check"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="CoverageFormat__tag CoverageFormat__margins text-center"
          align="justify"
        >
          With our Global Business Solutions team, we can provide a
          <mark>Commercial Offer in 2-3 working days.</mark> Our Global Ops team
          is capable to deliver services in the country in 15 to 20 working
          days.
        </p>

        <p className="text-center">
          For more information, please{' '}
          <a href="mailto:sales@inte-QT.com?subject=Mail from Our Site">
            {' '}
            Email Us
          </a>
          .
        </p>

        <h4>* - References</h4>
        <ul>
          <li>
            <a
              href="https://www.britannica.com/place/Bermuda"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 1
            </a>
          </li>
          <li>
            <a
              href="https://www.frommers.com/destinations/bermuda/planning-a-trip/when-to-go"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 2
            </a>
          </li>
          <li>
            <a
              href="https://www.gotobermuda.com/article/bermuda-boasts-reliable-wi-fi-across-the-island#:~:text=The%20island%20has%20the%20fastest,no%20worry%20about%20spotty%20service"
              rel="noopener noreferrer"
              target="_blank"
            >
              Reference 3
            </a>
          </li>
        </ul>
      </div>

      <GetInTouch />
    </div>
  );
}

export default Bermuda;
